import React, {useEffect, useState} from 'react';


/**
 * `SupportTicketsFilter` is acomponent that provides a user interface for filtering
 * a list of support tickets based on specific criteria. It allows users to apply filters and update
 * the displayed list of tickets accordingly.
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.supportTickets - An array of support ticket objects that will be filtered based on user input.
 * @param {Function} props.onChange - A function that is called whenever the filter criteria change, allowing for updating
 * the displayed list of tickets based on the applied filters.
 *
 * @returns {JSX.Element} The rendered filter components with input fields or controls for applying filters to the support tickets.
 */
export const SupportTicketsFilter = ({supportTickets, onChange}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedUrgency, setSelectedUrgency] = useState('');
    const [selectedTag, setSelectedTag] = useState('');
    const [selectedAssignee, setSelectedAssignee] = useState('');
    const [selectedAccount, setSelectedAccount] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [sortUrgency, setSortUrgency] = useState(''); // Currently unused
    const [sortRecency, setSortRecency] = useState('');


    useEffect(() => {
        const tickets = [...supportTickets];

        const filter = tickets
            .filter(ticket => ticket.ticket_comment.toLowerCase().includes(searchTerm.toLowerCase()) || ticket.ticket_subject.toLowerCase().includes(searchTerm.toLowerCase()) || ticket.ticket_description.toLowerCase().includes(searchTerm.toLowerCase()))
            .filter(ticket => ticket.status_cat === selectedStatus || !selectedStatus)
            .filter(ticket => ticket.ticket_urgency === selectedUrgency || !selectedUrgency)
            .filter(ticket => ticket.tag === selectedTag || !selectedTag)
            .filter(ticket => ticket.assigned_to === selectedAssignee || !selectedAssignee)
            .filter(ticket => ticket.account === selectedAccount || !selectedAccount)
            .filter(ticket => ticket.location_name === selectedLocation || !selectedLocation);

        if (sortRecency === 'Recency: Ascending') {
            onChange(filter.sort((a, b) => new Date(a.last_email_datetime) - new Date(b.last_email_datetime)));
        }
        else if (sortRecency === 'Recency: Descending') {
            onChange(filter.sort((a, b) => new Date(b.last_email_datetime) - new Date(a.last_email_datetime)));
        }
        else {
            onChange(filter);
        }
    }, [searchTerm, selectedStatus, selectedUrgency, selectedTag, selectedAssignee, selectedAccount, selectedLocation, sortUrgency, sortRecency]);

    const urgencySortOptions = ['Urgency: Ascending', 'Urgency: Descending'];
    const recencySortOptions = ['Recency: Ascending', 'Recency: Descending'];
    const uniqueAccountOptions = Array.from(new Set(supportTickets.map(ticket => ticket.account))).sort();
    const uniqueAssignmentOptions = Array.from(new Set(supportTickets.map(ticket => ticket.assigned_to).filter(assignedTo => assignedTo !== null && assignedTo !== ''))).sort(); 
    const uniqueLocationOptions = Array.from(new Set(supportTickets.map(ticket => ticket.location_name))).sort();
    const uniqueTagOptions = Array.from(new Set(supportTickets.map(ticket => ticket.tag))).sort();
    const uniqueUrgencyOptions = Array.from(new Set(supportTickets.map(ticket => ticket.ticket_urgency))).sort();
    const uniqueStatusOptions = Array.from(new Set(supportTickets.map(ticket => ticket.status_cat))).sort();

    const handleClear = () => {
        setSearchTerm('');
        setSelectedAccount('');
        setSelectedAssignee('');
        setSelectedUrgency('');
        setSelectedTag('');
        setSelectedLocation('');
        setSelectedStatus('');
    };

    return (
        <>
            <div style={{
                display: 'flex', flexDirection: 'row',
                alignItems: 'center',
            }}>
                <select id="selectError" value={sortRecency} onChange={e => setSortRecency(e.target.value)} // jumpy
                    style={{marginRight: '20px', width: '20%'}}
                    className="select-box"
                >
                    <option value="">Sort Recent</option>

                    {
                        recencySortOptions.map(opt => (
                            <option key={opt} value={opt}>
                                {opt}
                            </option>
                        ))
                    }
                </select>
            </div>
            <br />
            <div style={{
                display: 'flex', flexDirection: 'row',
                alignItems: 'center',
            }}>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{marginRight: '20px', width: '20%', cursor: 'text'}}
                    className="select-box"
                />
                <select id="selectedStatus" value={selectedStatus} onChange={e => setSelectedStatus(e.target.value)} // jumpy
                    style={{marginRight: '20px', width: '15%'}}
                    className="select-box"
                >
                    <option value="">All Statuses</option>

                    {
                        uniqueStatusOptions.map(opt => (
                            <option key={opt} value={opt}>
                                {opt}
                            </option>
                        ))
                    }
                </select>
                <select id="selectedUrgency" value={selectedUrgency} onChange={e => setSelectedUrgency(e.target.value)} // jumpy
                    style={{marginRight: '20px', width: '15%'}}
                    className="select-box"
                >
                    <option value="">All Urgencies</option>

                    {
                        uniqueUrgencyOptions.map(opt => (
                            <option key={opt} value={opt}>
                                {opt}
                            </option>
                        ))
                    }
                </select>
                <select id="selectedTag" value={selectedTag} onChange={e => setSelectedTag(e.target.value)} // jumpy
                    style={{marginRight: '20px', width: '10%'}}
                    className="select-box"
                >
                    <option value="">All Tags</option>

                    {
                        uniqueTagOptions.map(opt => (
                            <option key={opt} value={opt}>
                                {opt}
                            </option>
                        ))
                    }
                </select>
                <select id="selectedAssignee" value={selectedAssignee} onChange={e => setSelectedAssignee(e.target.value)}
                    style={{marginRight: '20px', width: '10%'}}
                    className="select-box"
                    disabled={false}
                >
                    <option value="">All Assignments</option>
                    {
                        uniqueAssignmentOptions.map(opt => (
                            <option key={opt} value={opt}>
                                {opt}
                            </option>
                        ))
                    }

                </select>
                <select id="selectedLocation" value={selectedLocation} onChange={e => setSelectedLocation(e.target.value)}
                    style={{marginRight: '20px', width: '10%'}}
                    className="select-box"
                    disabled={false}
                >
                    <option value="">All Locations</option>

                    {
                        uniqueLocationOptions.map(opt => (
                            <option key={opt} value={opt}>
                                {opt}
                            </option>
                        ))
                    }
                </select>
                <select id="selectedAccount" value={selectedAccount} onChange={e => setSelectedAccount(e.target.value)}
                    disabled={false}
                    style={{marginRight: '20px', width: '10%'}}
                    className="select-box"
                >
                    <option value="">All Accounts</option>
                    {
                        uniqueAccountOptions.map(opt => (
                            <option key={opt} value={opt}>
                                {opt}
                            </option>
                        ))
                    }

                </select>
                <button className="support-button" onClick={handleClear}>Clear</button>
            </div>
        </>

    );
};

export default SupportTicketsFilter;