import React, { useEffect, useState } from 'react';
import { ServiceTable } from './ServiceTable';
import { CopyButton } from './ResolveButtons';
import * as Data from '../../utils/mapping';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { fetchUsername } from '../../utils/users/userUtils';
import { toast } from 'react-toastify';
import BackArrow from '../../components/BackArrow';
import ServiceForm from './ServiceForm';
import { getPercentageChange } from '../../utils/mapping/Format';

const ProductMapping = ({ selectedProduct, selectedLocation, onBack, onSubmit, beforeOnSubmit, highlightedRows = [] }) => {
    const [compareLocations, setCompareLocations] = useState([]);
    const [productMappings, setProductMappings] = useState([]);
    const [initialproductsMapping, setInitialproductsMapping] = useState([]);
    const [overwriteChecked, setOverwriteChecked] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(true);
    const [cloneOptions, setCloneOptions] = useState([]);
    const [selectedCompareOption, setSelectedCompareOption] = useState('');
    const [compareLocation, setCompareLocation] = useState([]);
    const [filteredCloneOptions, setFilteredCloneOptions] = useState([]);
    const [expandedOptions, setExpandedOptions] = useState([]);
    const [collapsedOptions, setCollapsedOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [newService, setNewService] = useState(false);
    const [selectedService, setSelectedService] = useState({});
    const [submitService, setSubmitService] = useState(false);
    const [username, setUsername] = useState('');
    const [uniqueProductOptions, setUniqueProductOptions] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const selectedId = selectedProduct.product_id;


    useEffect(() => {
        if (selectedProduct && selectedLocation) {
            Data.fetchServiceMappingProductInfo(selectedLocation, selectedProduct.product_id, handleProductMappingInfo);
            // Data.fetchServicesData(selectedLocation, setCurrentInventory);
            // Data.fetchServicesData({ selectedLocation, onlyNew: false, callback: setServiceOptions });
            Data.fetchServicesData({ selectedLocation, onlyNew: false, callback: setServiceOptions });
            Data.fetchInventory(selectedLocation, setProductOptions);
            Data.fetchLocations(setCompareLocations);
        }
        fetchUsername(setUsername);
    }, [selectedProduct, selectedLocation]);

    // Listeners for cloning
    useEffect(() => {
        const productId = selectedCompareOption ? selectedCompareOption : selectedProduct.product_id;
        Data.fetchCloneOptions({ productId, locationName: selectedLocation, compareLocation, callback: handleCloneOptions, showAll });
    }, [selectedCompareOption, compareLocation, serviceOptions, showAll]);

    useEffect(() => {
        if (compareLocation.length > 0) {
            setFilteredCloneOptions(cloneOptions.filter(item => compareLocation.includes(item.location_name)));
        }
        else {
            setFilteredCloneOptions(cloneOptions);
        }
    }, [cloneOptions]);

    useEffect(() => {
        console.log(productOptions);
        setUniqueProductOptions(Array.from(new Map(
            productOptions.map(item => [`${item.product_name} - ${item.product_description} (${item.product_id})`, item])
        ).values()));
    }, [productOptions]);

    useEffect(() => {
        const groupedProducts = {};
        // Group by product_id
        filteredCloneOptions.forEach(({ service_name, service_id, usage_quantity, selected }) => {
            if (!groupedProducts[service_name]) {
                groupedProducts[service_name] = {
                    service_id,
                    totalUsage: 0,
                    count: 0,
                    service_name,    // Take any product_name
                    selected
                };
            }

            // Accumulate usage and count
            groupedProducts[service_name].totalUsage += selected ? usage_quantity : 0;
            groupedProducts[service_name].count += selected ? 1 : 0;
            groupedProducts[service_name].selected = (groupedProducts[service_name].selected || selected);
        });

        // Calculate average usage and return the final array
        setCollapsedOptions(Object.values(groupedProducts).map(service => ({
            service_id: service.service_id,
            service_name: service.service_name,
            product_name: service.product_name,
            usage_quantity: service.count > 0 ? (service.totalUsage / service.count).toFixed(4) : '-',
            selected: service.selected
        })));

    }, [filteredCloneOptions]);

    const submitNewService = () => {
        setNewService(false);
        setSubmitService(true);
    };

    const handleOptionClick = (product_id) => {
        if (expandedOptions.includes(product_id)) {
            setExpandedOptions(expandedOptions.filter(id => id !== product_id));
        } else {
            // If the bundle is not expanded, add it to the list of expanded bundles
            setExpandedOptions([...expandedOptions, product_id]);
        }
    };

    const handleAddServiceMapping = (service) => {
        // Check if there's already a mapping with the same product_id and product_id
        if (productMappings.length > 0) {
            const isMappingExist = productMappings.some(mapping => mapping.service_name === service.service_name);

            if (isMappingExist) {
                toast.error('Mapping for this combination already present');
                return; // Exit the function without adding a new mapping
            }
        }

        // If mapping doesn't exist, create a new mapping object
        const newproductMapping = {
            service_id: service.service_id,
            service_name: service.service_name,
            product_id: selectedProduct.product_id,
            usage_quantity: 0
        };
        // Add the new mapping to selectedproductMapping
        setProductMappings([...productMappings, newproductMapping]);
    };

    const handleSubmit = async () => {
        let productId = selectedProduct.product_id;
        if (beforeOnSubmit) {
            productId = await beforeOnSubmit();
            if (!productId) {
                toast.error('Failed to add mappings.');
                return;
            }
        }
        const mappings = [...productMappings].map(product => ({ ...product, product_id: productId }));
        const success = await Data.submitMappingData({ serviceMappings: mappings, locationName: selectedLocation, username: username });
        if (!success) {
            toast.error('Failed to submit mapping');
        }
        else {
            onSubmit();

        }
    };
    const handleDeleteServiceMapping = (serviceId, productId) => {
        const updatedMappings = [...productMappings].filter(mapping => mapping.product_id !== productId || mapping.service_id !== serviceId);
        setProductMappings(updatedMappings);
    };

    const handleDeleteServiceMappingPermanent = async (serviceId, productId) => {
        const updatedMappings = [...productMappings].filter(mapping => mapping.product_id !== productId || mapping.service_id !== serviceId);
        setProductMappings(updatedMappings);
        const result = await Data.deleteServiceMapping(serviceId, productId, username, selectedLocation);
    };

    const handleCloneLocationChange = async (selected) => {
        selected ? setCompareLocation(selected.map(item => item.value)) : setCompareLocation([]);
    };


    const handleCloneOptions = (data) => {
        // Select only products with internal_server_id overlapping products at selected location.
        // Specify the products at the new locations for comparing to current.
        const filteredObjects = data.map(({ service_id, service_name, ...remaining }) => ({
            compare_service_id: service_id,
            service_name: service_name,
            ...remaining
        }));
        // Map products at current location to their internal_server_id.
        const productMap = serviceOptions.reduce((map, service) => {
            map[service.service_name] = { 'service_id': service.service_id, 'service_name': service.service_name };
            return map;
        }, {});
        // Join products at comparison location to the current location's counterpart.
        const joinedData = filteredObjects
            .map(mapping => ({
                ...mapping,
                ...productMap[mapping.service_name],
                selected: highlightedRows.length === 0 ? true :
                    ((highlightedRows.includes(productMap[mapping.service_name]?.service_id) &&
                        (mapping.location_name !== selectedLocation)))
            }));

        const finalproducts = joinedData.sort((a, b) => a.service_id - b.service_id);
        setCloneOptions(finalproducts);
        setSelectAllChecked(true);
    };

    const handleProductMappingInfo = async (data) => {
        // Update state with fetched product mapping info
        // setSelectedproduct([data.product_info]);
        setProductMappings(data.services_mapping);
        setInitialproductsMapping(data.services_mapping);
    };
    const handleSelectRow = (service_name, compare_service_id) => (_) => {
        const newCloneOptions = [...cloneOptions];

        const index = newCloneOptions.findIndex(row => (row.service_name === service_name) && (row.compare_service_id === compare_service_id));
        newCloneOptions[index].selected = !newCloneOptions[index].selected;

        setCloneOptions(newCloneOptions);
        setSelectAllChecked(false);
    };

    const handleSelectGroup = (service_name) => (_) => {
        const newCollapsedOptions = [...collapsedOptions];
        const index = newCollapsedOptions.findIndex(obj => obj.service_name === service_name);
        if (newCollapsedOptions[index].selected) {
            //remove all row selections
            const newFilteredCloneOptions = [...filteredCloneOptions];
            newFilteredCloneOptions.forEach(obj => {
                if (obj.service_name === service_name) {
                    obj.selected = false;
                }
            });
            setFilteredCloneOptions(newFilteredCloneOptions);
        }
        else {
            // add all row selection
            const newFilteredCloneOptions = [...filteredCloneOptions];
            newFilteredCloneOptions.forEach(obj => {
                if (obj.service_name === service_name) {
                    obj.selected = true;
                }
            });
            setFilteredCloneOptions(newFilteredCloneOptions);
        }
        newCollapsedOptions[index].selected = !newCollapsedOptions[index].selected;
        setCollapsedOptions(newCollapsedOptions);
    };



    const handleSelectAll = (isChecked) => {
        const newCloneOptions = [...cloneOptions].map(row => ({
            ...row,
            selected: isChecked ? true : false
        }));;
        setSelectAllChecked(!selectAllChecked);
        setCloneOptions(newCloneOptions);
    };

    const handleOverwriteChange = (event) => {
        setOverwriteChecked(event.target.checked);
    };

    const handleSubmitCloneProducts = () => {
        if (overwriteChecked) {
            // Overwrite: Replace all matching items in array1 with items from array2
            const map = new Map(productMappings.map(item => [item.service_name, item]));
            collapsedOptions.forEach(item => {
                if (!map.has(item.service_name) && item.selected) {
                    map.set(item.service_name, {
                        product_id: selectedProduct.product_id,
                        service_id: item.service_id,
                        service_name: item.service_name,
                        usage_quantity: parseFloat(item.usage_quantity),
                    });
                }
                else if (item.selected) {
                    const existingItem = map.get(item.service_name);
                    map.set(item.service_name, {
                        ...existingItem,
                        usage_quantity: parseFloat(item.usage_quantity),
                    });
                }
            });
            setProductMappings(Array.from(map.values()).sort((a, b) => a.service_id - b.service_id));
        } else {
            // Do not overwrite: Only add new items from array2 that are not already in array1
            const map = new Map(productMappings.map(item => [item.service_name, item]));
            collapsedOptions.forEach(item => {
                if (!map.has(item.service_name) && item.selected) {
                    map.set(item.service_name, {
                        product_id: selectedProduct.product_id,
                        service_id: item.service_id,
                        service_name: item.service_name,
                        usage_quantity: parseFloat(item.usage_quantity),
                    });
                }
            });
            setProductMappings(Array.from(map.values()).sort((a, b) => a.service_id - b.service_id));
        }
    };

    const handleCloneProductChange = async (selected) => {
        setSelectedCompareOption(selected.value);//, 'internal_product_id':selected.value}); 
    };

    const handleQuantityChange = (serviceId, newValue) => {
        // Update the usage_quantity of the mapping with the specified productId
        const updatedMappings = productMappings.map(mapping =>
            mapping.service_id === serviceId ? { ...mapping, usage_quantity: newValue } : mapping
        );

        // Update the state with the updated mappings
        setProductMappings(updatedMappings);
    };

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <BackArrow onClick={onBack} />
                <h3>{selectedProduct.product_name}</h3>
            </div>
            <input
                type="checkbox"
                id="overwriteCheckbox"
                checked={overwriteChecked}
                onChange={handleOverwriteChange}
            />
            <label htmlFor="overwriteCheckbox">Overwrite </label>
            <br />
            <br />

            <div style={{ display: 'flex' }}>

                <div style={{
                    border: '2px solid #87D3D7',
                    display: 'flex',
                    justifyContent: 'left',
                    flexDirection: 'column',
                    width: '30vw',
                    maxHeight: '610px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'separate',
                        borderSpacing: 0,
                        position: 'relative' // Ensure positioning context
                    }}>
                        <thead style={{
                            position: 'sticky', // Stick the header
                            top: 0, // Stick to the top of the table
                            zIndex: 1 // Ensure it stays above the tbody rows
                        }}>
                            <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Service ID</th>
                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Service Name</th>
                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Usage Quantity</th>
                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productMappings && productMappings.map((mapping, index) => {
                                // Check if the mapping is not in initialproductsMapping
                                const isEditable = initialproductsMapping ? (!initialproductsMapping.some(initialMapping =>
                                    initialMapping.service_id === mapping.service_id &&
                                    initialMapping.product_id === selectedId
                                )) : (true);
                                const isImportant = highlightedRows.includes(mapping.service_id);
                                const difference = isImportant ? getPercentageChange(mapping.usage_quantity, collapsedOptions.find(item => item.service_name === mapping.service_name)?.usage_quantity) : null;

                                const backgroundColor = isImportant ? '#FFD580' : (index % 2 === 0 ? '#ebf4f5' : '#ffffff');

                                return (
                                    <tr key={index} style={{ fontSize: '13px', backgroundColor: backgroundColor }} >
                                        <td style={{ padding: '10px', }}>{mapping.service_id}</td>
                                        <td style={{ padding: '10px', }}>
                                            {`${mapping.service_name}  ${isImportant && difference ? `(${difference > 0 ? '+' : ''}${difference}% average)` : ''}`}
                                        </td>
                                        <td style={{ padding: '10px', }}>
                                            <input
                                                type="number"
                                                value={mapping.usage_quantity}
                                                onChange={(event) => handleQuantityChange(mapping.service_id, parseFloat(event.target.value))}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                        </td>
                                        {isEditable ? (
                                            <td style={{ padding: '10px' }}>
                                                <button onClick={() => handleDeleteServiceMapping(mapping.service_id, selectedId)} style={{ backgroundColor: '#F49C7D', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>
                                                    Delete
                                                </button>
                                            </td>
                                        )
                                            :
                                            (
                                                <td style={{ padding: '10px' }}>
                                                    <button onClick={async () => await handleDeleteServiceMappingPermanent(mapping.service_id, selectedId)} style={{ backgroundColor: '#F49C7D', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>
                                                        Delete
                                                    </button>
                                                </td>
                                            )
                                        }
                                    </tr>
                                );
                            })}
                            {!productMappings && (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: 'center' }}>No product mappings available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <CopyButton handleClick={() => { handleSubmitCloneProducts(); }} allowCopy={collapsedOptions.find(item => item.selected === true)} />
                <div style={{
                    display: 'flex',
                    justifyContent: 'left',
                    width: '50vw',
                    flexDirection: 'column',
                    border: '2px solid #87D3D7',
                    maxHeight: '610px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'left',
                        flexDirection: 'column',
                        width: '100%',
                    }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <div style={{ fontStyle: 'italic', width: '50%', padding: '5px' }}>
                                <Select
                                    defaultValue={{ value: selectedProduct.product_name, label: selectedProduct.product_name }}
                                    name="products"
                                    options={uniqueProductOptions.map(item => ({
                                        value: item.product_id,
                                        label: `${item.product_name} - ${item.product_description} (Product ID ${item.product_id})`
                                    })).sort((a, b) => a.label.localeCompare(b.label))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={handleCloneProductChange}
                                    display='flex'
                                    placeholder="Select product"  // Placeholder text
                                />
                            </div>
                            <div style={{ fontStyle: 'italic', width: '50%', padding: '5px' }}>
                                <Select
                                    // defaultValue={[options[1], options[2]]}
                                    defaultValue={compareLocation}
                                    isMulti
                                    isClearable
                                    name="locations"
                                    options={compareLocations
                                        .map(location => ({ value: location.location_name, label: location.location_name }))
                                        .sort((a, b) => a.label.localeCompare(b.label))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={handleCloneLocationChange}
                                    display='flex'
                                    placeholder="Select Location"  // Placeholder text
                                />
                            </div>
                            <div style={{ width: '15%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                                <input
                                    type="checkbox"
                                    checked={showAll}
                                    onChange={() => setShowAll(!showAll)}
                                    style={{ width: '15px', height: '15px' }}
                                />
                                <label>
                                    Show All
                                </label>

                            </div>


                        </div>
                    </div>
                    {
                        (collapsedOptions.length === 0 && collapsedOptions.length > 0) ?
                            (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', // Centers horizontally
                                    alignItems: 'center',     // Centers vertically
                                }}>
                                    <div>No overlapping products are mapped to this product.</div>
                                </div>
                            ) : <div></div>
                    }
                    {
                        (collapsedOptions.length > 0) ? (

                            <div>
                                <table style={{
                                    width: '100%',
                                    borderCollapse: 'separate',
                                    borderSpacing: 0,
                                    position: 'relative', // Ensure positioning context

                                }}>
                                    <thead style={{
                                        position: 'sticky', // Stick the header
                                        top: 0, // Stick to the top of the table
                                        zIndex: 1 // Ensure it stays above the tbody rows
                                    }}>
                                        <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px' }}></th>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Service ID </th>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Service Name </th>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}>Usage Quantity</th>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}>
                                                <input
                                                    checked={selectAllChecked}
                                                    type="checkbox"
                                                    onChange={(e) => handleSelectAll(e.target.checked)}
                                                    style={{ width: '15px', height: '15px' }}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {collapsedOptions.length > 0 && collapsedOptions.map((mapping, index) => {
                                            const backgroundColor = highlightedRows.includes(mapping.service_id) ? '#FFD580' : (index % 2 === 0 ? '#ebf4f5' : '#ffffff');
                                            return (
                                                <React.Fragment key={index}>

                                                    <tr key={index} style={{ fontSize: '13px', backgroundColor: backgroundColor }} >
                                                        <td
                                                            onClick={() => handleOptionClick(mapping.service_id)}
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <span>
                                                                {expandedOptions.includes(mapping.service_id) ?
                                                                    <FontAwesomeIcon icon={faAngleUp} /> :
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                }
                                                            </span>
                                                        </td>
                                                        <td style={{ padding: '10px', }}>{mapping.service_id}</td>
                                                        <td style={{ padding: '10px', }}>
                                                            {mapping.service_name}
                                                        </td>


                                                        <td style={{ padding: '10px', textAlign: 'center' }}>{mapping.usage_quantity}</td>
                                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                                            <input
                                                                type="checkbox"
                                                                id="bulkCheckbox"
                                                                checked={mapping.selected}
                                                                onChange={handleSelectGroup(mapping.service_name, mapping.compare_service_id)}
                                                                style={{ width: '15px', height: '15px' }} // Adjust the scale and size as needed
                                                            />
                                                        </td>
                                                    </tr>
                                                    {expandedOptions.includes(mapping.service_id) &&
                                                        <tr>
                                                            {/* Render order details */}
                                                            <td colSpan="1"></td>
                                                            <td colSpan="24">
                                                                <div style={{ border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden' }}>
                                                                    <table style={{

                                                                        width: '100%',
                                                                        borderCollapse: 'separate',
                                                                        borderSpacing: 0,
                                                                        position: 'relative' // Ensure positioning context
                                                                    }}>
                                                                        <thead style={{
                                                                            position: 'sticky', // Stick the header
                                                                            top: 0, // Stick to the top of the table
                                                                        }}>
                                                                            <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Service Name </th>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Location</th>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Service ID (Location)</th>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}>Usage Quantity</th>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}></th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {filteredCloneOptions.filter(item => item.service_name === mapping.service_name).length > 0 && filteredCloneOptions.filter(item => item.service_id === mapping.service_id).map((mapping, index) => {
                                                                                return (

                                                                                    <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >

                                                                                        <td style={{ padding: '10px', }}>
                                                                                            {mapping.service_name}
                                                                                        </td>
                                                                                        <td style={{ padding: '10px', }}>{mapping.location_name}</td>
                                                                                        <td style={{ padding: '10px', }}>{mapping.compare_service_id}</td>


                                                                                        <td style={{ padding: '10px', textAlign: 'center' }}>{mapping.usage_quantity}</td>
                                                                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                id="bulkCheckbox"
                                                                                                checked={mapping.selected}
                                                                                                onChange={handleSelectRow(mapping.service_name, mapping.compare_service_id)}
                                                                                                style={{ width: '15px', height: '15px' }} // Adjust the scale and size as needed
                                                                                            />
                                                                                        </td>
                                                                                    </tr>);
                                                                            })
                                                                            }

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </React.Fragment>


                                            );
                                        })}
                                    </tbody>
                                </table></div>

                        ) :
                            <div>
                            </div>
                    }
                </div>
            </div>
            <br />
            <button className="support-button" onClick={productMappings && handleSubmit}
                style={{
                    opacity: productMappings ? 1 : 0.5, // Optionally adjust opacity
                    fontSize: 'small',
                }}
            >
                Submit
            </button>
            <br />
            <br />
            <ServiceTable services={serviceOptions} onClick={handleAddServiceMapping} />
            <br />
            <br />
            <button
                className='support-button'
                onClick={async () => {
                    if (submitService) {
                        const success = await Data.addNewService(selectedService);
                        if (success) {
                            setSubmitService(false);
                            Data.fetchServicesData({ selectedLocation, onlyNew: false, callback: setServiceOptions });
                        }
                        else {
                            toast.error('Failed to add service');
                        }
                    }
                    else {
                        setNewService(true);
                    }

                }}
                style={{ marginRight: '20px', fontSize: 'small' }}
            >
                {submitService ? 'Submit Service' : 'New Service'}
            </button>
            <button
                className='support-button'
                onClick={() => {
                    if (submitService) setSubmitService(false);
                    else setNewService(false);
                }
                }
                style={{ marginRight: '20px', fontSize: 'small' }}
            >
                Cancel
            </button>
            {newService && (
                <>
                    <br />
                    <br />
                    <ServiceForm setSelectedService={setSelectedService} selectedLocation={selectedLocation} setIsAdding={submitNewService} serviceOptions={serviceOptions} />
                </>

            )}
        </div>
    );
};

export default ProductMapping;