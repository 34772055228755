import React, { useEffect, useState } from 'react';
import ProductTable from './ProductTable';
import ProductForm from './ProductForm';
import { CopyButton } from './ResolveButtons';
import * as Data from '../../utils/mapping';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { fetchUsername } from '../../utils/users/userUtils';
import { toast } from 'react-toastify';
import BackArrow from '../../components/BackArrow';

const ServiceMapping = ({ selectedService, selectedLocation, onBack, onSubmit, beforeOnSubmit, highlightedRows = [] }) => {
    const [compareLocations, setCompareLocations] = useState([]);
    const [serviceMappings, setServiceMappings] = useState([]);
    const [initialServicesMapping, setInitialServicesMapping] = useState([]);
    const [overwriteChecked, setOverwriteChecked] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(true);
    const [cloneOptions, setCloneOptions] = useState([]);
    const [selectedCompareOption, setSelectedCompareOption] = useState('');
    const [compareLocation, setCompareLocation] = useState([]);
    const [filteredCloneOptions, setFilteredCloneOptions] = useState([]);
    const [expandedOptions, setExpandedOptions] = useState([]);
    const [collapsedOptions, setCollapsedOptions] = useState([]);
    const [currentInventory, setCurrentInventory] = useState([]);
    const [newProductForm, setNewProductForm] = useState(false);
    const [newInventoryForm, setNewInventoryForm] = useState(false);
    const [newPrice, setNewPrice] = useState(null);
    const [newInventory, setNewInventory] = useState(null);
    const [newProduct, setNewProduct] = useState(null);
    const [username, setUsername] = useState('');
    const [submitReady, setSubmitReady] = useState(false);
    const [productOptions, setProductOptions] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [showAll, setShowAll] = useState(false);

    const selectedId = selectedService.service_id;
    const [uniqueServiceOptions, setUniqueServiceOptions] = useState([]);


    useEffect(() => {
        Data.fetchServiceMappingInfo(selectedLocation, selectedService.service_id, handleServiceMappingInfo);
        Data.fetchInventory(selectedLocation, setCurrentInventory);
        fetchUsername(setUsername);
        Data.fetchInventory(selectedLocation, setProductOptions);
        Data.fetchProducts(setProductsData);
        Data.fetchLocations(setCompareLocations);
        Data.fetchServicesData({ selectedLocation, serviceName: null, onlyNew: false, callback: setServiceOptions }); // Yes
    }, []);

    // Listeners for cloning
    useEffect(() => {
        const serviceName = selectedCompareOption ? selectedCompareOption : selectedService.service_name;
        Data.fetchCloneOptions({ serviceName, locationName: selectedLocation, compareLocation, callback: handleCloneOptions, showAll });
    }, [selectedCompareOption, selectedService, compareLocation, showAll]);

    useEffect(() => {
        if (compareLocation.length > 0) {
            setFilteredCloneOptions(cloneOptions.filter(item => compareLocation.includes(item.location_name)));
        }
        else {
            setFilteredCloneOptions(cloneOptions);
        }
    }, [cloneOptions]);


    useEffect(() => {
        setUniqueServiceOptions(Array.from(new Map(
            serviceOptions.map(item => [item.service_name, item])
        ).values()));
    }, [serviceOptions]);

    useEffect(() => {
        const groupedProducts = {};

        // Group by product_id
        filteredCloneOptions.forEach(({ product_id, usage_quantity, product_name, service_name, selected }) => {
            if (!groupedProducts[product_id]) {
                groupedProducts[product_id] = {
                    product_id,
                    totalUsage: 0,
                    count: 0,
                    product_name,    // Take any product_name
                    service_name,    // Take any service_name
                    selected
                };
            }

            // Accumulate usage and count
            groupedProducts[product_id].totalUsage += selected ? usage_quantity : 0;
            groupedProducts[product_id].count += selected ? 1 : 0;
            groupedProducts[product_id].selected = (groupedProducts[product_id].selected || selected);
        });

        // Calculate average usage and return the final array
        setCollapsedOptions(Object.values(groupedProducts).map(product => ({
            product_id: product.product_id,
            product_name: product.product_name,
            service_name: product.service_name,
            usage_quantity: product.count > 0 ? (product.totalUsage / product.count).toFixed(4) : '-',
            selected: product.selected
        })));
    }, [filteredCloneOptions]);

    const handleOptionClick = (product_id) => {
        if (expandedOptions.includes(product_id)) {
            // If the bundle is already expanded, collapse it
            // const ordersInBundle = Object.values(finalbundlesort)
            //     .flatMap(orders => orders.filter(order => order.order_code === bundleId));

            // const deselectedOrders = selectedOrders.filter(order => !ordersInBundle.includes(order));

            // setSelectedOrders(deselectedOrders);

            setExpandedOptions(expandedOptions.filter(id => id !== product_id));
        } else {
            // If the bundle is not expanded, add it to the list of expanded bundles
            setExpandedOptions([...expandedOptions, product_id]);
        }
    };

    const handleDeleteServiceMapping = (serviceId, productId) => {
        const updatedMappings = [...serviceMappings].filter(mapping => mapping.product_id !== productId || mapping.service_id !== serviceId);
        setServiceMappings(updatedMappings);
    };

    const handleDeleteServiceMappingPermanent = async (serviceId, productId) => {
        const updatedMappings = [...serviceMappings].filter(mapping => mapping.product_id !== productId || mapping.service_id !== serviceId);
        setServiceMappings(updatedMappings);
        const result = await Data.deleteServiceMapping(serviceId, productId, username, selectedLocation);
    };

    const handleAddInventoryProductMapping = (product) => {
        // Check if there's already a mapping with the same product_id and service_id
        if (serviceMappings.length > 0) {
            const isMappingExist = serviceMappings.some(mapping => mapping.product_id === product.product_id && mapping.service_id === selectedService.service_id);

            if (isMappingExist) {
                toast.error('Mapping for this combination already present');
                return; // Exit the function without adding a new mapping
            }
        }

        // If mapping doesn't exist, create a new mapping object
        const newServiceMapping = {
            product_id: product.product_id,
            product_name: product.product_name,
            service_id: selectedService.service_id,
            usage_quantity: 0
        };
        // Add the new mapping to selectedServiceMapping
        setServiceMappings([...serviceMappings, newServiceMapping]);
    };

    const handleSubmit = async () => {
        let serviceId = selectedService.service_id;
        if (beforeOnSubmit) {
            serviceId = await beforeOnSubmit();
            if (!serviceId) {
                toast.error('Failed to add mappings.');
                return;
            }
        }
        const mappings = [...serviceMappings].map(service => ({ ...service, service_id: serviceId }));
        setServiceMappings(mappings);

        const success = await Data.submitMappingData({ serviceMappings: mappings, locationName: selectedLocation, username: username });

        if (!success) {
            toast.error('Failed to submit mapping.');
        }
        else onSubmit();
    };
    const handleCloneLocationChange = async (selected) => {
        selected ? setCompareLocation(selected.map(l => l.value)) : setCompareLocation([]);
    };

    const handleCloneOptions = (data) => {
        const currentServiceIds = serviceMappings.map(obj => obj.service_name);
        // Select only services with internal_server_id overlapping services at selected location.
        // Specify the services at the new locations for comparing to current.
        const uniqueIdSet = new Set(currentServiceIds);
        const filteredObjects = data.map(({ service_id, service_name, ...remaining }) => ({
            compare_service_id: service_id,
            compare_service_name: service_name,
            ...remaining
        }));

        // Map services at current location to their internal_server_id.
        const serviceMap = serviceMappings.reduce((map, service) => {
            map[service.service_name] = { 'service_id': service.service_id, 'service_name': service.service_name };
            return map;
        }, {});
        // Join services at comparison location to the current location's counterpart.
        const joinedData = filteredObjects
            .map(mapping => ({
                ...mapping,
                ...serviceMap[mapping.compare_service_name],
                selected: highlightedRows.length === 0 ? true :
                    ((highlightedRows.includes(serviceMap[mapping.compare_service_name].service_id) &&
                        (mapping.location_name !== selectedLocation)))
            }));

        const finalServices = joinedData.sort((a, b) => a.product_id - b.product_id);
        setCloneOptions(finalServices);
        setSelectAllChecked(true);
    };

    const handleServiceMappingInfo = async (data) => {
        setServiceMappings(data.services_mapping);
        setInitialServicesMapping(data.services_mapping);
    };
    const handleSelectRow = (product_id, service_id) => (_) => {
        const newCloneOptions = [...cloneOptions];

        const index = newCloneOptions.findIndex(row => (row.product_id === product_id) && (row.compare_service_id) === service_id);

        newCloneOptions[index].selected = !newCloneOptions[index].selected;

        setCloneOptions(newCloneOptions);
        setSelectAllChecked(false);
    };

    const handleSelectGroup = (product_id, service_id) => (_) => {
        const newCollapsedOptions = [...collapsedOptions];
        const index = newCollapsedOptions.findIndex(obj => obj.product_id === product_id);
        if (newCollapsedOptions[index].selected) {
            //remove all row selections
            const newFilteredCloneOptions = [...filteredCloneOptions];
            newFilteredCloneOptions.forEach(obj => {
                if (obj.product_id === product_id) {
                    obj.selected = false;
                }
            });
            setFilteredCloneOptions(newFilteredCloneOptions);
        }
        else {
            // add all row selection
            const newFilteredCloneOptions = [...filteredCloneOptions];
            newFilteredCloneOptions.forEach(obj => {
                if (obj.product_id === product_id) {
                    obj.selected = true;
                }
            });
            setFilteredCloneOptions(newFilteredCloneOptions);
        }
        newCollapsedOptions[index].selected = !newCollapsedOptions[index].selected;
        setCollapsedOptions(newCollapsedOptions);
    };



    const handleSelectAll = (isChecked) => {
        const newCloneOptions = [...cloneOptions].map(row => ({
            ...row,
            selected: isChecked ? true : false
        }));;
        setSelectAllChecked(!selectAllChecked);
        setCloneOptions(newCloneOptions);
    };

    const handleOverwriteChange = (event) => {
        setOverwriteChecked(event.target.checked);
    };

    const handleSubmitCloneProducts = () => {
        if (overwriteChecked) {
            // Overwrite: Replace all matching items in array1 with items from array2
            const map = new Map(serviceMappings.map(item => [item.product_id, item]));
            collapsedOptions.forEach(item => {
                if (!map.has(item.product_id) && item.selected) {
                    map.set(item.product_id, {
                        service_id: selectedService.service_id,
                        product_id: item.product_id,
                        product_name: item.product_name,
                        usage_quantity: parseFloat(item.usage_quantity),
                    });
                }
                else if (item.selected) {
                    const existingItem = map.get(item.product_id);
                    map.set(item.product_id, {
                        ...existingItem,
                        usage_quantity: parseFloat(item.usage_quantity),
                    });
                }
            });
            setServiceMappings(Array.from(map.values()).sort((a, b) => a.product_id - b.product_id));
        } else {
            // Do not overwrite: Only add new items from array2 that are not already in array1
            const map = new Map(serviceMappings.map(item => [item.product_id, item]));
            collapsedOptions.forEach(item => {
                if (!map.has(item.product_id) && item.selected) {
                    map.set(item.product_id, {
                        service_id: selectedService.service_id,
                        product_id: item.product_id,
                        product_name: item.product_name,
                        usage_quantity: parseFloat(item.usage_quantity),
                    });
                }
            });
            setServiceMappings(Array.from(map.values()).sort((a, b) => a.product_id - b.product_id));
        }
    };

    const handleCloneServiceChange = async (selected) => {
        setSelectedCompareOption(selected.label);//, 'internal_service_id':selected.value}); 
    };

    const handleQuantityChange = (productId, newValue) => {
        // Update the usage_quantity of the mapping with the specified productId
        const updatedMappings = serviceMappings.map(mapping =>
            mapping.product_id === productId ? { ...mapping, usage_quantity: newValue } : mapping
        );

        // Update the state with the updated mappings
        setServiceMappings(updatedMappings);
    };



    return (
        <div>
            <div style={{ display: 'flex' }}>
                <BackArrow onClick={onBack} />
                <h3>{selectedService.service_name}</h3>
            </div>
            <input
                type="checkbox"
                id="overwriteCheckbox"
                checked={overwriteChecked}
                onChange={handleOverwriteChange}
            />
            <label htmlFor="overwriteCheckbox">Overwrite </label>
            <br />
            <br />

            <div style={{ display: 'flex' }}>

                <div style={{
                    border: '2px solid #87D3D7',
                    display: 'flex',
                    justifyContent: 'left',
                    flexDirection: 'column',
                    width: '30vw',
                    maxHeight: '610px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'separate',
                        borderSpacing: 0,
                        position: 'relative' // Ensure positioning context
                    }}>
                        <thead style={{
                            position: 'sticky', // Stick the header
                            top: 0, // Stick to the top of the table
                            zIndex: 1 // Ensure it stays above the tbody rows
                        }}>
                            <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Product ID</th>
                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Product Name</th>
                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Usage Quantity</th>
                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {serviceMappings && serviceMappings.map((mapping, index) => {
                                // Check if the mapping is not in initialServicesMapping
                                const isEditable = initialServicesMapping ? (!initialServicesMapping.some(initialMapping =>
                                    initialMapping.product_id === mapping.product_id &&
                                    initialMapping.service_id === selectedId
                                )) : (true);

                                return (
                                    <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                                        <td style={{ padding: '10px', }}>{mapping.product_id}</td>
                                        <td style={{ padding: '10px', }}>
                                            {mapping.product_name}
                                        </td>
                                        <td style={{ padding: '10px', }}>
                                            <input
                                                type="number"
                                                value={mapping.usage_quantity}
                                                onChange={(event) => handleQuantityChange(mapping.product_id, parseFloat(event.target.value))}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                        </td>
                                        {isEditable ? (
                                            <td style={{ padding: '10px' }}>
                                                <button onClick={() => handleDeleteServiceMapping(selectedId, mapping.product_id)} style={{ backgroundColor: '#F49C7D', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>
                                                    Delete
                                                </button>
                                            </td>
                                        )
                                            :
                                            (
                                                <td style={{ padding: '10px' }}>
                                                    <button onClick={async () => await handleDeleteServiceMappingPermanent(selectedId, mapping.product_id)} style={{ backgroundColor: '#F49C7D', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>
                                                        Delete
                                                    </button>
                                                </td>
                                            )
                                        }
                                    </tr>
                                );
                            })}
                            {!serviceMappings && (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: 'center' }}>No service mappings available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <CopyButton handleClick={() => { handleSubmitCloneProducts(); }} allowCopy={collapsedOptions.find(item => item.selected === true)} />
                <div style={{
                    display: 'flex',
                    justifyContent: 'left',
                    width: '50vw',
                    flexDirection: 'column',
                    border: '2px solid #87D3D7',
                    maxHeight: '610px', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'left',
                        flexDirection: 'column',
                        width: '100%',
                    }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <div style={{ fontStyle: 'italic', width: '50%', padding: '5px' }}>
                                <Select
                                    defaultValue={{ value: selectedService.service_name, label: selectedService.service_name }}
                                    name="services"
                                    options={uniqueServiceOptions.map(item => ({
                                        value: item.service_name,
                                        label: item.service_name
                                    })).sort((a, b) => a.label.localeCompare(b.label))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={handleCloneServiceChange}
                                    display='flex'
                                    placeholder="Select Service"  // Placeholder text
                                />
                            </div>
                            <div style={{ fontStyle: 'italic', width: '50%', padding: '5px' }}>
                                <Select
                                    // defaultValue={[options[1], options[2]]}
                                    defaultValue={compareLocation}
                                    isMulti
                                    isClearable
                                    name="locations"
                                    options={compareLocations
                                        .map(location => ({ value: location.location_name, label: location.location_name }))
                                        .sort((a, b) => a.label.localeCompare(b.label))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={handleCloneLocationChange}
                                    display='flex'
                                    placeholder="Select Location"  // Placeholder text
                                />
                            </div>
                            <div style={{ width: '15%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                                <input
                                    type="checkbox"
                                    checked={showAll}
                                    onChange={() => setShowAll(!showAll)}
                                    style={{ width: '15px', height: '15px' }}
                                />
                                <label>
                                    Show All
                                </label>

                            </div>

                        </div>
                    </div>
                    {
                        (collapsedOptions.length === 0 && collapsedOptions.length > 0) ?
                            (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', // Centers horizontally
                                    alignItems: 'center',     // Centers vertically
                                }}>
                                    <div>No overlapping products are mapped to this service.</div>
                                </div>
                            ) : <div></div>
                    }
                    {
                        (collapsedOptions.length > 0) ? (

                            <div>
                                <table style={{
                                    width: '100%',
                                    borderCollapse: 'separate',
                                    borderSpacing: 0,
                                    position: 'relative', // Ensure positioning context

                                }}>
                                    <thead style={{
                                        position: 'sticky', // Stick the header
                                        top: 0, // Stick to the top of the table
                                        zIndex: 1 // Ensure it stays above the tbody rows
                                    }}>
                                        <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px' }}></th>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Product ID </th>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Product Name </th>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Service Name</th>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}>Usage Quantity</th>
                                            <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}>
                                                <input
                                                    checked={selectAllChecked}
                                                    type="checkbox"
                                                    onChange={(e) => handleSelectAll(e.target.checked)}
                                                    style={{ width: '15px', height: '15px' }}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {collapsedOptions.length > 0 && collapsedOptions.map((mapping, index) => {
                                            return (
                                                <React.Fragment key={index}>

                                                    <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                                                        <td
                                                            onClick={() => handleOptionClick(mapping.product_id)}
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <span>
                                                                {expandedOptions.includes(mapping.product_id) ?
                                                                    <FontAwesomeIcon icon={faAngleUp} /> :
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                }
                                                            </span>
                                                        </td>
                                                        <td style={{ padding: '10px', }}>{mapping.product_id}</td>
                                                        <td style={{ padding: '10px', }}>
                                                            {mapping.product_name}
                                                        </td>
                                                        <td style={{ padding: '10px', }}>
                                                            {mapping?.service_name || ''}
                                                        </td>

                                                        <td style={{ padding: '10px', textAlign: 'center' }}>{mapping.usage_quantity}</td>
                                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                                            <input
                                                                type="checkbox"
                                                                id="bulkCheckbox"
                                                                checked={mapping.selected}
                                                                onChange={handleSelectGroup(mapping.product_id, mapping.compare_service_id)}
                                                                style={{ width: '15px', height: '15px' }} // Adjust the scale and size as needed
                                                            />
                                                        </td>
                                                    </tr>
                                                    {expandedOptions.includes(mapping.product_id) &&
                                                        <tr>
                                                            {/* Render order details */}
                                                            <td colSpan="1"></td>
                                                            <td colSpan="24">
                                                                <div style={{ border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden' }}>
                                                                    <table style={{

                                                                        width: '100%',
                                                                        borderCollapse: 'separate',
                                                                        borderSpacing: 0,
                                                                        position: 'relative' // Ensure positioning context
                                                                    }}>
                                                                        <thead style={{
                                                                            position: 'sticky', // Stick the header
                                                                            top: 0, // Stick to the top of the table
                                                                        }}>
                                                                            <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Product ID </th>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Product Name </th>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Location</th>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Service ID (Location)</th>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Service Name  (Location)</th>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}>Usage Quantity</th>
                                                                                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}></th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {filteredCloneOptions.filter(item => item.product_id === mapping.product_id).length > 0 && filteredCloneOptions.filter(item => item.product_id === mapping.product_id).map((mapping, index) => {
                                                                                return (

                                                                                    <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >

                                                                                        <td style={{ padding: '10px', }}>{mapping.product_id}</td>
                                                                                        <td style={{ padding: '10px', }}>
                                                                                            {mapping.product_name}
                                                                                        </td>
                                                                                        <td style={{ padding: '10px', }}>{mapping.location_name}</td>
                                                                                        <td style={{ padding: '10px', }}>{mapping.compare_service_id}</td>
                                                                                        <td style={{ padding: '10px', }}>
                                                                                            {mapping?.compare_service_name || ''}
                                                                                        </td>

                                                                                        <td style={{ padding: '10px', textAlign: 'center' }}>{mapping.usage_quantity}</td>
                                                                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                id="bulkCheckbox"
                                                                                                checked={mapping.selected}
                                                                                                onChange={handleSelectRow(mapping.product_id, mapping.compare_service_id)}
                                                                                                style={{ width: '15px', height: '15px' }} // Adjust the scale and size as needed
                                                                                            />
                                                                                        </td>
                                                                                    </tr>);
                                                                            })
                                                                            }

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </React.Fragment>


                                            );
                                        })}
                                        {/* {!serviceMappings && (
                                            <tr>
                                                <td colSpan="3" style={{textAlign: 'center'}}>No service mappings available</td>
                                            </tr>
                                        )} */}
                                    </tbody>
                                </table></div>

                        ) :
                            <div>
                            </div>
                    }
                </div>
            </div>
            <br />
            <button className="support-button" onClick={serviceMappings && handleSubmit}
                style={{
                    opacity: serviceMappings ? 1 : 0.5, // Optionally adjust opacity
                    fontSize: 'small',
                }}
            >
                Submit
            </button>
            <br />
            <br />
            <ProductTable products={currentInventory} onClick={handleAddInventoryProductMapping} />

            <br />
            <button
                className='support-button'
                style={{ marginRight: '20px', fontSize: 'small' }}
                onClick={() => setNewProductForm(true)}
                disabled={newInventoryForm}
            >
                New Product
            </button>
            <button
                className='support-button'
                style={{ marginRight: '20px', fontSize: 'small' }}
                onClick={() => setNewInventoryForm(true)}
                disabled={newProductForm}
            >
                New Inventory
            </button>
            <button
                className='support-button'
                style={{ marginRight: '20px', fontSize: 'small' }}

                onClick={() => {
                    setNewInventoryForm(false);
                    setNewProductForm(false);
                    setSubmitReady(false);
                    setNewProduct(null);
                    // setNewProductMapping(false);
                    // setNewInventoryMapping(false);
                    // setProductDone(false);
                    // setPriceDone(false);
                    // setInventoryDone(false);
                    // setLoading(false);
                }
                }
            >
                Cancel
            </button>
            <br />
            <br />
            {submitReady && (
                <>
                    <button
                        className='support-button'
                        style={{ marginRight: '20px', fontSize: 'small' }}

                        onClick={async () => {
                            if (newInventoryForm) {
                                const success = await Data.addNewInventory(newInventory);
                                if (success) {
                                    Data.fetchInventory(selectedLocation, setProductOptions);
                                    setSubmitReady(false);
                                    setNewInventoryForm(false);
                                    setNewProductForm(false);
                                    setNewProduct(false);
                                }
                                else {
                                    toast.error('Failed to add to inventory.');
                                }
                            }
                            if (newProductForm) {
                                const success = await Data.addNewProduct(newProduct, newPrice, newInventory);

                                if (success) {
                                    Data.fetchInventory(selectedLocation, setProductOptions);
                                    setSubmitReady(false);
                                    setNewProductForm(false);
                                    setNewInventory(false);
                                    setNewProduct(null);
                                }
                                else {
                                    toast.error('Dailed to add to inventory.');
                                }
                            }
                            // await the insertion of the new product then call.
                            Data.fetchInventory(selectedLocation, setCurrentInventory);
                        }
                        }
                    >
                        Submit
                    </button>
                    <br />
                    <br />

                </>


            )}

            {(newInventoryForm && !submitReady) && (
                <>
                    {!newProduct && (
                        <ProductTable products={productsData.filter(item1 => !currentInventory.some(item2 => item2.product_id === item1.product_id))} onClick={(p) => setNewProduct(p)} />
                    )}
                    {newProduct && (
                        <ProductForm copyProduct={newProduct}
                            setNewInventory={setNewInventory} setNewPrice={setNewPrice} setSelectedProduct={() => { }}
                            onFinish={() => setSubmitReady(true)} selectedLocation={selectedLocation}
                            productOptions={productOptions}
                            onBack={() => setNewProduct(null)}
                            isExistingProduct={true}
                        />
                    )}
                </>
            )}
            {(newProductForm && !submitReady) && (
                <>
                    <ProductForm copyProduct={{}}
                        setNewInventory={setNewInventory} setNewPrice={setNewPrice} setSelectedProduct={setNewProduct}
                        onFinish={() => setSubmitReady(true)}
                        selectedLocation={selectedLocation}
                        productOptions={productOptions}
                        onBack={() => setNewProductForm(false)} isExistingProduct={false}
                    />
                </>
            )}
        </div>
    );

};

export default ServiceMapping;