import React, {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

export const ResolveButtons = ({confirmCallback, resolveCallback}) => {
    const [confirm, setConfirm] = useState(false);

    return (
        <>
            {confirm && (
                <>
                    <button
                        className="support-button"

                        onClick={async () => {
                            confirmCallback();
                            setConfirm(false);
                        }}
                        style={{marginRight: '10px', fontSize: 'small', width: '90px'}}

                    >
                        Submit
                    </button>
                    <button
                        className="support-button"

                        onClick={async () => {
                            setConfirm(false);
                        }}
                        style={{fontSize: 'small', width: '90px'}}

                    >
                        Cancel
                    </button>
                </>

            )
            }
            {
                !confirm && (
                    <>
                        {confirmCallback && (
                            <button
                                className="support-button"
                                onClick={async () => {
                                    setConfirm(true);
                                }}
                                style={{marginRight: '10px', fontSize: 'small', width: '90px'}}
                            >
                                Confirm
                            </button>
                        )}

                        <button
                            className="support-button"
                            style={{fontSize: 'small', width: '90px'}}

                            onClick={async () => {
                                resolveCallback();
                            }}
                        >
                            Resolve
                        </button>
                    </>

                )
            }
        </>
    );
};

export const CopyButton = ({handleClick, allowCopy}) => {
    return (
        <button
            className="support-button"
            onClick={handleClick}
            style={{
                marginLeft: '8px',
                marginRight: '8px',
                width: '5%',
                opacity: allowCopy ? 1 : 0.5, // Optionally adjust opacity
                pointerEvents: allowCopy ? 'auto' : 'none', // Optionally disable pointer events
                maxHeight: '40px',  // Limit the height of the button

            }}
        >
            {
                <FontAwesomeIcon icon={faArrowLeft} />
            }
        </button>);
};