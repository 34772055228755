import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import InventoryTable from './InventoryTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { fetchUsername } from '../../utils/users/userUtils';
import { fetchTicketInfo } from '../../utils/locations/locationsUtils';

// Make sure to bind the modal to your appElement
Modal.setAppElement('#root');

const FreeProductModal = ({isOpen, onClose, ticketInfo, setTicketInfo}) => {

    const [selectedProduct, setSelectedProduct] = useState([]);
    const [freeProductQuantity, setFreeProductQuantity] = useState(0);
    const [username, setUsername] = useState([]);

    useEffect(() => {

      fetchUsername(setUsername);
    
    }, []);

    const resetAllFreeProduct = () => {
        setSelectedProduct([]);
        setFreeProductQuantity(0);
    };

    const addFreeQuantity = async (productId, locationName, newQuantity) => {
  
        if (newQuantity === 0){
          alert('You cannot submit a quantity equal to 0.')
          return;
        }
    
        let status_order = ticketInfo.bundle_status;
    
        if (status_order === 'Pending'){
          status_order = 'Open';
        }
    
        // Define the order data to be sent in the request body
        const orderData = {
          order_code: ticketInfo.order_code,
          location_name: locationName,
          product_id: productId,
          order_quantity: newQuantity,
          unit_price: 0,
          vat_percent: 0,
          batch_expiration: null,
          internal_description: 'Free product',
          client_comment: '',
          status_cat: status_order,
          status_rationale: '',
          recommendation_rationale: '',
          due_date: null,
          payment_date: null,
          order_comment: username,
          recommendation_quantity: null,
          order_urgency: '',
          bundle_date: ticketInfo.date
        };
    
        let lastOrderId = -1;
      
        // Make a POST request to the server endpoint to add a new order
        await fetch(process.env.REACT_APP_BACKEND_URL + '/add-order', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(orderData)
        })
        .then(response => {
          // Check if the response is successful (status code 201)
          if (response.status === 201) {
            // Parse the JSON response
            return response.json();
          } else {
            // If response is not successful, throw an error
            throw new Error('Failed to add new order');
          }
        })
        .then(data => {
          // Extract the newly added order ID from the response data
          lastOrderId = data.order_id;
      
          // Do something with the newly added order ID
          console.log('Newly added order ID:', lastOrderId);
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error('Error:', error.message);
        });
      
        if (lastOrderId != -1){
      
          // Create order tracking
          await fetch(process.env.REACT_APP_BACKEND_URL + '/store-orders-tracking', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              order_id: lastOrderId,
              location_name: locationName,
              product_id: productId,
              order_quantity_before: newQuantity, // Get old order quantity from fetched old data
              order_quantity_after: newQuantity, // Get new order quantity from editedOrder
              unit_price_before: 0, // Get old unit price from fetched old data
              unit_price_after: 0, // Get new unit price from editedOrder
              order_comment_before: '', // Get old internal description from fetched old data
              order_comment_after: username, // Get new internal description from editedOrder
              status_cat_before: 'Free product',
              status_cat_after: status_order,
              change_datetime: '', // Handle in server.js
              order_quantity_before: '',
              order_quantity_after: ''
            }),
          })
          .then(response => response.json())
          .then(result => {
            if (result.message) {
              console.log('Order tracking data inserted successfully');
            } else {
              console.error('Failed to insert order tracking data');
            }
          })
          .catch(error => console.error('Error inserting order tracking data:', error));
        
        }
    
        await fetchTicketInfo(setTicketInfo, ticketInfo.bundle_id)
        resetAllFreeProduct();
        
      
      };

    return (
        <Modal
            overlayClassName="modal-overlay"
            className="modal-content"
            isOpen={isOpen}
            onRequestClose={() => {
                resetAllFreeProduct();
                onClose();
            }}
            style={{
                overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 2
                },
                content: {
                width: '60%',
                height: '70%',
                }
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button 
                    className="button-style"
                    onClick={() => { resetAllFreeProduct(); onClose(); }}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>

            <h3>Add Free Product</h3>
            
            <>
                <InventoryTable locationName={ticketInfo.location_name} setSelectedProduct={setSelectedProduct} />
                <br/>
                {selectedProduct.length !== 0 ? (
                <>
                    <h4>{selectedProduct.product_name}</h4>
                    <div className="input-group">
                    Quantity:
                    <input
                        type="number"
                        value={freeProductQuantity}
                        onChange={(e) => setFreeProductQuantity(parseFloat(e.target.value))}
                        style={{ width: '20%', marginLeft: '20px', marginRight: '20px' }}
                    />
                    <button
                        onClick={() => addFreeQuantity(selectedProduct.product_id, ticketInfo.location_name, parseFloat(freeProductQuantity))}
                        style={{
                        border: '1px solid #31bac1',
                        backgroundColor: '#31bac1',
                        color: '#fff',
                        padding: '8px 12px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        fontSize: '16px'
                        }}
                    >
                        Submit
                    </button>
                    </div>
                </>
                ) : (
                <h4>No Product Selected.</h4>
                )}
            </>
        </Modal>
    );
};

export default FreeProductModal;
