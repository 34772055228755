import { newDate } from '../time_zones/TimeZones';

/**
 * Function to fetch locations data.
 * @param {Function} setLocations - A callback function to set the locations in the state.
 * @param {string} call - The base URL for the API.
 */
export const fetchLocations = (setLocations) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/locations')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setLocations(data);
        })
        .catch(error => console.error('Error fetching locations data:', error));
};

/**
 * Function to fetch detailed information about a specific bundle.
 * @param {Function} setTicketInfo - A callback function to set the ticket information in the state.
 * @param {string} bundle_id - The bundle ID to fetch the detailed information for.
 */
export const fetchTicketInfo = async (setTicketInfo, bundleId) => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get-ticket-info', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bundle_id: bundleId }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setTicketInfo(data); // Update the ticket information state with the fetched data
    } catch (error) {
      console.error('Error fetching ticket information:', error);
    }
};
  
/**
 * Function to fetch detailed information about all the bundles.
 * @param {Function} setTicketsInfo - A callback function to set the tickets information in the state.
 */
export const fetchTicketsInfo = async (setTicketsInfo, locationName, abortController = null) => {
    await fetch(process.env.REACT_APP_BACKEND_URL + '/get-tickets-info', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName }),
        signal: abortController ? abortController.signal : undefined,
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        setTicketsInfo(data); // Update the ticket information state with the fetched data
    })
    .catch(error => {
        if (error.name === 'AbortError') {
            console.log('Fetch request was aborted');
        } else {
            console.error('Error fetching ticket information:', error);
        }
    });

    return () => {
        abortController.abort();
    };
};


/**
 * Function to fetch inventory data for a specific location.
 * @param {string} locationName - The name of the location to filter the inventory data.
 * @param {Function} setInventoryData - A callback function to set the inventory data in the state.
 */
export const fetchInventory = async (locationName) => {
    try {

        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/inventory');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        const locationInventoryData = data.filter(item => item.location_name === locationName);

        return locationInventoryData;
    } catch (error) {
        console.error('Error fetching inventory data:', error);
    }
};

/**
 * Function to fetch inventory data for a specific location.
 * @param {string} locationName - The name of the location to filter the inventory data.
 * @param {Function} setInventoryData - A callback function to set the inventory data in the state.
 */
export const fetchInventoryData = async (locationName, setInventoryData) => {
    try {

        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/inventory');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        let locationInventoryData = data;

        if (locationName !== 'All Locations'){
            locationInventoryData = data.filter(item => item.location_name === locationName);
        }

        setInventoryData(locationInventoryData);

        return locationInventoryData;
    } catch (error) {
        console.error('Error fetching inventory data:', error);
    }
};

/**
 * Fetches bundles data from the API and updates the state.
 * @param {Function} setBundlesData - A callback function to update the bundles state.
 * @param {string} call - The base URL for the API.
 */
export const fetchBundles = (setBundlesData) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/get_bundles')
        .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
        })
        .then(data => {
        setBundlesData(data); // Update the bundles state with the fetched data
        })
        .catch(error => console.error('Error fetching bundles data:', error));
};
  
/**
 * Fetches order data from the API and updates the state.
 * @param {Function} setOrderData - A callback function to update the orders state.
 * @param {string} locationName - The location name to filter the orders by.
 * @param {string} call - The base URL for the API.
 */
export const fetchOrderData = (setOrderData, locationName) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/all-orders')
        .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
        })
        .then(data => {
        // Filter the orders based on the location name
        const locationOrderData = locationName === 'All Locations'
            ? data
            : data.filter(item => item.location_name === locationName);

        setOrderData(locationOrderData); // Update the orders state with the fetched data
        })
        .catch(error => console.error('Error fetching order data:', error));
};

/**
 * Function to fetch all suppliers data.
 * @param {Function} setSuppliersData - A callback function to set the suppliers data in the state.
 */
export const fetchSuppliersData = (setSuppliersData) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/get-all-suppliers', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        // Filter out null supplier names and ensure unique entries
        const filteredData = Array.from(new Set(data
            .filter(item => item.supplier_name !== null) // Filter out null values
            .map(item => JSON.stringify(item)) // Convert objects to JSON strings for uniqueness
        )).map(item => JSON.parse(item)); // Convert back to objects

        setSuppliersData(filteredData); // Update the suppliers data state with the fetched data
    })
    .catch(error => console.error('Error fetching suppliers data:', error));
};

/**
 * Function to update the tracking number for a specific bundle.
 * @param {string} bundleId - The ID of the bundle for which the tracking number needs to be updated.
 * @param {string} trackingNumber - The new tracking number to be set.
 */
export const updateTrackingNumber = async (bundleId, trackingNumber) => {
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/update-tracking-number', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ bundleId, trackingNumber }),
        });

        if (!response.ok) {
            throw new Error('Failed to update tracking number');
        }

        console.log('Tracking number updated successfully');
    } catch (error) {
        console.error('Error updating tracking number:', error.message);
        throw error; // Propagate the error further
    }
};

/**
 * Function to update the paid status of a specific bundle.
 * @param {string} paidStatus - The new paid status for the bundle.
 * @param {string} orderCode - The order code associated with the bundle.
 */
export const updateBundlePaidStatus = async (paidStatus, orderCode) => {
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/update-bundle-paid-status', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paid_status: paidStatus, order_code: orderCode }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to update bundle paid status');
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error updating bundle paid status:', error.message);
        throw error; // Propagate the error further
    }
};

/**
 * Function to update a bundle with the specified details.
 * @param {string} bundleDate - The date of the bundle in yyyy-mm-dd format.
 * @param {string} orderCode - The order code associated with the bundle.
 * @param {number} shipping - The shipping cost for the bundle.
 * @param {number} taxes - The taxes applied to the bundle.
 * @param {number} discount - The discount applied to the bundle.
 * @param {string} comment - Any additional comments about the bundle.
 * @param {string} dueDate - The due date in yyyy-mm-dd format.
 * @param {string} dueDateOption - An optional flag indicating due date conditions.
 */
export const updateBundle = async (bundleDate, orderCode, shipping, taxes, discount, comment, dueDate, dueDateOption, eta='') => {
      try {

        const dueDateProcessed = dueDate.split('T')[0] || dueDate;

        // Check if dueDate has the format yyyy-mm-dd and is a valid date
        const dueDateFormat = /^\d{4}-\d{2}-\d{2}$/;
        if (!dueDateFormat.test(dueDateProcessed) || isNaN(Date.parse(dueDateProcessed))) {
            alert("Due date should be in the format yyyy-mm-dd and must be a valid date.");
            return false; // Return to stop the function
        } else if (dueDateOption === 'later' && newDate(dueDateProcessed) < newDate(bundleDate)) {
            alert("Due date has to be later than bundle date.");
            return false;
        }

        // Make a PUT request to the API endpoint
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/update-bundle', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                bundleDate,
                orderCode,
                shipping,
                taxes,
                discount,
                comment,
                dueDate: dueDateProcessed,
                eta
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to update bundle');
        }

        // Handle the response
        await response.json();

        return true; // Return true to indicate success
      } catch (error) {
        // Handle errors
        console.error('Error updating bundle:', error);

        return false; // Return false to indicate failure
      }
};
  
export const fetchSuppliers = async (locationName, supplierId) => {
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get-suppliers-info', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                locationName,
                supplierId
            }),
        });

        if (!response.ok) {
            throw new Error(`Error fetching suppliers: ${response.statusText}`);
        }
        const suppliers = await response.json();

        return suppliers;
    } catch (error) {
        console.error('Error fetching suppliers:', error);
        return [];
    }
};

/**
 * Fetches suppliers based on the provided manufacturer name and updates the state with the results.
 *
 * @param {string} manufacturerName - The name of the manufacturer to filter suppliers.
 * @param {function} setSuppliers - A state setter function to store the fetched suppliers data.
 */
export const fetchSupplierPerManufacturer = async (locationName, manufacturerName, setSuppliers) => {
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get-suppliers-per-manufacturer-name', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName, manufacturer_name: manufacturerName }),
        });

        if (!response.ok) {
            throw new Error(`Error fetching suppliers: ${response.statusText}`);
        }

        const suppliersData = await response.json();
        setSuppliers(suppliersData);
    } catch (error) {
        console.error('Error in fetchSupplierPerManufacturer:', error);
        setSuppliers([]); // Optionally clear suppliers on error
    }
};

/**
 * Fetches the total orders per location.
 * @param {Function} setData - A callback function to set the data in the state.
 */
export const fetchOrdersPerLocation = (setData) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/orders-per-location')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setData(data);
        })
        .catch(error => console.error('Error fetching orders per location:', error));
};

/**
 * Fetches the average order value per location.
 * @param {Function} setData - A callback function to set the data in the state.
 */
export const fetchAvgOrderValuePerLocation = (setData) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/avg-order-value-per-location')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setData(data);
        })
        .catch(error => console.error('Error fetching average order value per location:', error));
};

/**
 * Fetches the top products by sales.
 * @param {Function} setData - A callback function to set the data in the state.
 */
export const fetchTopProductsBySales = (setData) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/top-products-by-sales')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setData(data);
        })
        .catch(error => console.error('Error fetching top products by sales:', error));
};

/**
 * Fetches the orders paid vs unpaid.
 * @param {Function} setData - A callback function to set the data in the state.
 */
export const fetchInvoiceStatuses = (setData) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/invoices-statuses')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setData(data);
        })
        .catch(error => console.error('Error fetching invoices statuses:', error));
};

export const GetStatusColor = (status) => {
    switch (status) {
        case 'Placed':
            return '#71B1D9';
        case 'Confirmed':
            return 'gold';
        case 'Backorder':
            return 'brown';
        case 'In Review':
            return 'orange';
        case 'Open':
            return '#F49C7D';
        case 'Shipped':
            return 'green';
        case 'Delivered':
            return '#006400';
        case 'Recommend':
            return 'grey';
        case 'Remove':
            return 'purple';
        case 'Moved':
            return 'blue';
        case 'Declined':
            return 'black';
        default:
            return 'grey';
    }
};