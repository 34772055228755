import React, {useState, useEffect} from 'react';
import { fetchUser } from '../utils/users/userUtils';
import UserProfile from './UserProfile';

const TopBar = ({ title }) => {
  const [user, setUser] = useState('');

  useEffect(() => {
    fetchUser(setUser);
  }, []);

  return (
    <div className="top-bar">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{ textAlign: 'left' }}>
            <h1 style={{fontSize: '40px', fontWeight: 'bold', color: '#87D3D7' }}>{title}</h1>
        </div>

        <UserProfile user={user} />
      </div>
    </div>
  );
};

export default TopBar;
