import React, {useState, useEffect, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane, faUser, faPaperclip, faCaretDown} from '@fortawesome/free-solid-svg-icons';
import {fetchEmailConversation, replyToEmail2, getFileBlob} from '../../utils/notifications/notificationsUtils';
import loadingSpinner from '../../assets/images/loading.gif';
import {toast} from 'react-toastify';
import ContentEditable from 'react-contenteditable';
import {updateData, parseEmails} from '../../utils/support/supportUtils';
import {AttachmentPreview} from './AttachmentPreview';
import {fetchUserInfo} from '../../utils/users/userUtils';

/**
 * `SupportMessaging` is a React functional component that displays the messaging
 * details of a support ticket, including the latest email conversation and its
 * attachments. It allows for interaction with and management of the email conversation.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.ticket - The support ticket object containing details about the ticket.
 * @param {Object} props.emailConversation - An object representing the current email conversation related to the ticket.
 * @param {Object} props.emailConversation.lastMessage - The most recent message in the conversation.
 * @param {string} props.emailConversation.lastMessage.body - The body of the last email message.
 * @param {string} props.emailConversation.lastMessage.from - The email address of the sender.
 * @param {string} props.emailConversation.lastMessage.sent_date - The date the last email message was sent.
 * @param {string} props.emailConversation.lastMessage.subject - The subject of the last email message.
 * @param {string} props.emailConversation.lastMessage.message_id - The unique ID of the last email message.
 * @param {Array<string>} props.emailConversation.lastMessage.to_recipients - A list of email addresses of the primary recipients.
 * @param {Array<string>} props.emailConversation.lastMessage.cc_recipients - A list of email addresses of the CC recipients.
 * @param {Array<Object>} props.emailConversation.attachments - A list of attachments associated with the email conversation.
 * @param {Function} props.setEmailConversation - A function to update the email conversation state.
 *
 * @returns {JSX.Element} The rendered component, which includes the details of the last message
 * and any attachments related to the support ticket.
 */
const SupportMessaging = ({ticket, emailConversation, setEmailConversation}) => {
    const {body, from, sent_date, subject, message_id, to_recipients, cc_recipients} = emailConversation.lastMessage ?? {};
    const attachments = emailConversation.attachments;
    const [replyMessage, setReplyMessage] = useState('');
    const [senderEmail, setSenderEmail] = useState('info@medvelle.com');//useState(ticket.account); 
    const [recipientEmails, setRecipients] = useState(from && from.endsWith('@medvelle.com') ? to_recipients : [from]);
    const [ccEmails, setCCEmails] = useState(cc_recipients);
    const [isSendReplyLoading, setIsSendReplyLoading] = useState(false);
    const [templateType, setTemplateType] = useState('None');
    const [userInfo, setUserInfo] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [replyPressed, setReplyPressed] = useState(false);
    const [file, setFile] = useState('');
    const [sendFiles, setSendFiles] = useState([]); 

    const fileInputRef = useRef(null);

    useEffect(() => {
        fetchUserInfo(ticket.username, ticket.location_name, setUserInfo);
    }, []);
    useEffect(() => {
        if (file) {
            setSendFiles([...sendFiles, file]);
        }
        setFile('')
    }, [file]);

    const loadFile = (content, type, setBlob) => {
        const blob = new Blob([content], {type: type});
        setBlob(blob);
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const removeFile = (index) => {
        let allFiles = [...sendFiles];
        allFiles.splice(index, 1);
        setSendFiles(allFiles);
    }

    const fileSelected = (event) => {
        let f = event.target.files[0];

        setFile(f);
        fileInputRef.current.value = null;
    }

    // Function to update the reply message based on selected template
    const updateReplyMessage = (template) => {
        if (template === 'None') {
            setReplyMessage('');
        }
        if (template === 'Personalized') {
            setReplyMessage(`
                Dear ${ userInfo.first_name },<br><br>
                Thank you for reaching out.<br><br>
                Best regards,<br>
                Medvelle Team
            `);
        } else if (template === 'Thank You') {
            setReplyMessage(`
                Great, thank you!<br><br>
                Best regards,<br>
                Medvelle Team
            `);
        }
    };

    // Handle template type change
    const handleTemplateChange = (e) => {
        const selectedTemplate = e.target.value;
        setTemplateType(selectedTemplate);
        updateReplyMessage(selectedTemplate);
    };

    const handleReply = async () => {
        try {
            if (!replyMessage) {
                toast.error('Cannot send without a reply message.');
                return;
            }
            setIsSendReplyLoading(true);
            await replyToEmail2(senderEmail, message_id, replyMessage, recipientEmails, ccEmails, sendFiles);
            updateData({}, {ticket_id: ticket.ticket_id}, 'support_tickets', ['last_email_datetime']);
            setIsSendReplyLoading(false);
            fetchEmailConversation(ticket.email_id, senderEmail, setEmailConversation);
            toast.success('Reply sent successfully!');
            setReplyMessage('');
            setTemplateType('None');
            setSendFiles([]);
            // onClose();
        } catch (error) {
            toast.error('Failed to send reply:', error);
            console.error('Failed to send reply:', error);
            setIsSendReplyLoading(false);
        }
    };

    return (
        <div className="placed-flow">
            <div style={{display: 'flex'}}>
                <button
                    className='support-button'
                    style={{
                        // Circular border
                        borderRadius: '50%', // Make the button circular
                        padding: '10px', // Padding for the icon
                        width: '50px', // Equal width and height to make it circular
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',

                        fontSize: '24px', // Icon size
                        marginRight: '20px',

                    }}

                    onClick={() => togglePopup()}
                >
                    <FontAwesomeIcon
                        icon={faUser}
                    />
                </button>
                <div >
                    <p><strong>{userInfo && (`${userInfo?.first_name ? userInfo.first_name : ''} ${userInfo?.last_name ? userInfo.last_name : ''} (${userInfo?.permission_description ? userInfo?.permission_description : ''})`)}</strong>
                    </p>
                    <div
                        style={{
                            maxHeight: isOpen ? '200px' : '0px', // Control height

                            // backgroundColor: '#fff',
                            overflow: 'hidden',
                            transition: 'max-height 0.5s ease',

                            // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Optional: for shadow
                            zIndex: 1000,
                            display: 'flex',
                            flexDirection: 'column', // Stack child elements vertically
                            alignItems: 'flex-start', // Align children to the start (left)
                            justifyContent: 'flex-start', // Align children to the start (top)
                        }}
                    >

                        {userInfo && (
                            <>  
                                {userInfo?.user_email && (
                                    <p>{userInfo?.user_email}</p>
                                )}
                                {userInfo?.user_phone && (
                                    <p>{userInfo?.user_phone}</p>
                                )}
                            </>

                        )}

                    </div>
                </div>
            </div>
            <div className="email-flow">
                <div
                    style={{
                        border: '2px solid #87D3D7',
                        borderRadius: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '90%',
                        height: replyPressed ? '30vh' : '60vh',
                        transition: 'height 0.3s ease',
                        overflowY: 'auto',
                        boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        backgroundColor: 'white',
                        marginTop: '20px',
                        marginLeft: '20px'
                    }}
                >

                    <div>
                        <strong>Subject:</strong> {subject}
                        <br />
                        <strong>From:</strong> {from}
                        <br />
                        <strong>To:</strong> {to_recipients?.join(', ')}
                        <br />
                        <strong>CC:</strong> {cc_recipients?.join(', ')}
                        <br />
                        <strong>Last Message Date:</strong> {new Date(sent_date).toLocaleString()}
                    </div>
                    <div>
                        <div
                            className="email-body"
                            dangerouslySetInnerHTML={{__html: body}}
                            style={{
                                marginTop: '20px',
                            }}
                        />
                    </div>

                    {attachments && attachments.length > 0 && (
                        <div
                            style={{
                                overflow: 'visible',
                                position: 'sticky',
                                bottom: '0px',
                                left: '0px',
                                marginTop: '10px'
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-start',
                            }}>
                                {attachments.map((attachment, index) => <AttachmentPreview name={attachment.name} type={attachment.contentType} loadAsset={getFileBlob.bind(null, attachment.email_id, senderEmail, attachment.id)} />
                                )}  
                            </div>

                        </div>
                    )}
                </div>
                {!replyPressed && (
                    <>
                        <br></br>
                        <button
                            className="support-button"
                            onClick={async () => {
                                setReplyPressed(true);
                            }}
                            style={{marginRight: '10px', marginLeft: '20px'}}
                        >
                            Reply
                        </button>
                    </>
                )}
                {replyPressed && (
                    <>
                        <div style={{
                            marginTop: '10px', paddingLeft: '20px', paddingRight: '10px', paddingTop: '20px',
                            display: 'flex',
                            gap: '20px',
                            flexDirection: 'row'
                        }}>
                            <div style={{paddingRight: '20px'}}>
                                <label htmlFor="templateType">Template:</label>
                                <select
                                    id="templateType"
                                    value={templateType}
                                    onChange={handleTemplateChange}
                                    style={{width: '100%'}}
                                    className='select-box'
                                >   
                                    <option value="None">None</option>
                                    <option value="Personalized">Personalized</option>
                                    <option value="Thank You">Thank You</option>
                                </select>

                            </div>
                            <div style={{marginRight: '20px'}}>
                                <label htmlFor="senderEmail">Sender:</label>
                                <input
                                    type="email"
                                    id="senderEmail"
                                    value={senderEmail}
                                    onChange={(e) => setSenderEmail(e.target.value)}
                                    style={{width: '100%', cursor: 'text', marginRight: '20px'}}
                                    className='select-box'
                                />
                            </div>
                            <div style={{marginRight: '20px'}}>
                                <label htmlFor="recipientEmails">Recipient Email(s):</label>
                                <input
                                    type="email"
                                    id="recipientEmails"
                                    value={recipientEmails}
                                    onChange={(e) => setRecipients(parseEmails(e.target.value))}
                                    style={{
                                        width: '100%', cursor: 'text'
                                    }}
                                    className='select-box'

                                />
                            </div>
                            <div>
                                <label htmlFor="ccEmails">CC Email(s):</label>
                                <input
                                    type="email"
                                    id="ccEmails"
                                    value={ccEmails}
                                    onChange={(e) => setCCEmails(parseEmails(e.target.value))}
                                    style={{width: '90%', cursor: 'text'}}
                                    className='select-box'
                                />
                            </div>
                            <button
                                className="support-button"
                                onClick={() => {
                                    setReplyPressed(false);
                                }}
                                style={{
                                    marginTop: '10px', height: '100%', alignSelf: 'flex-end',
                                }}

                            >
                                <FontAwesomeIcon icon={faCaretDown} />
                            </button>



                        </div>
                        <br /> 
                        <label htmlFor="reply" style={{
                            marginRight: '10px', marginLeft: '20px'
                        }}>Reply:</label>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '20px'

                            }}
                        >

                            <ContentEditable
                                html={replyMessage}
                                onChange={(e) => setReplyMessage(e.target.value)}
                                style={{
                                    border: '2px solid #87D3D7',
                                    borderRadius: '8px',
                                    width: '100%',
                                    height: '20vh',
                                    padding: '5px',
                                    overflowY: 'auto',
                                    backgroundColor: '#fff',
                                    marginRight: '10px',
                                    marginBottom: '10px'

                                }}
                                onFocus={(e) => {
                                    e.target.style.border = '2px solid #31bac1'; // Color changes on focus
                                    e.target.style.outline = 'none'; // Remove blue outline
                                }}
                                onBlur={(e) => {
                                    e.target.style.border = '2px solid #87D3D7'; // Reverts to original color on blur
                                }}

                            />

                            {(isSendReplyLoading) ? (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
                                    <img
                                        src={loadingSpinner}
                                        alt="loading-spinner"
                                        style={{
                                            height: '20px',
                                            marginRight: '10px',
                                            padding: '10px'
                                        }}
                                    />
                                </div>
                            ) : (
                                    <div style={{gap: '8px', display: 'flex', flexDirection: 'column'}}>
                                        <button
                                            className="support-button"
                                            onClick={async () => {
                                                await handleReply();
                                            }}
                                            style={{marginRight: '10px', }}

                                        >
                                            <FontAwesomeIcon icon={faPaperPlane} />
                                        </button>
                                        <input
                                            type="file"
                                            accept="*"
                                            onChange={fileSelected}
                                            ref={fileInputRef}
                                            style={{display: 'none', marginRight: '10px'}} // Hide the actual file input
                                            id="fileInput" // Assign an ID for the label to reference
                                            disabled={sendFiles.length >= 3}
                                        />
                                        <label htmlFor="fileInput"
                                            className="support-button"
                                            style={{marginRight: '10px', color: sendFiles.length >= 3 ? 'grey' : '', cursor: sendFiles.length >= 3 ? 'not-allowed' : ''}}
                                        >
                                            <FontAwesomeIcon icon={faPaperclip} />
                                        </label>
                                    </div>

                            )}
                        </div>
                        {sendFiles && sendFiles.length > 0 && (
                            <div
                                style={{
                                    overflow: 'visible',
                                    // position: 'sticky',
                                    bottom: '0px',
                                    left: '0px',
                                    // marginTop: '10px'
                                }}
                            >
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                }}>
                                    {sendFiles.map((attachment, index) => <AttachmentPreview name={attachment.name} type={attachment.type} loadAsset={loadFile.bind(null, attachment, attachment.type)} dismissable={removeFile.bind(null, index)} />
                                    )}
                                </div>

                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default SupportMessaging;
