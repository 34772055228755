import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Recommendation from './pages/Recommendation';
import Automation from './pages/Automation';
import System from './pages/System';
import Payments from './pages/Payments';
import Invoices from './pages/Invoices';
import Mapping from './pages/Mapping';
import Operations from './pages/Operations';
import Suppliers from './pages/Suppliers';
import Account from './pages/Account';
import Support from './pages/Support'
import { fetchUsername, fetchPermissionCodeForDefaultLocation } from './utils/users/userUtils'

import NavBar from './components/NavBar';

import './assets/style/index.css';

const App = () => {
  const call = process.env.REACT_APP_BACKEND_URL;

  const locationName = 'Medvelle Test';
  const [username, setUsername] = useState(null);
  const [permissionCode, setPermissionCode] = useState(null);

  const getPermissionCode = async () => {
    await fetchUsername(setUsername);
    if (username) {
      await fetchPermissionCodeForDefaultLocation(locationName, username, setPermissionCode);
    }
  };

  useEffect(() => {
    getPermissionCode();
  }, [username]);

  return (
    <>
      <NavBar />
      { permissionCode === 11 &&
        (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/recommendations" element={<Recommendation locationName={locationName} call={call} />} />
            <Route path="/automation" element={<Automation locationName={locationName} call={call} />} />
            <Route path="/operations" element={<Operations />} />
            <Route path="/system" element={<System locationName={locationName} call={call} />} />
            <Route path="/invoices" element={<Invoices locationName={locationName} call={call} />} />
            <Route path="/payments" element={<Payments locationName={locationName} call={call} />} />
            <Route path="/mapping" element={<Mapping />} />
            <Route path="/suppliers" element={<Suppliers locationName={locationName} call={call} />} />
            <Route path="/support" element={<Support />} />
            <Route path="/account" element={<Account />} />
          </Routes>
        )
      }
    </>
  );
};

export default App;