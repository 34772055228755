import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import * as Data from '../../utils/mapping';
import {formatDateTimeText, formatDateToUTCString} from '../../utils/time_zones/TimeZones';
import {toast} from 'react-toastify';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faTableList} from '@fortawesome/free-solid-svg-icons';
import loadingSpinner from '../../assets/images/loading.gif';
import {fetchUsername} from "../../utils/users/userUtils";
import {ResolveButtons} from "./ResolveButtons";

const OrderReview = ({orderSpotcheck, setOrderSpotcheck, location}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editItem, setEditItem] = useState(0);

    // Removes the order from the list.
    const removeOrderId = (orderId) => {
        const revisedReviewOrders = [...orderSpotcheck].filter(obj => obj.order_id !== orderId);
        setOrderSpotcheck(revisedReviewOrders);
    };

    // Resolve the order by opening a modal to edit price or accpeting the price.
    const resolveOrder = (orderId, item) => {
        if (item) {
            setEditItem(item);
            setIsModalOpen(true);
        }
        else {
            const updateValues = {
                spotcheck_status: 1,
                spotcheck_date: null
            };
            Data.updateOrders(orderId, updateValues);
            removeOrderId(orderId);
        }
    };

    return (
        <div style={{
            border: '2px solid #87D3D7',
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'column',
            width: '100%',
            maxHeight: '610px', // Set a fixed height
            overflowY: 'auto', // Enable vertical scrolling
            boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
        }}>
            <table style={{
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing: 0,
                position: 'relative' // Ensure positioning context
            }}>
                <thead style={{
                    position: 'sticky', // Stick the header
                    top: 0, // Stick to the top of the table
                    zIndex: 1 // Ensure it stays above the tbody rows
                }}>
                    <tr style={{fontSize: '13px', backgroundColor: 'white'}}>
                        <th style={{borderBottom: '2px solid black', padding: '10px', width: '5%'}}>Order ID</th>
                        <th style={{borderBottom: '2px solid black', padding: '10px', width: '15%'}}>Order Date</th>
                        <th style={{borderBottom: '2px solid black', padding: '10px', width: '5%'}}>Order Status</th>
                        <th style={{borderBottom: '2px solid black', padding: '10px', width: '10%'}}>Product</th>
                        <th style={{borderBottom: '2px solid black', padding: '10px', width: '15%'}}>Product Description</th>
                        <th style={{borderBottom: '2px solid black', padding: '10px', width: '5%'}}>Product Tier</th>
                        <th style={{borderBottom: '2px solid black', padding: '10px', width: '5%'}}>Unit Price</th>
                        <th style={{borderBottom: '2px solid black', padding: '10px', width: '20%'}}>Origin</th>
                        <th style={{borderBottom: '2px solid black', padding: '10px', width: '20%', textAlign: 'center'}}>Resolve</th>
                    </tr>
                </thead>
                <tbody>
                    {orderSpotcheck.map((item, index) => (
                        <tr key={item.order_id} style={{fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff'}} >
                            <td style={{padding: '10px', }}>
                                <span>{item.order_id}</span>
                            </td>
                            <td style={{padding: '10px', }}>
                                <span>{formatDateTimeText(item.order_date)}</span>
                            </td>
                            <td style={{padding: '10px'}}>{item.status_cat}</td>

                            <td style={{padding: '10px'}}>{item.product_name}</td>
                            <td style={{padding: '10px'}}>{item.product_description}</td>
                            <td style={{padding: '10px'}}>{item.product_tier}</td>
                            <td style={{padding: '10px'}}>${item.unit_price.toFixed(2)}</td>
                            <td style={{padding: '10px'}}>{item.origin}</td>

                            <td style={{padding: '10px', textAlign: 'center'}}>
                                <ResolveButtons resolveCallback={() => resolveOrder(item.order_id, item)} confirmCallback={() => resolveOrder(item.order_id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {isModalOpen && (
                <>
                    <PriceModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} item={editItem} removeOrderId={removeOrderId} location={location} />
                </>
            )}

        </div>
    );
};


// Modal for editting the price.
const PriceModal = ({isModalOpen, setIsModalOpen, item, removeOrderId, location}) => {
    const [newPrice, setNewPrice] = useState(parseFloat(item.unit_price));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [previousPrices, setPreviousPrices] = useState([]);
    const [username, setUsername] = useState(false);

    useEffect(() => {
        fetchUsername(setUsername);
    }, []);

    const handleSubmit = async () => {
        try {
            if (item.unit_price !== newPrice) {
                const updateValues = {
                    spotcheck_status: 1,
                    spotcheck_date: null,
                    unit_price: newPrice
                };

                const success = await Data.updateOrders(item.order_id, updateValues);

                if (success) {
                    const track_success = await Data.updatePrice({
                        price: newPrice, // Pass the updated unit price
                        product_id: item.product_id, // Pass the updated unit price
                        location_name: location, // Pass the updated unit price
                        username: username, // Pass the updated unit price
                        origin: 'Order Review', // Pass the updated unit price
                    });
                    if (track_success) {
                        toast.success('New price set.');
                        removeOrderId(item.order_id);
                    }
                    else {
                        toast.error('Failed to update price');
                    }
                } else {
                    toast.error('Failed to update order');
                }
            }
            else {
                const updateValues = {
                    spotcheck_status: 1,
                    spotcheck_date: null
                };
                Data.updateOrders(item.order_id, updateValues);
                removeOrderId(item.order_id);
            }
        } catch (error) {
            console.error('Error updating orders:', error);
            toast.error('Failed to set new price due to an error.');
        } finally {
            setIsModalOpen(false);
        }
    };

    return (<Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={isModalOpen}
        appElement={document.getElementById('#root')}

        onRequestClose={() => {
            // Call toggleNotificationModal only when the modal is closed
            if (modalIsOpen) {
                setIsModalOpen(false);
            }
        }}
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 2,

            },
            content: {
                height: 'fit-content',
                background: '#f5f5f5',
                border: '2px solid #87D3D7',
                borderRadius: '8px',
                overflow: 'auto'
            }
        }}
    >


        <div style={{
            display: 'flex',
            justifyContent: 'center', // Center the header
            alignItems: 'center',
            overflow: 'auto',
            position: 'sticky',
            top: '0px',
            right: '0px'
        }}>
            <div style={{width: '20px'}}></div>
            <h2 style={{
                textAlign: 'center',
                flex: 1 // Allow the header to take up remaining space
            }}>
                {`Edit Order Price`}
            </h2>
            <button className="support-button" style={{
                marginLeft: 'auto', // Push the button to the far right
                fontSize: 'medium',
                postion: 'absolute',
                top: '0',
                right: '0'

            }} onClick={() => {
                setIsModalOpen(false);
            }}>
                <FontAwesomeIcon icon={faTimes} />
            </button>

        </div>
        <div style={{display: 'flex'}}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                border: '2px solid #87D3D7',
                padding: '10px',
                color: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxSizing: 'border-box', // Include padding in height/width calculations
                marginRight: '8px'
            }}>
                Order ID: {item.order_id}
            </div>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                border: '2px solid #87D3D7',
                padding: '10px',
                color: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxSizing: 'border-box',
                marginRight: '8px',
            }}>
                {item.product_name}
            </div>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                border: '2px solid #87D3D7',
                padding: '10px',
                color: 'black',
                display: item.product_description ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxSizing: 'border-box' // Include padding in height/width calculations
            }}>
                {item.product_description}
            </div>
        </div>

        <div style={{marginTop: '8px'}}>
            <label htmlFor="price" style={{fontSize: 'medium'}}>New Unit Price</label>
            <br />
            <div style={{display: 'flex'}}>
                <div>
                    <input className='select-box' type="number" step="any" id="price" name="price" value={newPrice} onWheel={(e) => e.target.blur()}
                        onChange={(e) => {setNewPrice(parseFloat(e.target.value));}} style={{marginTop: '8px', fontSize: 'medium', marginRight: '8px'}} />
                </div>

                {previousPrices.length > 0 && (
                    <>
                        <table style={{
                            width: '100%',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            paddingTop: '8px',
                            position: 'relative' // Ensure positioning context
                        }}>
                            <thead style={{
                                position: 'sticky', // Stick the header
                                top: 0, // Stick to the top of the table
                                zIndex: 1 // Ensure it stays above the tbody rows
                            }}>
                                <tr style={{fontSize: '13px', backgroundColor: 'white'}}>
                                    <th style={{borderBottom: '2px solid black', padding: '10px', width: '5%'}}>Order ID</th>
                                    <th style={{borderBottom: '2px solid black', padding: '10px', width: '15%'}}>Order Date</th>
                                    <th style={{borderBottom: '2px solid black', padding: '10px', width: '10%'}}>Price</th>

                                </tr>
                            </thead>
                            <tbody>
                                {previousPrices.map((item, index) => (
                                    <tr key={item.order_id} style={{fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff'}} >
                                        <td style={{padding: '10px', }}>
                                            <span>{item.order_id}</span>
                                        </td>
                                        <td style={{padding: '10px', }}>
                                            <span>{formatDateTimeText(item.order_date)}</span>
                                        </td>

                                        <td style={{padding: '10px'}}>${item.unit_price.toFixed(2)}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
                {previousPrices.length === 0 && (
                    <button className='support-button' style={{ marginTop: '8px', }} onClick={() => {
                        Data.fetchRecentPrices(location, item.product_id, setPreviousPrices);
                    }}>
                        <FontAwesomeIcon icon={faTableList} />
                    </button>
                )}
            </div>

        </div>
        <br />
        {isSubmitting && (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
                <img
                    src={loadingSpinner}
                    alt="loading-spinner"
                    style={{
                        height: '20px',
                        marginRight: '10px',
                        padding: '10px'
                    }}
                />
            </div>
        )}
        {!isSubmitting && (
            <button className='support-button' style={{fontSize: 'small'}} onClick={() => {
                setIsSubmitting(true);
                handleSubmit();
            }}>
                Submit
            </button>

        )}



    </Modal>);
};


export default OrderReview;