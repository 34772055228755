import { DateTime } from 'luxon';

// Function to check if the timezone is valid using Intl.DateTimeFormat
function isValidTimezone(timezone) {
    try {
        // Attempt to create a DateTimeFormat object with the timezone
        Intl.DateTimeFormat(undefined, { timeZone: timezone });
        return true;
    } catch (e) {
        return false;
    }
}

// Function to get the timezone from localStorage or default to 'America/New_York'
function getTimezone() {
    const timezone = localStorage.getItem('timeZone');
    if (isValidTimezone(timezone)) {
        return timezone;
    } else {
        return 'America/New_York';
    }
}

function formatDateToUTCString(date) {
    const timezone = getTimezone();

    // Convert the incoming date to a DateTime object in the specified timezone
    const dateObj = typeof date === 'string'
        ? DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss', { zone: timezone })
        : DateTime.fromJSDate(date).setZone(timezone);

    // Convert the DateTime object to UTC
    const utcDate = dateObj.toUTC();

    // Return the ISO string representation of the UTC date
    return utcDate.toISO();
}

async function formatDateLocationToUTCString(date, location_name) {
    try {
      // Fetch location data from the /get-locations API
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get-locations');
      const locations = await response.json();
      
      // Find the location object matching the given location_name
      const location = locations.find(loc => loc.location_name === location_name);

      let timezone = 'America/New_York';

      if (!location) {
        console.log(`Location with name ${location_name} not found.`);
      } else {
        timezone = location.time_zone;
      }

      // Convert the incoming date to a DateTime object in the specified timezone
      const dateObj = typeof date === 'string'
        ? DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss', { zone: timezone })
        : DateTime.fromJSDate(date).setZone(timezone);
  
      // Convert the DateTime object to UTC
      const utcDate = dateObj.toUTC();
  
      // Return the ISO string representation of the UTC date
      return utcDate.toISO();
    } catch (error) {
      console.error('Error fetching location data:', error);
      return null;
    }
}  

// Function to format a Date object to 'YYYY-MM-DD'
const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};  

function formatToUTC(date) {
    // Check if the input matches the expected format using a regular expression
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    
    if (!dateTimeRegex.test(date)) {
        console.warn("Invalid date format. Expected format: 'yyyy-MM-dd HH:mm:ss'");
        return null; // Or return an empty string, or a default date like '1970-01-01T00:00:00.000Z'
    }

    // Split the date string into components
    let [year, month, dayTime] = date.split('-');
    let [day, time] = dayTime.split(' ');

    // Return the properly formatted ISO 8601 UTC string
    return `${year}-${month}-${day}T${time}.000Z`; // Converts to "YYYY-MM-DDTHH:MM:SSZ" format
}

function formatDateToLocalString(date = '') {
    const timezone = getTimezone();

    // Parse the date into a DateTime object (assume input is in UTC)
    const dateObj = typeof date === 'string'
        ? DateTime.fromISO(date, { zone: 'utc' })
        : DateTime.fromJSDate(date).setZone('utc');

    // Convert the DateTime object from UTC to the specified timezone
    const localTime = dateObj.setZone(timezone);

    // Format the DateTime object in the local timezone to 'yyyy-MM-dd HH:mm:ss'
    return localTime.toFormat('yyyy-MM-dd HH:mm:ss');
}

function newDate(date = '') {
    const timezone = getTimezone();

    let dateTime;

    if (date === '') {
        // For the current date/time in the specified timezone
        dateTime = DateTime.now().setZone(timezone);
    } else {
        // Parse the date string assuming it's in UTC and convert it to the specified timezone
        dateTime = DateTime.fromISO(date, { zone: 'utc' }).setZone(timezone);
    }

    // Convert Luxon DateTime to native JavaScript Date object
    return dateTime.toJSDate();
}

function formatDateText(dateString) {
    try {
        // Check if dateStringInput is not a string and convert to ISO string if necessary
        if (typeof dateString !== 'string') {
            dateString = dateString.toISOString();
        }

        // Check if dateStringInput is an invalid date string
        if (dateString === '' || !DateTime.fromISO(dateString).isValid) {
            return '';
        }

        const timezone = getTimezone();

        const dateObj = DateTime.fromISO(dateString, { zone: 'utc' }).setZone(timezone);

        const dayOfWeek = dateObj.toFormat('ccc'); // Day of the week (e.g., Mon)
        const dayOfMonth = dateObj.toFormat('d'); // Day of the month (e.g., 25)
        const month = dateObj.toFormat('MMM'); // Month abbreviation (e.g., Aug)
        const year = dateObj.toFormat('yyyy'); // Full year (e.g., 2024)

        return `${dayOfWeek} ${month} ${dayOfMonth} ${year}`;
    } catch (error) {
        console.error('Error formatting date:', error);
        throw error;
    }
}

function formatDateTimeText(dateString) {
    try {
        // Check if dateStringInput is not a string and convert to ISO string if necessary
        if (typeof dateString !== 'string') {
            dateString = dateString.toISOString();
        }

        // Check if dateStringInput is an invalid date string
        if (dateString === '' || !DateTime.fromISO(dateString).isValid) {
            return '';
        }

        const timezone = getTimezone();

        const dateObj = DateTime.fromISO(dateString, { zone: 'utc' }).setZone(timezone);

        const dayOfWeek = dateObj.toFormat('ccc'); // Day of the week (e.g., Mon)
        const dayOfMonth = dateObj.toFormat('d'); // Day of the month (e.g., 25)
        const month = dateObj.toFormat('MMM'); // Month abbreviation (e.g., Aug)
        const year = dateObj.toFormat('yyyy'); // Full year (e.g., 2024)
        const hours = dateObj.toFormat('HH'); // Hours (e.g., 14)
        const minutes = dateObj.toFormat('mm'); // Minutes (e.g., 30)

        return `${dayOfWeek} ${month} ${dayOfMonth} ${year} ${hours}:${minutes}`;
    } catch (error) {
        console.error('Error formatting date:', error);
        throw error;
    }
}

export {
    formatDateToUTCString,
    formatDateLocationToUTCString,
    formatToUTC,
    formatDateToYYYYMMDD,
    formatDateToLocalString,
    formatDateText,
    formatDateTimeText,
    getTimezone,
    newDate
};
