import React from "react";
import './assets/style/index.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './pages/authentication/Signin';
import ForgotPasswordPage from './pages/authentication/ForgotPassword';
import App from './App';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('userEmail'); // Replace with your actual authentication logic
  return isAuthenticated ? children : <Navigate to="/" />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <App />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  </React.StrictMode>
);
