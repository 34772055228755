import React, { useEffect, useState, useRef } from 'react';

import TicketTable from '../features/operations/TicketTable';

import { fetchUserList } from '../utils/users/userUtils';
import { fetchLocations, fetchTicketsInfo, fetchInventoryData, fetchSuppliersData } from '../utils/locations/locationsUtils';
import { fetchOrderData } from '../utils/procurement/procurementUtils';
import loadingSpinner from '../assets/images/loading.gif';
import TopBar from '../components/TopBar';

const Operations = () => {

    const contentRef = useRef();

    const [locations, setLocations] = useState([]);
    const [locationName, setLocationName] = useState('All Locations');
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [ticketsInfo, setTicketsInfo] = useState([]);
    const [sortDirection, setSortDirection] = useState('');
    const [sortBundle, setSortBundle] = useState('');
    const [userAssigned, setUserAssigned] = useState('');
    const [searchProduct, setSearchProduct] = useState('');
    const [selectedCategories, setSelectedCategories] = useState(['Open', 'In Review']);
    const [selectedBundleUrgency, setSelectedBundleUrgency] = useState('');
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [selectedSupplierID, setSelectedSupplierID] = useState('');
    const [inventoryData, setInventoryData] = useState([]);
    const [ordersData, setOrdersData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [isTicketsLoading, setIsTicketsLoading] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);

    // Gets unique category of bundles
    const bundleStatusCategories = [
        'Placed',
        'Pending',
        'In Review',
        'Open',
        'Shipped',
        'Delivered',
        'Recommend',
        'Remove',
        'Declined',
        'Moved',
        'Confirmed',
        'Backorder'
    ];

    const uniqueManufacturers = Array.from(new Set(inventoryData
        .filter(item => item.manufacturer_name !== null)
        .map(item => item.manufacturer_name)));

    const bundleUrgencyLevels = Array.from(new Set(ordersData.map(item => item.order_urgency)));

    const filterAndSortTickets = ({
        ticketsInfo,
        searchProduct = '',
        selectedCategories = '',
        selectedBundleUrgency = '',
        selectedManufacturer = '',
        sortDirection = '',
        sortBundle = '',
        userAssigned = ''
    }) => {

        // Filter by searchProduct
        let filtered = ticketsInfo && ticketsInfo.filter(ticket => ticket &&
            ticket.orders.some(order => order &&
                typeof order.product_name === 'string' &&
                (searchProduct === '' || order.product_name.toLowerCase().includes(searchProduct.toLowerCase()))
            )
        );
    
        // Filter by bundle_status
        if (selectedCategories && selectedCategories.length !== 0) {
            filtered = filtered.filter(ticket => 
                selectedCategories.includes(ticket.bundle_status) || selectedCategories.includes("")
            );
        }
    
        // Filter by bundle_urgency
        if (selectedBundleUrgency !== '') {
            filtered = filtered.filter(ticket => 
                ticket.bundle_urgency === selectedBundleUrgency
            );
        }

        // Filter by bundle_manufacturer_name
        if (selectedManufacturer !== '') {
            filtered = filtered.filter(ticket => 
                ticket.bundle_manufacturer_name === selectedManufacturer
            );
        }

        // Filter by bundle_supplier_id
        if (selectedSupplierID !== '') {
            filtered = filtered.filter(ticket => 
                parseInt(ticket.bundle_supplier_id) === parseInt(selectedSupplierID)
            );
        }

        // Filter by bundle_manufacturer_name
        if (userAssigned !== '') {

            filtered = filtered.filter(ticket => 
                ticket.assigned_to === userAssigned
            );
        }

        // Sort by date
        if (sortDirection !== '') {
            filtered = filtered.sort((a, b) => 
                sortDirection === 'asc'
                    ? new Date(a.date) - new Date(b.date)
                    : new Date(b.date) - new Date(a.date)
            );
        }
    
        // Sort by bundle_id
        if (sortBundle !== '') {
            filtered = filtered.sort((a, b) => 
                sortBundle === 'asc'
                    ? a.bundle_id - b.bundle_id
                    : b.bundle_id - a.bundle_id
            );
        }
    
        return filtered;
    };

    const handleCategoryChange = (e) => {
        const { options } = e.target;
        const selectedValues = Array.from(options)
            .filter(option => option.selected)
            .map(option => option.value);
        setSelectedCategories(selectedValues);
    };

    const getTickets = async (abortController) => {
        setIsTicketsLoading(true);

        try {
            await fetchTicketsInfo(setTicketsInfo, locationName, abortController);
        } catch (error) {
            if (error.name !== 'AbortError') {
                throw error;
            }
        }

        setIsTicketsLoading(false);

    };

    useEffect(() => {
        const abortController = new AbortController();

        fetchUserList(setUserList);
        fetchLocations(setLocations);
        getTickets(abortController);
        fetchInventoryData(locationName, setInventoryData);
        fetchOrderData(locationName, setOrdersData);
        fetchSuppliersData(setSuppliers);

        contentRef.current.style.marginLeft = '250px';
        contentRef.current.style.marginRight = '49px';

        return () => {
            abortController.abort();
        };
    }, [locationName]);

    useEffect(() => {

        const filtered = filterAndSortTickets({
            ticketsInfo,
            searchProduct,
            selectedCategories,
            selectedBundleUrgency,
            selectedManufacturer,
            sortDirection,
            sortBundle,
            userAssigned
        });

        setFilteredTickets(filtered);

    }, [ticketsInfo, selectedSupplierID, searchProduct, selectedCategories, selectedBundleUrgency, selectedManufacturer, sortDirection, sortBundle, userAssigned]);

    return (
  
      <div ref={contentRef}>

        <TopBar title={'Procurement Dashboard'} />

        <select
            value={locationName}
            onChange={(e) => {setLocationName(e.target.value); setSelectedOrders([]);}}
            style={{marginBottom: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
        > 
            <option value="All Locations">All Locations</option>
            {locations.sort().map((location) => (
                <><option key={location.location_name} value={location.location_name}>
                    {location.location_name}
                </option></>
            ))}
        </select>

        <select
          value={sortDirection}
          onChange={(e) =>  setSortDirection(e.target.value)}
          style={{ marginLeft: '20px', padding: '10px', fontStyle: 'italic', width: '150px' }}
        >
          <option value="">Sort Date</option>
          <option value="asc">Date: Ascending</option>
          <option value="desc">Date: Descending</option>
        </select>
        
        <select
            value={sortBundle}
            onChange={(e) => setSortBundle(e.target.value)}
            style={{ marginLeft: '20px', padding: '10px', fontStyle: 'italic', width: '150px' }}
        >
            <option value="">Sort Bundle</option>
            <option value="asc">Bundle: Ascending</option>
            <option value="desc">Bundle: Descending</option>
        </select>

        <br/>

        <input
            type="text"
            placeholder="Search Product Name"
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
            style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '15%'}}
        />
    
        <select
            value={selectedBundleUrgency}
            onChange={(e) => setSelectedBundleUrgency(e.target.value)}
            style={{marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '15%' }}
        >
            <option value="">All Urgencies</option>
            {bundleUrgencyLevels.sort().map((urgency) => (
            <option key={urgency} value={urgency}>
                {urgency}
            </option>
            ))}
        </select>

        <select
            value={selectedManufacturer}
            onChange={(e) => setSelectedManufacturer(e.target.value)}
            style={{marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '15%' }}
        >
            <option value="">All Manufacturers</option>
            {uniqueManufacturers.sort().map(manufacturer => (
            <option key={manufacturer} value={manufacturer}>
                {manufacturer}
            </option>
            ))}
        </select>

        <select
            value={selectedSupplierID}
            onChange={(e) => setSelectedSupplierID(e.target.value)}
            style={{
                marginRight: '20px',
                padding: '10px',
                boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                border: '1px solid #87D3D7',
                fontStyle: 'italic',
                width: '15%'
            }}
        >
            <option value="">All Suppliers</option>
            {suppliers.sort((a, b) => a.supplier_name.localeCompare(b.supplier_name)).map(supplier => (
                <option key={supplier.supplier_id} value={supplier.supplier_id}>
                    {supplier.supplier_name}
                </option>
            ))}
        </select>

        <select
            value={userAssigned}
            onChange={(e) => setUserAssigned(e.target.value)}
            style={{marginBottom: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '15%' }}
        >
        <option value="">All Assignee</option>
        {userList.sort().map(user => (
            <option key={user.username} value={user.username}>
            {user.name}
            </option>
        ))}
        </select>

        <br/>

        <select
            multiple
            value={selectedCategories}
            onChange={handleCategoryChange}
            style={{
                marginBottom: '20px',
                padding: '10px',
                boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                border: '1px solid #87D3D7',
                fontStyle: 'italic'
            }}
        >
            <option value="">All Categories</option>
            {bundleStatusCategories.sort().map((category) => (
                <option key={category} value={category}>
                    {category.charAt(0).toUpperCase() + category.slice(1)} {/* Capitalize first letter */}
                </option>
            ))}
        </select>

        {isTicketsLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <img
                        src={loadingSpinner}
                        alt="loading-spinner"
                        style={{
                            height: '20px',
                            marginRight: '10px',
                        }} 
                    />
                </div>
            ) : (
                <>
                    {filteredTickets.length > 0 ? (
                        <TicketTable tickets={filteredTickets} userList={userList} setTickets={setTicketsInfo} locationName={locationName} suppliers={suppliers} selectedOrders={selectedOrders} setSelectedOrders={setSelectedOrders}/>
                    ) : (
                        <p>No tickets available.</p>
                    )}
                </>
            )

        }
        
      </div>
    );


  };


export default Operations;
