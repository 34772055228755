import React, { useEffect, useCallback, useState, useRef } from 'react';
import ServiceForm from './ServiceForm';
import { ServiceTable } from './ServiceTable';
import * as Data from '../../utils/mapping';
import ServiceMapping from './ServiceMapping';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import BackArrow from '../../components/BackArrow';

const ServiceSelection = ({ newService, backPressed, serviceOptions, selectedLocation }) => {
    const [newServiceOptions, setNewServiceOptions] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [isAdding, setIsAdding] = useState(true);
    const [copyService, setCopyService] = useState({});

    useEffect(() => {
        Data.fetchServicesData({ selectedLocation, onlyNew: true, callback: setNewServiceOptions });

    }, []);

    const backNewService = () => {
        setSelectedService(null);
        setIsAdding(true);
    };

    const handleAddService = async (service) => {
        setSelectedService(service);
    };

    const addService = async () => {
        const serviceId = await Data.addNewService(selectedService);
        if (serviceId) return serviceId;
        return false;
    };

    return (

        <div>
            {/* New service to be added and the process is not done. */}
            <span style={{ display: newService && isAdding ? '' : 'none' }}>
                <BackArrow onClick={backPressed} />
                <br />
                <br />
                <ServiceForm selectedLocation={selectedLocation} setSelectedService={setSelectedService} setIsAdding={setIsAdding} serviceOptions={newServiceOptions} />
            </span>

            {/* Use existing service. Selection is not finished yet. */}
            <span style={{ display: !newService && !selectedService ? '' : 'none' }}>
                <ServiceTable services={serviceOptions} onClick={handleAddService} onBack={backPressed} />
            </span>

            {/* A service has been selected for mapping. */}
            {selectedService && (
                <ServiceMapping
                    selectedService={selectedService}
                    selectedLocation={selectedLocation}
                    onBack={() => newService ? backNewService() : setSelectedService(null)}
                    onSubmit={() => newService ? backPressed() : setSelectedService(null)}
                    beforeOnSubmit={newService ? addService : null} />
            )}
        </div>
    );

};

export default ServiceSelection;;