import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { fetchTopProductsBySales } from '../../utils/locations/locationsUtils';

const TopProductsBySales = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchTopProductsBySales(setData);
  }, []);

  const chartData = {
    labels: data.map(entry => entry.product_name),
    datasets: [{
      label: 'Total Sales',
      data: data.map(entry => entry.total_sales),
      backgroundColor: 'rgba(255, 159, 64, 0.2)',
      borderColor: 'rgba(255, 159, 64, 1)',
      borderWidth: 1,
    }]
  };

  return (
    <div>
      <h2>Top Products by Sales</h2>
      <Bar data={chartData} options={{ responsive: true }} />
    </div>
  );
};

export default TopProductsBySales;
