import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'

import { signOut } from "firebase/auth";
import { auth } from "./authentication/Config";
import { fetchUserDetails } from "../utils/users/userUtils"
import TopBar from '../components/TopBar';

const Account = () => {

  const [accountData, setAccountData] = useState([]);

  const contentRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {

    fetchUserDetails(setAccountData);

    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '50px';
    
  }, []);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      navigate('/')
      console.log("Logged out successfully");
    }).catch((error) => {
      console.error("Error signing out", error);
    });
  };

  return (
    <div ref={contentRef} style={{ textAlign: 'left' }}>
    
      <TopBar title={'Your Account'} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    
        {accountData.map((user, index) => (
          
          <div style={{ width: '45%', margin: '10px' }}>
          
            <form key={index} className = 'info-form' >
              <h2 style={{textAlign: 'center', width: '100%'}}>User Information</h2>
              <label className='form-labels'>
                First Name
              </label>
              <input type="text" className="text-box" value={user.first_name}/>
              <label className='form-labels'>
                Last Name
              </label>
              <input type="text" className="text-box" value={user.last_name}/>
              <label className='form-labels'>
                Contact Email 
              </label>
              <input type="text" className="text-box" value={user.user_email}/>
              <label className='form-labels'>
                Contact Phone
              </label>
              <input type="text" className="text-box" value={user.user_phone}/>

              <br/>
              <br/>

              <button className="logout-button" onClick={() => handleSignOut()} > Log Out</button>
            </form>

          </div>
          
        ))}

      </div>
         
    </div>
  )
}
export default Account;
