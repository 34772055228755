import React, {useEffect, useState, useRef} from "react";
import "react-toastify/dist/ReactToastify.css";
import SupportTickets from "../features/support/SupportTickets";
import SupportTicketsFilter from "../features/support/SupportTicketsFilter";
import {fetchSupportTickets} from "../utils/support/supportUtils";
import {fetchUsername, fetchUserList, fetchUser} from '../utils/users/userUtils';
import SupportForm from '../features/support/SupportForm'
import TopBar from '../components/TopBar';

/**
 * `Support` is a component that provides a dashboard interface for managing and viewing support tickets.
 * It includes features for filtering and viewing tickets based on their status, and for creating new tickets.
 * The component fetches and displays ticket data, and allows interaction with the displayed data via a Modal.
 * 
 * @param {Object} props - The component props.
 * @param {Function} props.call - A callback function (not used in the current implementation).
 * 
 * @returns {JSX.Element} The rendered support dashboard, including ticket filtering, ticket viewing, and ticket creation functionalities.
 */
const Support = () => {
  const [selectedTab, setSelectedTab] = useState('Active');
  const [refresh, setRefresh] = useState(false);
  const [username, setUsername] = useState("");
  const [newTicket, setNewTicket] = useState(false);
  const [user, setUser] = useState("");
  const [userList, setUserList] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [tickets, setTickets] = useState([]);

  const contentRef = useRef();

  useEffect(() => {
    fetchUserList(setUserList);
    fetchUsername(setUsername);
    fetchSupportTickets(setTickets);
    fetchUser(setUser)

    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '49px';

  }, []);

  // Whenever tickets change, apply filters
  useEffect(() => {
    setFilteredTickets(tickets);
  }, [tickets]);

  // When a refresh is requested, retrieve tickets.
  useEffect(() => {
    fetchSupportTickets(setTickets);
    setRefresh(false);
  }, [refresh]);

  // Handles switching between open and resolved tabs.
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  // Handle new ticket button click.
  const handleApplyClick = () => {
    setNewTicket(true);
  }

  return (
    <div ref={contentRef} style={{textAlign: 'left', margin: '20px'}}>
      <TopBar title={'Support Dashboard'} />

      <SupportTicketsFilter supportTickets={tickets} onChange={setFilteredTickets} />
      <br />
      <br />
      <div>
        <span
          onClick={() => handleTabClick('Active')}
          style={{
            marginBottom: '15px',
            color: '#000000',
            borderBottom: selectedTab === 'Active' ? ' 2px solid black' : ' 2px solid transparent',
            fontSize: '30px', // Updated font size
            fontWeight: 'bold', // Added font weight
            cursor: 'pointer' // Set cursor to pointer
          }}
        >
          Active
        </span>

        <span
          onClick={() => handleTabClick('Resolved')}
          style={{
            marginLeft: '15px',
            color: '#000000',
            borderBottom: selectedTab === 'Resolved' ? ' 2px solid black' : ' 2px solid transparent',
            fontSize: '30px', // Updated font size
            fontWeight: 'bold', // Added font weight
            cursor: 'pointer' // Set cursor to pointer
          }}
        >
          Resolved
        </span>
      </div>
      <br />
      <br />
      {selectedTab === 'Active' && (
        <>
          <button className="support-button" style={{cursor: 'pointer'}} onClick={handleApplyClick}>New Ticket</button>
          <br />
          <br />
        </>
      )}
      <SupportTickets onChange={setRefresh} supportTickets={selectedTab === 'Active' ? filteredTickets.filter(ticket => ticket.status_cat !== 'Resolved') : filteredTickets.filter(ticket => ticket.status_cat === 'Resolved')} userList={userList} username={username} />

      {newTicket && (
        <SupportForm callBack={setNewTicket} />
      )}
    </div >
  );
};

export default Support;
