import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Data from '../../utils/mapping';
import { ServiceTable } from './ServiceTable';


const ServiceForm = ({ initialService, setSelectedService, selectedLocation, setIsAdding, serviceOptions }) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCat, setSelectedSubCat] = useState('');
    // const [internal];
    const [copyService, setCopyService] = useState({});
    // const copyService = !selectedService ? {} : selectedService;
    const uniqueServiceCategories = Array.from(new Set(serviceOptions.map(item => item.service_cat)));
    const uniqueServiceSubCategories = Array.from(new Set(serviceOptions.map(item => item.service_sub_cat))); //TODO make this all service sub cats
    const [newServiceOptions, setNewServiceOptions] = useState([]);

    useEffect(() => {
        Data.fetchServicesData({ selectedLocation, onlyNew: true, callback: setNewServiceOptions });
        setCopyService(initialService ? initialService : {})
    }, []);

    useEffect(() => {
        setSelectedCategory(copyService?.service_cat);
        setSelectedSubCat(copyService?.service_sub_cat);
    }, [copyService]);

    const handleCopyService = (service) => {
        setCopyService(service);
    };
    const handleSubmitService = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        const internal_service_id = await Data.fetchInternalServiceId(formData.get('serviceName'));
        const serviceData = {
            service_name: formData.get('serviceName'),
            location_name: selectedLocation,
            service_cat: formData.get('serviceCategory'),
            service_sub_cat: formData.get('serviceSubCategory'),
            service_price: parseFloat(formData.get('servicePrice')),
            duration: parseFloat(formData.get('duration')),
            provider_requirement: formData.get('providerRequirement'),
            room_type: formData.get('roomType'),
            internal_service_id: internal_service_id,
        };
        setSelectedService(serviceData);
        setIsAdding(false);
    };

    return (
        <>
            <div style={{
                border: '2px solid #87D3D7',
                borderRadius: '8px',
                width: '40%',
                padding: '20px',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <h2 style={{ textAlign: 'center' }}>Service</h2>
                <form onSubmit={handleSubmitService} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                }}>
                    <input type="hidden" id="locationName" name="locationName" value={selectedLocation} />

                    <label htmlFor="serviceName">Service Name:</label>
                    <input type="text" id="serviceName" name="serviceName" required defaultValue={!copyService.service_name ? '' : copyService.service_name} />
                    {/* readOnly={newService.service_name}  */}

                    <label htmlFor="serviceCategory">Service Category:</label>
                    <select id="serviceCategory" name="serviceCategory" onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory} required>
                        <option value="">Select Service Category...</option>
                        {uniqueServiceCategories.sort().map((category, index) => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                        {/* <option key={'new'} value={'new'}>{'New Category'}</option> */}

                    </select>

                    <label htmlFor="serviceSubCategory">Service Sub Category:</label>
                    <select id="serviceSubCategory" name="serviceSubCategory" onChange={(e) => setSelectedSubCat(e.target.value)} value={selectedSubCat} required>
                        <option value="">Select Service Sub Category...</option>
                        {uniqueServiceSubCategories.sort().map((subCategory, index) => (
                            <option key={index} value={subCategory}>{subCategory}</option>
                        ))}
                        {/* <option key={'new'} value={'new'}>{'New Subcategory'}</option> */}

                    </select>

                    <label htmlFor="servicePrice">Service Price:</label>
                    <input type="text" id="servicePrice" name="servicePrice" defaultValue={copyService.service_price} />

                    <label htmlFor="duration">Duration:</label>
                    <input type="text" id="duration" name="duration" defaultValue={copyService.duration} />

                    <label htmlFor="providerRequirement">Provider Requirement:</label>
                    <input type="text" id="providerRequirement" name="providerRequirement" defaultValue={copyService.provider_requirement} />

                    <label htmlFor="roomType">Room Type:</label>
                    <input type="text" id="roomType" name="roomType" defaultValue={copyService.room_type} />

                    <input type="submit" value="Add Service" className='support-button' />
                </form>
            </div>
            <br />
            <h3>Existing Services</h3>
            <ServiceTable services={newServiceOptions} onClick={handleCopyService} presetSearch={copyService.service_name} />
        </>

    );
};
export default ServiceForm;