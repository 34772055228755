import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { fetchOrdersPerLocation } from '../../utils/locations/locationsUtils';

import 'chart.js/auto';

const OrdersPerLocation = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchOrdersPerLocation(setData);
  }, []);

  const chartData = {
    labels: data.map(entry => entry.location_name),
    datasets: [{
      label: 'Total Orders',
      data: data.map(entry => entry.total_orders),
      backgroundColor: 'rgba(153, 102, 255, 0.2)',
      borderColor: 'rgba(153, 102, 255, 1)',
      borderWidth: 1,
    }]
  };

  return (
    <div>
      <h2>Orders Per Location</h2>
      <Bar data={chartData} options={{ responsive: true }} />
    </div>
  );
};

export default OrdersPerLocation;
