import React, { useEffect, useState, useRef } from 'react';
import tick from '../assets/images/green-tick.png';
import cross from '../assets/images/red-cross.png';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import TopBar from '../components/TopBar';

const Recommendation = ({locationName, call}) => {

    const contentRef = useRef(); 
    const [orders, setOrders] = useState([]); // New state for storing orders
    const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
    const [declineReason, setDeclineReason] = useState('');
    const [orderModalIsOpen, setOrderModalIsOpen] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [queryResult, setQueryResult] = useState([]);
    const [orderQuantity, setOrderQuantity] = useState(1);
    const [orderComment, setOrderComment] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(''); // State to manage selected category
    const [selectedItemLocation, setselectedItemLocation] = useState(''); 
    const [recommendedQuantity, setRecommendedQuantity] = useState('');
    const [isScriptRunning, setIsScriptRunning] = useState(false);
    const [sortDirectionInventoryQuantity, setSortDirectionInventoryQuantity] = useState(''); // 'asc' or 'desc'
    const [sortDirectionRelativeWeeks, setSortDirectionRelativeWeeks] = useState('asc'); // 'asc' or 'desc'
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [acceptCount, setAcceptCount] = useState(0);
    const [declineCount, setDeclineCount] = useState(0);
    const [locationsData, setLocationsData] = useState([]);



    useEffect(() => {

    // Fetch inventory data from the server
    fetch(`${call}/get-recommendations`)
    .then(response => response.json())
    .then(data => {
      const sortedData = data.sort((a, b) => b.total_score - a.total_score);
      setOrders(sortedData);
    })
    .catch(error => console.error('Error fetching inventory data:', error));

    fetchLocations();

    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '50px';

    }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    fetchData();
  }, [declineCount, acceptCount]); // This runs every time there's an accept or decline

  const fetchLocations = async () => {

    await fetch(`${call}/get-locations`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Store the username in the state variable
      setLocationsData(data);
    })
    .catch(error => console.error('Error fetching locations:', error));

  }

  const updateRecommendationsActivation = async (location_name, recommendations_active) => {
    
    // Toggle the recommendations_active value
    const opposite_recommendations_active = !recommendations_active ? 1 : 0;

    try {
      const response = await fetch(`${call}/update-recommendations-activation`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ location_name, recommendations_active: opposite_recommendations_active })
      });

      await fetchLocations();

      if (response.ok) {
        console.log('Recommendations activation updated successfully!');
        toast.success('Recommendations settings updated successfully!');
        // Optionally, you can redirect the user or perform any other action after successful submission
      } else {
        console.error('Failed to update recommendations activation.');
        toast.error('Failed to update recommendations settings.');

      }
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  const initialFormData = {
    order_code: null,
    location_name: 'Null',
    product_id: null,
   
    order_quantity: null,
    unit_price: null,
    vat_percent: null,
   
    status_cat: 'Recommend',
    status_rationale: null,
    
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };


  const fetchData = async() => {
    fetch(`${call}/get-recommendations`)
    .then(response => response.json())
    .then(data => {
      const sortedData = data.sort((a, b) => b.total_score - a.total_score);
      setOrders(sortedData);
    })
    .catch(error => console.error('Error fetching inventory data:', error));
  }

  
  const handleSubmit = async (e) => {
    
    e.preventDefault();

    try {
      const response = await fetch(`${call}/orderrec`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        console.log('Order recommendation submitted successfully!');
        // Optionally, you can redirect the user or perform any other action after successful submission
      } else {
        console.error('Failed to submit order recommendation');
      }
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  const handleRefresh = async () => {
    console.log('refresh clicked');
    setIsScriptRunning(true); // Set the script running state to true

    try {
        const response = await fetch(`${call}/recommendation_refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ run_recommendation: true })
        });
        if (response.ok) {
            console.log('Recommendation backup initiated successfully');
        } else {
            console.error('Failed to initiate recommendation backup');
        }
    } catch (error) {
        console.error('Error initiating recommendation backup:', error);
    } finally {
        setIsScriptRunning(false); // Set the script running state back to false
    }
};

  const handleOrder = (title, product_id, quantity, origin, recommendation_rationale, location_name) => {
    setOrderModalIsOpen(true);
    const old_quantity = quantity
    setSelectedProductId(product_id); 
    console.log(recommendation_rationale)
    setSelectedProduct({title, old_quantity, product_id, quantity, recommendation_rationale, location_name});
    
  }

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));


  const handleDecline = (product_id, recommended_quantity, location_name) => {
    setSelectedProductId(product_id); // Set the selected order ID
    setselectedItemLocation(location_name)
    setRecommendedQuantity(recommended_quantity)
    setDeclineModalIsOpen(true);
  }

  const submitDecline = async () => {
    
    setDeclineModalIsOpen(false);

      const response = fetch(`${call}/decline-sys-rec/${selectedProductId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: selectedItemLocation, recommended_quantity: recommendedQuantity, order_comment: declineReason }),
      });
      await delay(500);
     

      setDeclineCount(prev => prev + 1);
      setDeclineModalIsOpen(false);
      setDeclineReason('');
      fetchData();
    
  };
  
  const handleQuantityChange = (newQuantity) => {

    setOrderQuantity(newQuantity);

    setSelectedProduct(prevState => ({
        ...prevState,
        quantity: newQuantity
    }));
};

const submitOrder = async() => {

  setOrderModalIsOpen(false);
  
    
  console.log('Submitting order:', {
    product: selectedProduct.title,
    quantity: orderQuantity,
    actual: selectedProduct.quantity

    
  });

  setOrderQuantity(selectedProduct.quantity);



  const userEmail = localStorage.getItem('userEmail');
  let username = '';
  

  if (userEmail) {
    try {
        const response = await fetch(`${call}/get-username?user_email=${userEmail}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        username = data.username;
        console.log(username);
    } catch (error) {
        console.error('Error fetching username:', error);
        return; // Exit the function if there is an error
    }
}

if (username) {
  
      const response = fetch(`${call}/accept-sys-rec/${selectedProductId}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, location_name: selectedProduct.location_name, orderQuantity:selectedProduct.quantity,  old_quantity: selectedProduct.old_quantity, orderComment, username, recommendation_rationale: selectedProduct.recommendation_rationale }),
      });
      
      await delay(500);
    
      setAcceptCount(prev => prev + 1);
      
  
}

  // Close the order modal
  setOrderModalIsOpen(false);
  
  // Reset order details
  setSelectedProduct(null);

  setOrderComment('');
};

const uniqueManufacturers = Array.from(new Set(orders.map(item => item.manufacturer_name)));

const uniqueLocations = Array.from(new Set(orders.map(item => item.location_name)));

const filteredorders = orders.filter(item =>
  
  (selectedLocation === '' || item.location_name.toLowerCase() === selectedLocation.toLowerCase()) &&
  (selectedManufacturer === '' || item.manufacturer_name.toLowerCase() === selectedManufacturer.toLowerCase())

);
const sortedFilteredBundlesInventoryQuantity = filteredorders.slice().sort((a, b) => {
  const aValue = a.quantity_conversion !== 0 ? (a.inventory_quantity / a.quantity_conversion) : a.inventory_quantity;
  const bValue = b.quantity_conversion !== 0 ? (b.inventory_quantity / b.quantity_conversion) : b.inventory_quantity;
  
  // Check for NaN values
  if (isNaN(aValue) && isNaN(bValue)) return 0; // If both are NaN, no change in order
  if (isNaN(aValue)) return 1; // Push NaN values to the end
  if (isNaN(bValue)) return -1; // Push NaN values to the end

  if (sortDirectionInventoryQuantity === 'desc') {
      return bValue - aValue;
  } else {
      return aValue - bValue;
  }
});


const sortedFilteredBundlesRelativeWeeks = filteredorders.slice().sort((a, b) => {
  const aValue = a.inventory_quantity / a.product_usage;
  const bValue = b.inventory_quantity / b.product_usage;
  
  // Check for NaN values
  if (isNaN(aValue) && isNaN(bValue)) return 0; // If both are NaN, no change in order
  if (isNaN(aValue)) return 1; // Push NaN values to the end
  if (isNaN(bValue)) return -1; // Push NaN values to the end

  if (sortDirectionRelativeWeeks === 'desc') {
      return bValue - aValue;
  } else {
      return aValue - bValue;
  }
});

  

  return (
    <div ref={contentRef} >

      <TopBar title={'System Recommendations'} />

      <select
        value={selectedLocation}
        onChange={(e) => setSelectedLocation(e.target.value)}

        style={{ marginBottom: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}

      >
        <option value="">All Locations</option>
        {uniqueLocations.sort().map(location_name => (
          <option key={location_name} value={location_name}>
            {location_name}
          </option>
        ))}
      </select>

      <select
        value={selectedManufacturer}
        onChange={(e) => setSelectedManufacturer(e.target.value)}
        style={{  marginLeft: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
      >
        <option value="">All Manufacturers</option>
        {uniqueManufacturers.sort().map(manufacturer => (
          <option key={manufacturer} value={manufacturer}>
            {manufacturer}
          </option>
        ))}
      </select>

      <select
        value={sortDirectionInventoryQuantity}
        onChange={(e) => {setSortDirectionInventoryQuantity(e.target.value); setSortDirectionRelativeWeeks('');}}
        style={{marginLeft: '20px', padding: '10px', fontStyle: 'italic', width: '150px' }}
      >
        <option value="asc">Inventory Quantity: Ascending</option>
        <option value="desc">Inventory Quantity: Descending</option>
      </select>

      <select
        value={sortDirectionRelativeWeeks}
        onChange={(e) => {setSortDirectionRelativeWeeks(e.target.value); setSortDirectionInventoryQuantity('');}}
        style={{marginLeft: '20px', padding: '10px', fontStyle: 'italic', width: '150px' }}
      >
        <option value="asc">Relative Weeks: Ascending</option>
        <option value="desc">Relative Weeks: Descending</option>
      </select>

      <div style={{ 
        border: '2px solid #87D3D7', 
        display: 'flex', 
        justifyContent: 'left', 
        flexDirection: 'column', 
        width: '100%', 
        maxHeight: '310px', // Set a fixed height
        minHeight: '310px', // Set a fixed height
        overflowY: 'auto', // Enable vertical scrolling
        boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
      }}>
        <table style={{  
          backgroundColor: '#fffff', 
          width: '100%', 
          borderCollapse: 'separate', 
          borderSpacing: 0, 
          
          position: 'relative' // Ensure positioning context
        }}>
          <thead style={{
            position: 'sticky', // Stick the header
            top: 0, // Stick to the top of the table
            backgroundColor: '#ffffff', // Background to cover underlying rows
          
          }}>
            <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
            <th style={{ borderBottom:'2px solid black', padding: '5px', width: '5%',textAlign: 'left' }}>Location Name</th>
              <th style={{ borderBottom:'2px solid black', padding: '5px', width: '5%',textAlign: 'left' }}>Product ID</th>
              <th style={{ borderBottom:'2px solid black', padding: '5px', width: '10%',textAlign: 'left' }}>Product Name</th>
              <th style={{ borderBottom:'2px solid black', padding: '5px', width: '5%',textAlign: 'left' }}>Product Desc</th>
              <th style={{ borderBottom:'2px solid black', padding: '5px', width: '10%',textAlign: 'left' }}>Manufacturer Name</th>
              <th style={{ borderBottom:'2px solid black', padding: '5px', width: '10%',textAlign: 'left' }}>Inventory Quantity</th>
              <th style={{ borderBottom:'2px solid black', padding: '5px', width: '10%',textAlign: 'left' }}>Product Usage</th>
              <th style={{ borderBottom:'2px solid black', padding: '5px', width: '10%',textAlign: 'left' }}>Relative Weeks</th>
              <th style={{ borderBottom:'2px solid black', padding: '5px', width: '10%',textAlign: 'left' }}>Total Score</th>
              <th style={{ borderBottom:'2px solid black', padding: '5px', width: '10%',textAlign: 'left' }}>Recommended Quantity</th>
              <th style={{ borderBottom:'2px solid black', padding: '5px', width: '10%',textAlign: 'left' }}>Recommendation Rationale</th>
              <th style={{  borderBottom:'2px solid black', padding: '5px', width: '10%',textAlign: 'left' }}>Accept/Decline</th>
              
            </tr>

          </thead>

          <tbody>
         
                    {(sortDirectionInventoryQuantity === '' ? sortedFilteredBundlesRelativeWeeks : sortedFilteredBundlesInventoryQuantity).map((item) => (
                        <tr key={item.id} style={{ fontSize: '13px' }}>
                          <td className='table-row' style={{  padding: '5px',  }}>{item.location_name}</td>
                            <td className='table-row' style={{  padding: '5px',  }}>{item.product_id}</td>
                            <td className='table-row' style={{  padding: '5px',  }}>{item.product_name}</td>
                            <td className='table-row' style={{  padding: '5px',  }}>{item.product_description}</td>
                            <td className='table-row' style={{  padding: '5px',  }}>{item.manufacturer_name}</td>
                            <td className='table-row' style={{  padding: '5px', }}>{item.quantity_conversion !== 0
              ? parseFloat(item.inventory_quantity / item.quantity_conversion).toFixed(1)
              : item.inventory_quantity}</td>
                            <td className='table-row' style={{  padding: '5px', }}>{item.quantity_conversion !== 0
              ? parseFloat(item.product_usage / item.quantity_conversion).toFixed(1)
              : item.inventory_quantity}</td>
                            <td className='table-row' style={{  padding: '5px', }}>{parseFloat(item.inventory_quantity/item.product_usage).toFixed(2)}</td>
                            <td className='table-row' style={{  padding: '5px', }}>{parseFloat(item.total_score).toFixed(2)}</td>
                            <td className='table-row' style={{  padding: '5px', }}>{item.recommended_quantity}</td>
                            <td className='table-row' style={{  padding: '5px', }}>{item.recommendation_rationale}</td>
                                                                                                    
                            
                            <td className='table-row' style={{ padding: '5px', }}>
                                <button onClick={() => handleOrder(item.product_name, item.product_id, item.recommended_quantity, 'recommend', item.recommendation_rationale, item.location_name)} style={{ marginRight: '10px', backgroundColor: 'white', border: 'none', cursor: 'pointer' }}>
                                    <img src={tick} alt="green-tick" style={{ height: '20px', width: '20px' }} />
                                </button>
                                <button onClick={() => handleDecline(item.product_id, item.recommended_quantity, item.location_name)} style={{ marginRight: '10px', backgroundColor: 'white', border: 'none', cursor: 'pointer' }}>
                                    <img src={cross} alt="red-cross" style={{ height: '20px', width: '20px' }} />
                                </button>
                            </td>
                        </tr>
                    ))}
          </tbody>
        </table>
        
      </div>
      <button type='button' onClick={handleRefresh} style={{  marginTop: '20px', cursor: 'pointer' ,backgroundColor: '#fff', marginRight: '20px', marginBottom: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
      >Refresh Recommendations</button>
      {isScriptRunning && <p>Generating Recommendations...</p>}

      <h2 style={{ marginTop: '27px',  fontSize: '40px', fontWeight: 'bold', color: '#87D3D7',}}>Manual Recommendations</h2>

        <form onSubmit={handleSubmit}>
      <label>
        Order Code:
        <input type="text" name="order_code" value={formData.order_code} onChange={handleChange} />
      </label>
      <br />

      <label>
        Location Name:
        <input type="text" name="location_name" value={formData.location_name} onChange={handleChange} />
      </label>
      <br />

      <label>
        Product ID:
        <input type="text" name="product_id" value={formData.product_id} onChange={handleChange} />
      </label>
      <br />


      <label>
        Order Quantity:
        <input type="text" name="order_quantity" value={formData.order_quantity} onChange={handleChange} />
      </label>
      <br />

      <label>
        Unit Price:
        <input type="text" name="unit_price" value={formData.unit_price} onChange={handleChange} />
      </label>
      <br />

      <label>
        VAT Percent:
        <input type="text" name="vat_percent" value={formData.vat_percent} onChange={handleChange} />
      </label>
      <br />

      <label>
        Status Category:
        <input type="text" name="status_cat" value={formData.status_cat} onChange={handleChange} />
      </label>
      <br />

      <label>
        Status Rationale:
        <input type="text" name="status_rationale" value={formData.status_rationale} onChange={handleChange} />
      </label>
      <br />

      <button type="submit" style={{padding:'5px', marginTop:'20px'}}>Submit</button>
    </form>

    <h2 style={{ marginTop: '27px',  fontSize: '40px', fontWeight: 'bold', color: '#87D3D7',}}>Recommendations Activation</h2>

    <div style={{ 
      border: '2px solid #87D3D7', 
      display: 'flex', 
      justifyContent: 'left', 
      flexDirection: 'column', 
      width: '50%', 
      maxHeight: '350px', // Set a fixed height
      overflowY: 'auto', // Enable vertical scrolling
      boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
      marginTop: '20px',
    }}>
      <table style={{  
        width: '100%', 
        borderCollapse: 'separate', 
        borderSpacing: 0, 
        position: 'relative' // Ensure positioning context
      }}>
        <thead style={{
          position: 'sticky', // Stick the header
          top: 0, // Stick to the top of the table
          backgroundColor: '#ffffff', // Background to cover underlying rows
          zIndex: 1
        }}>
          <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
            <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Location</th>
            <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center' }}>Recommendations</th>
          </tr>

        </thead>

        <tbody>
          {locationsData && locationsData.map((location, index) => (
            <tr key={location.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
              <td className='table-row' style={{  padding: '5px', textAlign: 'center'  }}>{location.location_name}</td>
              <td className='table-row' style={{  padding: '5px', textAlign: 'center' }}>
              <label className="switch">
                <input type="checkbox" checked={location.recommendations_active ? location.recommendations_active : 0} onChange={async () => {await updateRecommendationsActivation(location.location_name, location.recommendations_active)}} />
                <span className="slider round" style={{zIndex: 0}}></span>
              </label>
              </td>
            </tr>
          ))}      
        </tbody>
      </table>
    </div>

    <br/>
    
    <Modal overlayClassName="modal-overlay"
      className="modal-content"
      isOpen={declineModalIsOpen}
      onRequestClose={() => setDeclineModalIsOpen(false)}
      style={{width: '30px',height:'20px'}}>
      <h2>Decline Order</h2>
      <label>
        Reason for Decline:
        <select value={declineReason} onChange={(e) => setDeclineReason(e.target.value)}>
          <option value="">Select a reason</option>
          <option value="Intention to Discontinue Product Use">Intention to Discontinue Product Use</option>
          <option value="Issue with Price">Issue with Price</option>
          <option value="Want a different Product/Manufacturer">Want a different Product/Manufacturer</option>
          <option value="Already Ordered the product externally">Already Ordered the product externally</option>
          <option value="Other">Other</option>
        </select>
      </label>
      <button style={{marginTop:'30px'}} onClick={() => submitDecline() }>Submit</button>
    </Modal>

    <Modal
      overlayClassName="modal-overlay"
      className="modal-content"
      isOpen={orderModalIsOpen}
      onRequestClose={() => setOrderModalIsOpen(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          width: '200px',
          height: '400px',
          margin: 'auto',
          padding: '20px',
        },
      }}
    >
      <h2>Place Order</h2>
      {selectedProduct && (
        <>
          <p>Product: {selectedProduct.title}</p>
          <label>
            Quantity:
            <input
              type="text"
              value={selectedProduct.quantity}
              onChange={(e) => {
                const newValue = e.target.value === '' ? '' : parseInt(e.target.value, 10);
                handleQuantityChange(newValue);
            }}
              
            />
          </label>
          <label>
            Comment:
            <textarea
              value={orderComment}
              onChange={(e) => setOrderComment(e.target.value)}
            />
          </label>
      
          <button onClick={submitOrder}>Submit Order</button>
        </>
      )}
    </Modal>

    <ToastContainer />

    </div>
  );
};

export default Recommendation;
