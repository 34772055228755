import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import {fetchRecentSupportUpdates} from '../../utils/support/supportUtils';
import {formatDateTimeText} from '../../utils/time_zones/TimeZones';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

/**
 * `SupportTrackingTableModal` is a React functional component that displays a modal showing recent support updates
 * for a specific support ticket. The modal is used to provide detailed history of actions related to the ticket.
 * 
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - A boolean indicating whether the modal is currently open.
 * @param {Function} props.onClose - A function to handle closing the modal.
 * @param {Object} props.ticket - The support ticket object. The type of ticket determines the history fetched
 * (e.g., ticket history, user history, or location history).
 * 
 * @returns {JSX.Element} The rendered modal component with a table displaying the recent support updates.
 */
const SupportTrackingTableModal = ({isOpen, onClose, ticket}) => {
    const [recentSupportUpdates, setRecentSupportUpdates] = useState([]);

    // Get history depending on tab.
    useEffect(() => {
        if ('ticket_id' in ticket) {
            fetchRecentSupportUpdates(setRecentSupportUpdates, ticket, 'Ticket History');
        }
        else if ('username' in ticket) {
            fetchRecentSupportUpdates(setRecentSupportUpdates, ticket, 'User History');
        }
        else if ('location_name' in ticket) {
            fetchRecentSupportUpdates(setRecentSupportUpdates, ticket, 'Location History');
        }
    }, [ticket]);

    return (
        <Modal
            overlayClassName="modal-overlay"
            className="modal-content"
            isOpen={isOpen}

            onRequestClose={() => {onClose();}}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2
                },
                content: {
                    backgroundColor: 'white',
                    width: '60%',
                    height: '70%',
                }
            }}
        >
            <button
                style={{
                    position: 'absolute',
                    top: '15px',
                    right: '15px'
                }}
                className="support-button"
                onClick={() => {onClose();}}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <br />
            <br />
            <h2 style={{textAlign: 'center'}}>Support Ticket History</h2>
            <div style={{
                border: '2px solid #87D3D7',
                display: 'flex',
                justifyContent: 'left',
                flexDirection: 'column',
                width: '100%',
                maxHeight: '410px',
                overflowY: 'auto',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <table id="order-updates-table" style={{
                    backgroundColor: '#ffffff',
                    width: '100%',
                    borderCollapse: 'separate',
                    borderSpacing: 0,
                    position: 'relative'
                }}>
                    <thead style={{position: 'sticky', top: 0}}>
                        <tr style={{fontSize: '13px', backgroundColor: '#ffffff', color: '#000000'}}>
                            <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left', backgroundColor: '#ffffff', width: '5%'}}>Ticket ID</th>
                            <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left', backgroundColor: '#ffffff', width: '40%'}}>Recent Actions</th>
                            <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left', backgroundColor: '#ffffff', width: '10%'}}>Change by</th>
                            <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'left', backgroundColor: '#ffffff', width: '10%'}}>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(recentSupportUpdates && recentSupportUpdates.length > 0) ?
                            (
                                <>
                                    {recentSupportUpdates.map((item, index) => (
                                        <tr key={index} style={{fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff'}}>
                                            <td style={{padding: '10px'}}>{item.ticket_id}</td>
                                            <td style={{padding: '10px'}}>{item.action}</td>
                                            <td style={{padding: '10px'}}>{item.username}</td>
                                            <td style={{padding: '10px'}}>{formatDateTimeText(item.time)}</td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="8" style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '16px', fontStyle: 'italic', padding: "1px", padding: '50px', height: '160px'}}>
                                        No tracking data.
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
        </Modal>
    );
};

export default SupportTrackingTableModal;
