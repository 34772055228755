import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImage, faFilePdf, faTimes} from '@fortawesome/free-solid-svg-icons';
import AttachmentModal from './AttachmentModal';

/**
 * AttachmentPreview component displays a preview of an attachment.
 * The attachment can be an image or a PDF, and includes options to view the full version or dismiss it.
 * 
 * @param {Object} props - Component properties.
 * @param {string} props.name - Name of the attachment.
 * @param {string} props.type - MIME type of the attachment (e.g., 'image/jpeg', 'application/pdf').
 * @param {Function} props.loadAsset - Function to load the attachment blob.
 * @param {Function} [props.dismissable] - Optional function to dismiss the attachment preview.
 */
export const AttachmentPreview = ({name, type, loadAsset, dismissable}) => {
    // State to store the URL for the attachment preview
    const [attachmentSrc, setAttachmentSrc] = useState(null);

    // State to handle hover effects
    const [isHovered, setIsHovered] = useState(false);

    // State to store the binary large object (BLOB) of the attachment
    const [blob, setBlob] = useState(null);

    // State to control the visibility of the full attachment modal
    const [showFull, setShowFull] = useState(false);

    // Effect to clean up the object URL when the component is unmounted or attachmentSrc changes
    useEffect(() => {
        return () => {
            if (attachmentSrc) {
                URL.revokeObjectURL(attachmentSrc); // Revoke the object URL to free memory
            }
        };
    }, [attachmentSrc]);

    // Effect to create an object URL when a new blob is loaded
    useEffect(() => {
        if (blob) {
            const url = window.URL.createObjectURL(blob); // Create a URL for the blob
            setAttachmentSrc(url); // Set the URL for the attachment preview
        }
    }, [blob]);

    // Function to handle click event and show the full attachment in a modal
    const handleClick = () => {
        setShowFull(true);
    };

    // Function to load the attachment by calling the passed loadAsset function
    const handleLoadAttachment = async () => {
        await loadAsset(setBlob);
    };

    return (
        <div
            style={{
                marginRight: '40px',
                marginBottom: '10px',
                position: 'relative',
            }}
        >
            {attachmentSrc ? (
                <div
                    style={{
                        width: '100px',
                        height: '100px',
                        overflow: 'visible',
                    }}
                >
                    {type === 'image/jpeg' && (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            {/* Display the image attachment with hover effects */}
                            <img
                                src={attachmentSrc}
                                alt="Fetched Blob"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    transition: 'transform 0.3s ease-in-out',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    transformOrigin: 'bottom left',
                                    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
                                    zIndex: isHovered ? 1 : 'auto',
                                }}
                            />

                            {/* Overlay with a "View" button and optional dismiss button */}
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    zIndex: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                                    color: isHovered ? 'white' : 'transparent',
                                    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
                                    transformOrigin: 'bottom left',
                                    transition: 'transform 0.3s ease-in-out',
                                    cursor: 'pointer',
                                    fontSize: '1.2rem',
                                }}
                            >
                                <div
                                    onClick={handleClick}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    View
                                </div>
                                {dismissable && (
                                    <button
                                        className="support-button"
                                        onClick={dismissable}
                                        style={{
                                            top: '0%',
                                            fontSize: 'small',
                                            left: '0%',
                                            position: 'absolute',
                                            zIndex: 5,
                                            padding: '2px',
                                            borderRadius: '0px',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                    {type === 'application/pdf' && (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            {/* Display the PDF attachment inside an iframe with hover effects */}
                            <div style={{height: '100%'}}>
                                <iframe
                                    src={attachmentSrc}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: 'none',
                                        transition: 'transform 0.3s ease-in-out',
                                        cursor: 'pointer',
                                        transform: isHovered ? 'scale(1.1)' : 'scale(1)',
                                        transformOrigin: 'bottom left',
                                    }}
                                    title="Clickable iframe"
                                />
                            </div>

                            {/* Overlay with a "View" button and optional dismiss button */}
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                                    color: isHovered ? 'white' : 'transparent',
                                    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
                                    transformOrigin: 'bottom left',
                                    transition: 'transform 0.3s ease-in-out',
                                    cursor: 'pointer',
                                    fontSize: '1.2rem',
                                }}
                            >
                                <div
                                    onClick={handleClick}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    View
                                </div>
                                {dismissable && (
                                    <button
                                        className="support-button"
                                        onClick={dismissable}
                                        style={{
                                            top: '0%',
                                            fontSize: 'small',
                                            left: '0%',
                                            position: 'absolute',
                                            zIndex: 5,
                                            padding: '2px',
                                            borderRadius: '0px',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                    // Button to load the attachment if it hasn't been loaded yet
                    <button
                        className='support-button-accent'
                        onClick={() => {
                            handleLoadAttachment();
                        }}
                        style={{
                            padding: '5px 10px',
                            fontSize: 'small',
                        }}
                >
                    <FontAwesomeIcon icon={type === 'application/pdf' ? faFilePdf : faImage} /> {name}
                </button>
            )}
            {showFull && (
                // Modal to display the full attachment when requested
                <AttachmentModal blob={blob} name={name} type={type} isOpen={showFull} setIsOpen={setShowFull} />
            )}
        </div>
    );
};
