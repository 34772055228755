import React, { useEffect, useState } from 'react';
import ProductForm from './ProductForm';
import ProductTable from './ProductTable';
import * as Data from '../../utils/mapping';
import ProductMapping from './ProductMapping';

const ProductSelection = ({ newProduct, backPressed, productOptions, selectedLocation }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isAdding, setIsAdding] = useState(newProduct);
    const [copyProduct, setCopyProduct] = useState({});
    const [newPrice, setNewPrice] = useState({});
    const [newInventory, setNewInventory] = useState({});

    useEffect(() => {
        // Data.fetchProducts({ selectedLocation, onlyNew: true, callback: setNewServiceOptions });
    }, []);

    const handleAddProduct = async (product) => {
        setSelectedProduct(product);
    };

    const handleCopyService = (service) => {
        setCopyService(service);
    };

    const mappingOnBack = () => {
        if (newProduct) {
            setIsAdding(true);
        }
        else {
            setSelectedProduct(null);
        }
    };

    const addProduct = async () => {
        if (newProduct) {
            const productId = await Data.addNewProduct(selectedProduct, newPrice, newInventory);
            if (productId) return productId;
            return false;
        }
        else return selectedProduct.product_id;
    }

    return (
        //TODO: submit new product if necessary and pricing. also fof
        <div>
            {/* New service to be added and the process is not done. */}
            <span style={{ display: newProduct && isAdding ? '' : 'none' }}>
                <ProductForm
                    selectedLocation={selectedLocation}
                    copyProduct={copyProduct}
                    setNewInventory={setNewInventory}
                    setNewPrice={setNewPrice}
                    setSelectedProduct={setSelectedProduct}
                    onFinish={() => setIsAdding(false)}
                    productOptions={productOptions}
                    onBack={backPressed}
                    isExistingProduct={false} />
            </span>

            {/* Use existing service. Selection is not finished yet. */}
            <span style={{ display: !newProduct && !selectedProduct ? '' : 'none' }}>
                <ProductTable products={productOptions} onClick={handleAddProduct} onBack={backPressed} />
            </span>

            {/* A service has been selected for mapping. */}
            {selectedProduct && !isAdding && (
                <ProductMapping
                    selectedProduct={selectedProduct}
                    selectedLocation={selectedLocation}
                    onBack={mappingOnBack} onSubmit={() => newProduct ? backPressed() : setSelectedProduct(null)}
                    beforeOnSubmit={addProduct}
                />
            )}
        </div>
    );

};

export default ProductSelection;