import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/medvelle_blue.png';

const NavBar = () => {
  const location = useLocation(); // Hook to get the current location

  return (
    <div className="nav-bar">
      <Link to="/" className="logo-link">
        <img src={logo} alt="Logo" className="logo-img" />
      </Link>

      <div className="nav-links">
        <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>
          Home
        </Link>
        <Link to="/operations" className={`nav-link ${location.pathname === '/operations' ? 'active' : ''}`}>
          Operations
        </Link>
        <Link to="/automation" className={`nav-link ${location.pathname === '/automation' ? 'active' : ''}`}>
          Automation
        </Link>
        <Link to="/support" className={`nav-link ${location.pathname === '/support' ? 'active' : ''}`}>
          Support
        </Link>
        <Link to="/invoices" className={`nav-link ${location.pathname === '/invoices' ? 'active' : ''}`}>
          Invoices
        </Link>
        <Link to="/mapping" className={`nav-link ${location.pathname === '/mapping' ? 'active' : ''}`}>
          Mapping
        </Link>
        <Link to="/payments" className={`nav-link ${location.pathname === '/payments' ? 'active' : ''}`}>
          Payments
        </Link>
        <Link to="/recommendations" className={`nav-link ${location.pathname === '/recommendations' ? 'active' : ''}`}>
          Recommendations
        </Link>
        <Link to="/suppliers" className={`nav-link ${location.pathname === '/suppliers' ? 'active' : ''}`}>
          Suppliers
        </Link>
        <Link to="/system" className={`nav-link ${location.pathname === '/system' ? 'active' : ''}`}>
          System
        </Link>
        <Link to="/account" className={`nav-link ${location.pathname === '/account' ? 'active' : ''}`}>
          Account
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
