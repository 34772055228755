/**
 * Function to fetch the username.
 * @param {Function} setUser - A callback function to set the user name in the state.
 * @param {string} call - The base URL for the API.
 */
export const fetchUser = (setUser) => {
    const userEmail = localStorage.getItem('userEmail');

    if (userEmail) {
        fetch(process.env.REACT_APP_BACKEND_URL + `/get-name?user_email=${userEmail}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setUser(data);
            })
            .catch(error => console.error('Error fetching username:', error));
    }
};

/**
 * Function to fetch the username.
 * @param {Function} username - The username for the user you want to retrieve.
 * @param {Function} setUser - A callback function to set the user name in the state.
 * @param {string} call - The base URL for the API.
 */
export const fetchUserInfo = async (username, location_name, setUserInfo) => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/user-info', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: username,
            location_name: location_name
        }),
    });

    // Check if the response is okay
    if (!response.ok) {
        const errorMessage = `Error ${ response.status }: ${ response.statusText }`;
        console.error('API call failed:', errorMessage);
        return errorMessage;
    }

    // Parse the response data
    const data = await response.json();
    setUserInfo(data);
    return data;
};

/**
 * Function to fetch the username based on the user's email stored in local storage.
 * @param {Function} setUsername - A callback function to set the username in the state.
 */
export const fetchUsername = async (setUsername) => {
    try {
        // Get the user's email from local storage
        const userEmail = localStorage.getItem('userEmail');

        if (userEmail) {
            // Fetch the username from the API
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/get-username?user_email=${userEmail}`);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Parse the response JSON
            const data = await response.json();

            // Set the username in the state
            setUsername(data.username);
        }
    } catch (error) {
        // Handle errors
        console.error('Error fetching username:', error);
    }
};

/**
 * Fetches the list of internal users from the backend API and updates the user list state.
 *
 * @param {Function} setUserList - Function to update the state of the user list in the component.
 */
export const fetchUserList = async (setUserList) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-list`);
        const data = await response.json();
        setUserList(data);
    } catch (error) {
        console.error('Error fetching user list:', error);
    }
  };

/**
* Fetches the list of users from the backend API and updates the user list state.
*
* @param {Function} setUserList - Function to update the state of the user list in the component.
*/
export const fetchUserListAll = async (setUserList) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/user-list-all`);
        const data = await response.json();
        setUserList(data);
    } catch (error) {
        console.error('Error fetching user list:', error);
    }
};

/**
 * Function to fetch the username.
 * @param {Function} setUser - A callback function to set the user name in the state.
 * @param {string} call - The base URL for the API.
 */
export const fetchUserDetails = (setUser) => {
    const userEmail = localStorage.getItem('userEmail');

    if (userEmail) {
        fetch(process.env.REACT_APP_BACKEND_URL + `/get-user-details?user_email=${userEmail}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setUser(data);
            })
            .catch(error => console.error('Error fetching user details:', error));
    }
};

/**
 * Fetches the permission code for a specific location and user.
 * 
 * @param {string} locationName - The name of the location for which to fetch the permission code.
 * @param {string} username - The username of the user for whom to fetch the permission code.
 * @returns {Promise<string>} - A promise that resolves to the permission code.
 */
export const fetchPermissionCodeForDefaultLocation = async (locationName, username, setPermissionCode) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/permission-code/${username}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ locationName }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch permission code');
      }
  
      const result = await response.json();
      setPermissionCode(result.permission_code);
      
    } catch (error) {
      console.error('Error fetching permission code:', error);
      throw error;
    }
  };
  