import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Data from '../../utils/mapping';
import BackArrow from '../../components/BackArrow';
import { toast, ToastContainer } from 'react-toastify';


const ProductForm = ({ copyProduct, setSelectedProduct, setNewPrice, setNewInventory, selectedLocation, onFinish, productOptions, onBack, isExistingProduct, isWebProduct }) => {
    const [productDone, setProductDone] = useState(isExistingProduct);
    const [priceDone, setPriceDone] = useState(isExistingProduct);
    const [newProduct, setNewProduct] = useState(copyProduct);
    const [suppliersData, setSuppliersData] = useState([]);
    const [priceData, setPriceData] = useState({})
    const uniqueManufacturers = Array.from(new Set(productOptions.map(item => item.manufacturer_name)));
    const uniqueProductCategories = Array.from(
        new Set(
            productOptions
                .filter(item => item.product_cat !== 'web') // Exclude 'web' category
                .map(item => item.product_cat)
        )
    );
    const uniqueSupplierCategories = Array.from(
        new Set(
            suppliersData.map(item => ({
                supplier_name: item.supplier_name,
                supplier_id: item.supplier_id
            }))
        )
    ).sort((a, b) => a.supplier_name.localeCompare(b.supplier_name));
    const [formData, setFormData] = useState({
        productName: copyProduct.product_name || '',
        productDescription: copyProduct.product_description || '',
        manufacturerName: copyProduct.manufacturer_name || '',
        productCategory: copyProduct.product_cat || '',
        quantityConversion: copyProduct.quantity_conversion || '',
        productUnit: copyProduct.product_unit || '',
        productTier: copyProduct.product_tier || ''
    });

    const [priceFormData, setPriceFormData] = useState({})

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        Data.fetchSuppliersData(setSuppliersData);
        checkPrice();
    }, []);

    const checkPrice = async () => {
        const price = await Data.getPrice(copyProduct.product_id);
        setPriceFormData(price);
    }


    const handleSubmitProduct = async (event) => {
        event.preventDefault();

        // if (selectedWebProduct.length === 0) {
        //     setSelectedProductId(lastProductId + selectedProduct.length + 1);
        // }
        const lastProductId = await Data.fetchLastProductId(() => { });

        const formData = new FormData(event.target);
        if (formData.get('productCategory') === 'web') {
            toast.error('Cannot submit web for product category.');
            return;
        }
        const productData = {
            product_name: formData.get('productName'),
            product_description: formData.get('productDescription'),
            manufacturer_name: formData.get('manufacturerName'),
            product_cat: formData.get('productCategory'),
            quantity_conversion: parseFloat(formData.get('quantityConversion')),
            product_unit: formData.get('productUnit'),
            product_tier: parseInt(formData.get('productTier'), 10)
        };
        if (isWebProduct) productData.product_id = copyProduct.product_id; // upsert web products

        setSelectedProduct(productData);
        setNewProduct(productData);
        setProductDone(true);
    };

    const handleSubmitPrice = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const priceData = {
            product_id: parseInt(formData.get('productId'), 10),
            supplier_id: parseInt(formData.get('supplierId'), 10),
            client_price: parseFloat(formData.get('clientPrice')),
            delivery_days: parseInt(formData.get('deliveryDays'), 10),
            price_quantity: parseInt(formData.get('priceQuantity'), 10)
        };

        setNewPrice(priceData);
        setPriceDone(true);
    };

    const handleSubmitInventory = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const inventoryData = {
            location_name: selectedLocation,
            product_id: parseInt(formData.get('productId')),
            inventory_quantity: parseFloat(formData.get('inventoryQuantity')),
            class: parseFloat(formData.get('class')),
            spotcheck_status: parseInt(formData.get('spotcheckStatus')),
            discontinue: parseInt(formData.get('discontinue'), 10), // Assuming discontinue is an integer
            // Rest of the fields can be left empty or you can add them here
        };
        setNewInventory(inventoryData);
        onFinish();
    };

    const handleBackClick = () => {
        if (!productDone) onBack();
        else if (productDone && priceDone) {
            if (isExistingProduct) onBack();
            else setPriceDone(false)
        }
        else if (productDone && !priceDone) setProductDone(false);
    };


    return (
        <>
            <BackArrow onClick={handleBackClick} />
            <br />
            <br />
            < div style={{
                border: '2px solid #87D3D7',
                borderRadius: '8px',
                width: '40%',
                padding: '20px',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                display: !productDone ? '' : 'none'
            }
            }>
                {/* Product Form */}
                <h2 style={{ textAlign: 'center' }}>Product</h2>
                <form onSubmit={handleSubmitProduct} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                }}>

                    <label htmlFor="productName">Product Name:</label>
                    <input type="hidden" id="locationName" name="locationName" value={selectedLocation} />

                    <input type="text" id="productName" name="productName" defaultValue={copyProduct.product_name} value={formData.productName} onChange={handleChange} required />
                    <br />
                    <label htmlFor="productDescription">Product Description:</label>
                    <input type="text" id="productDescription" name="productDescription" defaultValue={copyProduct.product_description} value={formData.productDescription} onChange={handleChange} required />
                    <br />
                    <label htmlFor="manufacturerName">Manufacturer Name:</label>
                    <select id="manufacturerName" name="manufacturerName" defaultValue={copyProduct.manufacturer_name} onChange={handleChange} required>
                        <option value={formData.manufacturerName}>{formData.manufacturerName === '' ? (<>Select Manufacturer...</>) : (<>{formData.manufacturerName}</>)}</option>
                        {uniqueManufacturers.sort().map((manufacturer, index) => (
                            <option key={manufacturer} value={manufacturer}>{manufacturer}</option>
                        ))}
                    </select>
                    <br />
                    <label htmlFor="productCategory">Product Category:</label>
                    <select id="productCategory" name="productCategory" defaultValue={copyProduct.product_cat} onChange={handleChange} required>
                        <option value={formData.productCategory}>{formData.productCategory === '' ? (<>Select Product Category...</>) : (<>{formData.productCategory}</>)}</option>
                        {uniqueProductCategories.sort().map((productCat, index) => (
                            <option key={productCat} value={productCat}>{productCat}</option>
                        ))}
                    </select>
                    <br />
                    <label htmlFor="quantityConversion">Quantity Conversion:</label>
                    <input type="number" step="any" id="quantityConversion" name="quantityConversion" defaultValue={copyProduct.quantity_conversion} value={formData.quantityConversion} onWheel={(e) => e.target.blur()}
                        onChange={handleChange} required />
                    <br />
                    <label htmlFor="productUnit">Product Unit:</label>
                    <input type="text" id="productUnit" name="productUnit" defaultValue={copyProduct.product_unit} onWheel={(e) => e.target.blur()}
                        value={formData.productUnit} onChange={handleChange} required />
                    <br />
                    <label htmlFor="productTier">Product Tier:</label>
                    <input type="number" id="productTier" name="productTier" defaultValue={copyProduct.product_tier} value={formData.productTier} onWheel={(e) => e.target.blur()}
                        onChange={handleChange} required />
                    <br />
                    {/* Input for product picture can be added here in the future */}
                    {/* Input for subcategory can be added here in the future */}
                    <input type="submit" className='support-button'
                        value="Add Product"
                    />
                </form>
            </div >

            {/* Price Form */}
            <div style={{
                border: '2px solid #87D3D7',
                borderRadius: '8px',
                width: '40%',
                padding: '20px',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                display: productDone && !priceDone ? '' : 'none'
            }}>
                <h2 style={{ textAlign: 'center' }}>Price</h2>
                <form onSubmit={handleSubmitPrice} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                }}>
                    <input type="hidden" id="productId" name="productId" value={newProduct.product_id} />
                    <input type="hidden" id="priceQuantity" name="priceQuantity" value={priceFormData?.price_quantity ? priceFormData?.price_quantity : 1} />

                    <label htmlFor="supplierId">Supplier ID:</label>
                    <select id="supplierId" name="supplierId" value={priceFormData?.supplier_id} required>
                        <option value={''}>Select Supplier...</option>
                        {uniqueSupplierCategories.sort().map((supplier, index) => (
                            <option key={supplier.supplier_id} value={supplier.supplier_id}>{supplier.supplier_name}</option>
                        ))}
                    </select>
                    <br />
                    <label htmlFor="clientPrice">Client Price:</label>
                    <input type="number" step="0.01" id="clientPrice" name="clientPrice" value={priceFormData?.client_price} onWheel={(e) => e.target.blur()}
                        required />
                    <br />
                    <label htmlFor="deliveryDays">Delivery Days:</label>
                    <input type="number" id="deliveryDays" name="deliveryDays" value={priceFormData?.delivery_days} onWheel={(e) => e.target.blur()}
                        required />
                    <br />
                    <input type="submit" value="Add Price" className='support-button' />
                </form>
            </div>

            {/* Inventory form */}
            <div style={{
                border: '2px solid #87D3D7',
                borderRadius: '8px',
                width: '40%',
                padding: '20px',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                display: productDone && priceDone ? '' : 'none'
            }}>
                <h2 style={{ textAlign: 'center' }}>Inventory</h2>
                <form onSubmit={handleSubmitInventory} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                }}>
                    <input type="hidden" id="locationName" name="locationName" value={selectedLocation} />
                    <input type="hidden" id="productId" name="productId" value={newProduct.product_id} />
                    <label htmlFor="inventoryQuantity">Inventory Quantity:</label>
                    <input type="number" step="any" id="inventoryQuantity" name="inventoryQuantity" value={formData.inventoryQuantity} onWheel={(e) => e.target.blur()}
                        required />
                    <br />
                    <label htmlFor="class">Class:</label>
                    <input type="number" step="any" id="class" name="class" value={formData.class} onWheel={(e) => e.target.blur()}
                        required />
                    <br />
                    <label htmlFor="spotcheckStatus">Spotcheck Status:</label>
                    <input type="number" step="any" id="spotcheckStatus" name="spotcheckStatus" value={formData.spotcheckStatus} onWheel={(e) => e.target.blur()}
                        required />
                    <br />
                    {/* Spotcheck date automatically set to today's date */}
                    <input type="hidden" id="discontinue" name="discontinue" value="0" />
                    {/* Rest of the fields can be left empty */}
                    <input type="submit" value="Add to Inventory" className='support-button' />
                </form>
            </div>
        </>

    );
};
export default ProductForm;