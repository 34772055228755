import React, { useEffect, useCallback, useState, useRef } from 'react';
import ServiceForm from './ServiceForm';
import { ServiceTable } from './ServiceTable';
import ProductTable from './ProductTable';
import ProductForm from './ProductForm';
import * as Data from '../../utils/mapping';
import ServiceMapping from './ServiceMapping';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import BackArrow from '../../components/BackArrow';
import ProductMapping from './ProductMapping';
import { toast } from 'react-toastify';


const EditWebProduct = ({ selectedLocation, currentInventory, refreshKey }) => {
    const [webProductsData, setWebProductsData] = useState([]);
    // const [globalInventory, setGlobalInventory] = useState([])
    const [selectedWebProduct, setSelectedWebProduct] = useState({});
    const [isExisting, setIsExisting] = useState(false);
    const [commonProducts, setCommonProducts] = useState([]);
    const [newPrice, setNewPrice] = useState({});
    const [newInventory, setNewInventory] = useState({});
    const [newProduct, setNewProduct] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productSelected, setProductSelected] = useState(false);
    const [isInInventory, setIsInInventory] = useState(false);
    const [isMapping, setIsMapping] = useState(false);
    const [isReplaceReady, setIsReplaceReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        Data.fetchWebProducts(setWebProductsData);
        Data.getCommonProducts(setCommonProducts, selectedLocation);
        mappingOnBack();
        // Data.fetchProducts(setGlobalInventory);
    }, [selectedLocation]);

    useEffect(() => {
        mappingOnBack();
    }, [refreshKey])

    const mappingOnBack = () => {
        setIsMapping(false);
        setNewProduct(false);
        setIsInInventory(false);
        setIsReplaceReady(false);
        setProductSelected(false);
    }

    const handleAddWebProduct = async (product) => {
        setSelectedWebProduct(product);
        setProductSelected(true);
    };

    return (
        <>
            <div style={{ display: productSelected ? 'none' : '' }}>
                <ProductTable products={webProductsData.filter(
                    item => (selectedLocation === '' || item.location_name.toLowerCase() === selectedLocation.toLowerCase())
                )} onClick={handleAddWebProduct} onlySearch={true} />
            </div>
            {productSelected && (
                <div style={{ display: newProduct ? 'none' : '' }}>
                    <h2><BackArrow onClick={() => setProductSelected(false)} /> {selectedWebProduct.product_name}</h2>
                    <button
                        className='support-button'
                        onClick={async () => {
                            setNewProduct(true);
                            setSelectedProduct(selectedWebProduct);
                            setIsExisting(false);
                        }}
                        style={{ fontSize: 'small' }}
                    >
                        New Product Form
                    </button>
                    <h3>Current Products</h3>
                    <br />
                    <ProductTable
                        products={commonProducts}
                        onClick={(product) => {
                            setSelectedProduct(product);
                            setIsInInventory(currentInventory.some(item => item.product_id === product.product_id));
                            setNewProduct(true);
                            setIsExisting(true);
                        }}
                        buttonTitle={'Replace'}
                        search={selectedWebProduct?.product_name} />
                </div>
            )}
            {newProduct && !isInInventory && !isMapping && !isReplaceReady && (
                <ProductForm
                    copyProduct={selectedProduct}
                    selectedLocation={selectedLocation}
                    setNewInventory={setNewInventory}
                    setNewPrice={setNewPrice}
                    setSelectedProduct={setSelectedProduct}
                    onFinish={() => { setIsReplaceReady(true); }}
                    productOptions={currentInventory}
                    onBack={() => setNewProduct(false)}
                    isExistingProduct={isExisting}
                    isWebProduct={true}
                />
            )}
            {((isReplaceReady || isInInventory) && !isMapping) && (
                isLoading ? (
                    <span>Replacing...</span>
                ) :
                    <>
                        <BackArrow onClick={() => {
                            setIsReplaceReady(false);
                            setNewProduct(false);
                            setIsExisting(false);
                            setIsInInventory(false);
                        }
                        } /> 
                        <button
                            className='support-button'
                            onClick={async () => {
                                setIsLoading(true);
                                // Product is not in the location's inventory
                                if (!isInInventory) {
                                    // Product does not exist at other locations. No replacement needed.
                                    if (!isExisting) {
                                        const addSuccess = await Data.addNewProduct(selectedProduct, newPrice, newInventory);
                                        if (!addSuccess) {
                                            toast.error('Failed to add to inventory.');
                                            setIsLoading(false);
                                            return;
                                        }
                                    }
                                    // Product exists and needs added to inventory before replacing and deleting
                                    else {
                                        const addSuccess = await Data.addNewInventory(newInventory);
                                        if (!addSuccess) {
                                            toast.error('Failed to add to inventory.');
                                            setIsLoading(false);
                                            return;
                                        }

                                        const replaceSuccess = await Data.replaceProductOrders(selectedWebProduct.product_id, selectedProduct.product_id);
                                        if (!replaceSuccess) {
                                            toast.error('Failed to replace the product');
                                            setIsLoading(false);
                                            return;
                                        }

                                        const deleteSuccess = await Data.deleteProduct(selectedWebProduct.product_id);
                                        if (!deleteSuccess) {
                                            toast.error('Failed to delete the original product');
                                            setIsLoading(false);
                                            return;
                                        }
                                        await Data.fetchWebProducts(setWebProductsData);
                                    }

                                }
                                    // Replacing with a product in inventory. Must replace then delete web product.
                                else {
                                    // if new inventory add inventory item
                                    const replaceSuccess = await Data.replaceProductOrders(selectedWebProduct.product_id, selectedProduct.product_id);
                                    if (!replaceSuccess) {
                                        toast.error('Failed to replace the product');
                                        setIsLoading(false);
                                        return;
                                    }
                                    const deleteSuccess = await Data.deleteProduct(selectedWebProduct.product_id);
                                    if (!deleteSuccess) {
                                        toast.error('Failed to delete the original product');
                                        setIsLoading(false);
                                        return;
                                    }
                                    await Data.fetchWebProducts(setWebProductsData);
                                }

                                // Replace item
                                setIsLoading(false);
                                setIsMapping(true);
                            }}
                            style={{ fontSize: 'small' }}
                        >
                            Replace Product
                        </button>
                    </>                
            )}

            {selectedProduct && isMapping && (
                <ProductMapping selectedProduct={selectedProduct} selectedLocation={selectedLocation} onBack={mappingOnBack} onSubmit={mappingOnBack} />
            )}
        </>
    );
};

export default EditWebProduct;