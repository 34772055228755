import React, { useEffect, useState, useRef } from 'react';

import { faArrowsAltH, faAngleDown, faAngleUp, faPaperclip, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateText } from '../../utils/time_zones/TimeZones';
import { toast } from 'react-toastify';
import { fuzzySearch } from '../../utils/mapping/search';
import { sortArrayByAttribute, handleSortChange, getArrow } from '../../utils/tables/sortUtils';

const TransactionMatching = ({ ordersPerBundle, locations }) => {
    const [bundles, setBundles] = useState([]);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [minBundlePrice, setMinBundlePrice] = useState("");
    const [maxBundlePrice, setMaxBundlePrice] = useState("");
    const [minTransactionPrice, setMinTransactionPrice] = useState("");
    const [maxTransactionPrice, setMaxTransactionPrice] = useState("");
    const [minBundleDate, setMinBundleDate] = useState("");
    const [maxBundleDate, setMaxBundleDate] = useState("");
    const [minTransactionDate, setMinTransactionDate] = useState("");
    const [maxTransactionDate, setMaxTransactionDate] = useState("");
    const [bundlesToShow, setBundlesToShow] = useState(50); // Number of rows to show initially
    const [transactionsToShow, setTransactionsToShow] = useState(50); // Number of rows to show initially
    const [isLoadingMatch, setIsLoadingMatch] = useState(false);
    const [isLoadingUpload, setIsLoadingUpload] = useState(false);
    const [expandedBundles, setExpandedBundles] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [hoveredBundle, setHoveredBundle] = useState(null);
    const [hoveredTransaction, setHoveredTransaction] = useState(null);
    const [transactionMappings, setTransactionMappings] = useState([]);
    const [selectedBundles, setSelectedBundles] = useState([]);
    const [selectedTransactions, setSelectedTransactions] = useState([]);
    const [transactionType, setTransactionType] = useState('Unmatched');
    const [warning, setWarning] = useState(false);
    const [matchPressed, setMatchPressed] = useState(false);
    const [transactionSearch, setTransactionSearch] = useState('');
    const [paidBundles, setPaidBundles] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [bundleSearch, setBundleSearch] = useState('');

    const [filteredBundles, setFilteredBundles] = useState([]);
    const [displayedBundles, setDisplayedBundles] = useState([]);

    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [displayedTransactions, setDisplayedTransactions] = useState([]);

    const [bundleSum, setBundleSum] = useState(0);
    const [transactionSum, setTransactionSum] = useState(0);

    const [sortTransactionAttribute, setSortTransactionAttribute] = useState('');
    const [sortTransactionAsc, setSortTransactionAsc] = useState(true);

    const [sortBundleAttribute, setSortBundleAttribute] = useState('');
    const [sortBundleAsc, setSortBundleAsc] = useState(true);

    const [file, setFile] = useState('');
    const fileInputRef = useRef(null);

    const transactionOptions = ['Unmatched', 'Matched'];

    useEffect(() => {

        fetchTransactions(transactionType);
        fetchTransactionsMapping();
        fetchBundles();

        // initial filter for paid bundles.

    }, []);

    useEffect(() => {
        const finalBundles = [...bundles
            .filter(item => item.paid == 1), { order_code: 0 }];

        setPaidBundles(
            finalBundles
        );
        setManufacturerOptions([...new Set(bundles.map(item => item.manufacturer_name))].sort());
    }, [bundles])

    // Set the necessary bundles to display.
    useEffect(() => {
        setDisplayedBundles(filteredBundles.slice(0, bundlesToShow));
    }, [filteredBundles, bundlesToShow]);

    // Set the neessary transactions to display.
    useEffect(() => {
        setDisplayedTransactions(filteredTransactions.slice(0, transactionsToShow));
    }, [filteredTransactions, transactionsToShow]);

    // Switching between matched and unmatched.

    useEffect(() => {
        setBundlesToShow(50);
        setTransactionsToShow(50);
        fetchTransactions(transactionType);
        fetchTransactionsMapping();
        setSelectedBundles([]);
        setSelectedTransactions([]);
        setSortBundleAsc(true);
        setSortTransactionAsc(true);
        setSortTransactionAttribute('');
        setSortBundleAttribute('');

        resetBundleFilters();

        resetTransactionFilters();

    }, [transactionType]);

    // Apply bundle filteres
    useEffect(() => {
        filterBundles();
    }, [selectedLocation, selectedManufacturer, bundleSearch, transactionType,
        transactionMappings, paidBundles, sortBundleAsc, sortBundleAttribute, transactionMappings,
        minBundlePrice, maxBundlePrice, maxBundleDate, minBundleDate]);

    useEffect(() => {
        filterTransactions();
    }, [transactionSearch, sortTransactionAsc, sortTransactionAttribute, transactions, minTransactionPrice, maxTransactionPrice, minTransactionDate, maxTransactionDate]);

    const filterBundles = () => {
        const orderCodes = transactionMappings.map(item => item.order_code);

        const relevantOrders = paidBundles
            .filter(item => minBundlePrice ? item.total_with_taxes_shipping > minBundlePrice : true)
            .filter(item => maxBundlePrice ? item.total_with_taxes_shipping < maxBundlePrice : true)
            .filter(item => minBundleDate ? item.due_date >= minBundleDate : true)
            .filter(item => maxBundleDate ? item.due_date <= maxBundleDate : true)
            .filter(item => selectedLocation ? item.location_name === selectedLocation : true)
            .filter(item => selectedManufacturer ? item.manufacturer_name === selectedManufacturer : true)
            .filter(
                item => transactionType === 'Matched' ? orderCodes.includes(item.order_code) : !orderCodes.includes(item.order_code)
            );
        setFilteredBundles(sortArrayByAttribute(fuzzySearch(relevantOrders, bundleSearch, ['order_code', 'location_name', 'order_date', 'total_price', 'total_with_taxes_shipping', 'manufacturer_name']), sortBundleAttribute, sortBundleAsc));
    };
    const filterTransactions = () => {
        const temp_transactions = [...transactions]
            .filter(item => minTransactionPrice ? Math.abs(item.amount) > minTransactionPrice : true)
            .filter(item => maxTransactionPrice ? Math.abs(item.amount) < maxTransactionPrice : true)
            .filter(item => minTransactionDate ? item.transaction_date >= minTransactionDate : true)
            .filter(item => maxTransactionDate ? item.transaction_date <= maxTransactionDate : true);
        setFilteredTransactions(sortArrayByAttribute([...fuzzySearch(temp_transactions, transactionSearch, ['transaction_id', 'transaction_description', 'category', 'amount', 'transaction_date'])], sortTransactionAttribute, sortTransactionAsc));
    }

    const resetBundleFilters = () => {
        setMaxBundleDate('');
        setMinBundleDate('');
        setMinBundlePrice('');
        setMaxBundlePrice('');
        setBundleSearch('');
    };

    const resetTransactionFilters = () => {
        setMaxTransactionDate('');
        setMinTransactionDate('');
        setMinTransactionPrice('');
        setMaxTransactionPrice('');
        setTransactionSearch('');
    }

    // Calculate the difference sum of the match.
    useEffect(() => {
        setBundleSum(bundles
            .filter(obj => selectedBundles.includes(obj.order_code))  // Filter objects where id is in the ids list
            .reduce((total, obj) => total + obj.total_with_taxes_shipping, 0));
        setTransactionSum(transactions
            .filter(obj => selectedTransactions.includes(obj.transaction_id))  // Filter objects where id is in the ids list
            .reduce((total, obj) => total + obj.amount, 0));

    }, [selectedTransactions, selectedBundles]);

    // display more bundles when at the bottom of the view field.
    const handleBundleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            setBundlesToShow((prev) => prev + 20); // Load more items
        }
    };

    // handle a csv file selection.
    const fileSelected = (event) => {
        let f = event.target.files[0];
        setFile(f);
        fileInputRef.current.value = null;
    };

    const handleTransactionScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            setTransactionsToShow((prev) => prev + 20); // Load more items
        }
    };

    const priotizeMatched = (orderCodes, transactionIds) => {
        const orderCodesSet = new Set(orderCodes); // Create a Set for fast lookup
        const tempPaidBundles = [...paidBundles];
        setPaidBundles(tempPaidBundles.sort((a, b) => {
            // Check if each order_code is in the orderCodesSet
            const aInSet = orderCodesSet.has(a.order_code) ? 1 : 0;
            const bInSet = orderCodesSet.has(b.order_code) ? 1 : 0;

            // Prioritize items in the orderCodesSet
            return bInSet - aInSet;
        }));

        const transactionIdsSet = new Set(transactionIds); // Create a Set for fast lookup
        const tempDisplayedTransactions = [...transactions];
        setFilteredTransactions(tempDisplayedTransactions.sort((a, b) => {
            // Check if each order_code is in the orderCodesSet
            const aInSet = transactionIdsSet.has(a.transaction_id) ? 1 : 0;
            const bInSet = transactionIdsSet.has(b.transaction_id) ? 1 : 0;

            // Prioritize items in the orderCodesSet
            return bInSet - aInSet;
        }));
    };

    const fetchBundles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-bundles-location`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prev_months: 12 }),

            });

            if (!response.ok) {
                throw new Error('Error fetching bundles');
            }

            const data = await response.json();
            setBundles(data.filter(bundle => (bundle.bundle_status === 'Delivered' || bundle.bundle_status === 'Shipped')));
        } catch (error) {
            console.error('Error fetching bundles:', error);
            // Handle error, show error message, etc.
        }
    };

    // Retrieves matched or unmatched transactions based on type
    const fetchTransactions = async (type) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-transactions`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ transaction_type: type }),
            });

            if (!response.ok) {
                throw new Error('Error retrieving transactions');
            }
            const data = await response.json();
            setTransactions(data);
            setFilteredTransactions(data);
        }
        catch (error) {
            console.error('Error getting transactions:', error);
        }
    };

    // Retrieves all mappings.
    const fetchTransactionsMapping = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-transactions-mapping`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Error retrieving transactions mappings');
            }
            const data = await response.json();
            setTransactionMappings(data);

        }
        catch (error) {
            console.error('Error updating mappings:', error);
        }
    };

    // Submits new mappings and deletes the ones previously associated.
    const submitMapping = async (transactionIds, orderCodes, coaId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/map-transactions`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ transaction_ids: transactionIds, order_codes: orderCodes, coa_id: coaId }),
            });
            if (!response.ok) {
                throw new Error('Error submitting mapping');
            }
            const data = await response.json();
            fetchTransactions(transactionType);
            fetchTransactionsMapping();
            return true;
        }
        catch (error) {
            console.error('Error submitting mapping:', error);
            return false;
        }
    };

    // Submits new mappings and deletes the ones previously associated.
    const removeMapping = async (transactionIds, orderCodes) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/remove-transactions-mapping`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ transaction_ids: transactionIds, order_codes: orderCodes }),
            });
            if (!response.ok) {
                throw new Error('Error submitting mapping');
            }
            const data = await response.json();
            fetchTransactions(transactionType);
            fetchTransactionsMapping();
            return true;
        }
        catch (error) {
            console.error('Error submitting mapping:', error);
            return false;
        }
    };

    // Sets a bundle to be displayed.
    const handleBundleClick = (bundleId) => {
        if (expandedBundles.includes(bundleId)) {
            setExpandedBundles(expandedBundles.filter(id => id !== bundleId));
        } else {
            // If the bundle is not expanded, add it to the list of expanded bundles
            setExpandedBundles([...expandedBundles, bundleId]);
        }
    };

    // Uploads a transaction csv.
    const handleUpload = async () => {      
        setIsLoadingUpload(true);
        const formData = new FormData();
        formData.append('file', file); // Include file name for the backend

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upload-transactions`, {
                method: 'POST',
                body: formData
            });

            const data = await response.json();
            if (response.ok) {
                toast.success(data.response_text);
                setFile('')
                fetchTransactions(transactionType);
                fetchTransactionsMapping();
            } else {
                toast.error(data.response_text);
            }
            setIsLoadingUpload(false);
        } catch (error) {
            console.error("Error:", error);
            setIsLoadingUpload(false);
        }
    };

    // Runs the matching algorithm on existing data.
    const runMatchingAlg = async () => {
        try {
            setIsLoadingMatch(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/match-transactions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('could not complete matching');
            }
            const data = await response.json();
            if (response.ok) {
                toast.success(data.response_text);
                fetchTransactions(transactionType);
                fetchTransactionsMapping();
            }
            else {
                toast.error(data.response_text);
            }

            setIsLoadingMatch(false);
            return true;
        }
        catch (error) {
            console.error('Error matching transactions:', error);
            setIsLoadingMatch(false);
            return false;
        }
    };

    // Runs checks on potential matches and submits mappings.
    const handleMatchClick = async () => {
        if (isLoadingSubmit) return
        if (selectedTransactions.length > 1 && selectedBundles.length > 1 && !matchPressed) {
            toast.warning('You are submitting a many to many mapping.');
            setMatchPressed(true);
            return false
        }
        if (!matchPressed) {
            if (Math.round((transactionSum + bundleSum) * 100) / 100 !== 0) {
                toast.warning('Values are not equal. Verify before submitting.');
                setWarning(true);
            }
            setMatchPressed(true);
            return true
        }
        else if (matchPressed) {
            let coaId;
            const trashBundle = [0];
            if (selectedTransactions.length > 0 && transactionSum === 0 && selectedBundles.length === 0) {
                coaId = 2;
            }
            else {
                coaId = parseFloat((transactionSum + bundleSum).toFixed(2)) === 0;
            }
            const success = await submitMapping(selectedTransactions, coaId === 2 ? trashBundle : selectedBundles, coaId);
            if (success) {
                toast.success('Mapping submitted!');
                setWarning(false);
                setMatchPressed(false);
                setSelectedBundles([]);
                setSelectedTransactions([]);
            }
            else {
                toast.error('Failed to submit mapping.');
            }
            return success
        }
    };

    // Removes the matches.
    const handleUnmatchClick = async () => {
        if (isLoadingSubmit) return
        const emptyCoa = 0;
        const success = await removeMapping(selectedTransactions, selectedBundles);

        if (success) {
            toast.success('Successfully unmatched.');
            setSelectedTransactions([]);
            setSelectedBundles([])
        }
        else {
            toast.error('Failed to unmatch.');
        }
        return success
    };

    // Adds the transaction or bundle to selected.
    const handleRowClick = (id, type) => {
        setMatchPressed(false);
        setWarning(false);
        if (type === 'order') {
            if (transactionType === 'Matched') {
                if (selectedBundles.includes(id)) {
                    if (transactionMappings.find(element => element.order_code === id).coa_id === -1) {
                        setSelectedBundles([id]);
                        return;
                    }
                    setSelectedTransactions([]);
                    setSelectedBundles([]);
                    return;
                }
                const transactionIds = transactionMappings
                    .filter(transaction => transaction.order_code === id)
                    .map(transaction => transaction.transaction_id);
                // Find all transaction_ids associated with the found order codes
                const orderCodes = transactionMappings
                    .filter(transaction => transactionIds.includes(transaction.transaction_id))
                    .map(transaction => transaction.order_code);

                priotizeMatched([...new Set(orderCodes)], transactionIds);

                setSelectedTransactions(transactionIds);
                setSelectedBundles([...new Set(orderCodes)]);
                return;

            }

            const updatedArray = [...selectedBundles];

            // Check if the value is in the array
            const index = updatedArray.indexOf(id);

            if (index === -1) {
                // Value is not in the array, so add it
                updatedArray.push(id);
            } else {
                // Value is in the array, so remove it
                updatedArray.splice(index, 1);
            }
            setSelectedBundles(updatedArray);
        }
        else if (type === 'transaction') {

            if (transactionType === 'Matched') {
                if (selectedTransactions.includes(id)) {
                    if (transactions.find(element => element.transaction_id === id).coa_id === -1) {
                        setSelectedTransactions([id]);
                        return;
                    }

                    setSelectedTransactions([]);
                    setSelectedBundles([]);
                    return;
                }
                const orderCodes = transactionMappings
                    .filter(transaction => transaction.transaction_id === id)
                    .map(transaction => transaction.order_code);
                // Find all transaction_ids associated with the found order codes
                const transactionIds = transactionMappings
                    .filter(transaction => orderCodes.includes(transaction.order_code))
                    .map(transaction => transaction.transaction_id);
                priotizeMatched(orderCodes, [...new Set(transactionIds)]);
                setSelectedBundles(orderCodes);
                setSelectedTransactions([...new Set(transactionIds)]);
                return;

            }
            const updatedArray = [...selectedTransactions];

            // Check if the value is in the array
            const index = updatedArray.indexOf(id);

            if (index === -1) {
                // Value is not in the array, so add it
                updatedArray.push(id);
            } else {
                // Value is in the array, so remove it
                updatedArray.splice(index, 1);
            }
            setSelectedTransactions(updatedArray);
        }
    };

    return (
        <div>
            <div>
                <select
                    className='select-box'
                    id="transactions"
                    value={transactionType}
                    onChange={(e) => {
                        setSelectedLocation(e.target.value === 'Matched' ? '' : selectedLocation ? selectedLocation : '');
                        setTransactionType(e.target.value);
                    }}
                    style={{ padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
                >
                    {transactionOptions.map((item, index) => (
                        <option key={index} value={item}>{item}</option>
                    ))}
                </select>
                <button className='support-button' style={{ marginLeft: '10px', cursor: isLoadingMatch ? 'progress' : 'pointer' }} onClick={!isLoadingMatch ? runMatchingAlg : () => { }}>Auto Match</button>
                <button className='support-button' disabled={!file} style={{ marginLeft: '10px', cursor: isLoadingUpload ? 'progress' : 'pointer' }} onClick={handleUpload}>Upload</button>

                {!file && (
                    <>
                        <label htmlFor="fileInput"
                            className="support-button"
                            style={{ marginLeft: '10px' }}
                        >
                            <FontAwesomeIcon icon={faPaperclip} />
                        </label>
                        <input
                            type="file"
                            accept=".csv"
                            onChange={fileSelected}
                            ref={fileInputRef}
                            style={{ display: 'none', marginLeft: '10px' }} // Hide the actual file input
                            id="fileInput" // Assign an ID for the label to reference
                        // disabled={sendFiles.length >= 3}
                        />
                    </>


                )}

                {file && (
                    <button className='support-button'
                        onClick={() => setFile('')}
                        style={{ marginLeft: '10px', cursor: 'pointer' }}>
                        {file.name}
                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faTrash} />
                    </button>
                )}
            </div>


            <br />
            <br />

            <div style={{ display: 'flex' }}>

                <div style={{
                    border: '2px solid #87D3D7',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'left',
                    flexDirection: 'column',
                    width: '40%',
                    marginBottom: '10px',
                    maxHeight: '85vh', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <input
                            type="text"
                            className="select-box"
                            placeholder="Search"
                            value={transactionSearch}
                            onChange={e => {
                                setTransactionSearch(e.target.value);
                            }}
                            style={{ marginLeft: '10px', marginTop: '10px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '30%' }}
                        />

                        <button className="support-button"
                            style={{ marginLeft: '10px', marginTop: '10px', fontSize: 'small', width: '50px' }}
                            onClick={() => {
                                resetTransactionFilters();
                            }}
                        >Clear</button>

                        <button className="support-button"
                            disabled={selectedTransactions.length === 0}
                            style={{ marginLeft: 'auto', display: transactionType === 'Matched' ? 'none' : '', marginRight: '10px', marginTop: '10px', fontSize: 'small', cursor: isLoadingSubmit ? 'progress' : 'pointer' }}
                            onClick={isLoadingSubmit ? () => { } : async () => {
                                const trashOrderCode = 0;
                                const trashCoa = -1;
                                const result = await submitMapping(selectedTransactions, [trashOrderCode], trashCoa);
                                if (!result) {
                                    toast.error('Failed to mark as trash.');
                                    return;
                                }
                                toast.success('Successfully marked as trash.');
                                setSelectedTransactions([])
                            }}
                        ><FontAwesomeIcon icon={faTrash} /></button>

                        <button className="support-button"
                            disabled={selectedTransactions.length === 0}
                            style={{ marginLeft: transactionType === 'Matched' ? 'auto' : '', marginRight: '10px', marginTop: '10px', fontSize: 'small' }}
                            onClick={() => {
                                setSelectedTransactions([]);
                                if (transactionType === 'Matched') {
                                    setSelectedBundles([]);

                                }
                            }}
                        >Deselect All</button>
                    </div>
                    <div >
                        <input
                            className='select-box'
                            type="number"
                            id="minTransactionPrice"
                            step='100'
                            style={{ marginLeft: '10px', marginTop: '10px', width: '12%' }}
                            placeholder='Min Price'
                            min="0"
                            max={maxTransactionPrice}
                            value={minTransactionPrice}
                            onChange={(e) => setMinTransactionPrice(e.target.value)}
                        />

                        <input
                            className="select-box"
                            type="number"
                            step="100"
                            id="maxTransactionPrice"
                            min={minTransactionPrice ? minTransactionPrice : 0}
                            style={{ width: "12%", marginLeft: '10px' }}
                            placeholder='Max Price'
                            value={maxTransactionPrice}
                            onChange={(e) => setMaxTransactionPrice(e.target.value)}
                        />
                        <input
                            className='select-box'
                            type="date"
                            id="date"
                            style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', width: '17%' }}
                            value={minTransactionDate}
                            max={maxTransactionDate}
                            onChange={(e) => setMinTransactionDate(e.target.value)}
                        />
                        <span style={{ marginTop: '10px', }}>to</span>
                        <input
                            className='select-box'
                            type="date"
                            id="date"
                            style={{ marginTop: '10px', marginLeft: '10px', width: '17%' }}
                            value={maxTransactionDate}
                            min={minTransactionDate}
                            onChange={(e) => setMaxTransactionDate(e.target.value)}
                        />
                    </div>

                    <div style={{ overflowY: 'auto' }} onScroll={handleTransactionScroll}>
                        <table style={{
                            width: '100%',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            position: 'relative' // Ensure positioning context
                        }}>
                            <thead style={{
                                position: 'sticky', // Stick the header
                                top: 0, // Stick to the top of the table
                            }}>
                                <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                                    <th onClick={() => handleSortChange('transaction_id', sortTransactionAttribute, setSortTransactionAttribute, sortTransactionAsc, setSortTransactionAsc)}
                                        style={{ borderBottom: '1px solid black', padding: '10px', width: '5%' }}>Transaction ID {getArrow('transaction_id', sortTransactionAttribute, sortTransactionAsc)}</th>
                                    <th onClick={() => handleSortChange('transaction_date', sortTransactionAttribute, setSortTransactionAttribute, sortTransactionAsc, setSortTransactionAsc)}
                                        style={{ borderBottom: '1px solid black', padding: '10px', width: '15%' }}>Transaction Date {getArrow('transaction_date', sortTransactionAttribute, sortTransactionAsc)}</th>
                                    <th onClick={() => handleSortChange('transaction_description', sortTransactionAttribute, setSortTransactionAttribute, sortTransactionAsc, setSortTransactionAsc)}
                                        style={{ borderBottom: '1px solid black', padding: '10px', width: '20%' }}>Description {getArrow('transaction_description', sortTransactionAttribute, sortTransactionAsc)}</th>
                                    <th onClick={() => handleSortChange('category', sortTransactionAttribute, setSortTransactionAttribute, sortTransactionAsc, setSortTransactionAsc)}
                                        style={{ borderBottom: '1px solid black', padding: '10px', width: '20%' }}>Category {getArrow('category', sortTransactionAttribute, sortTransactionAsc)}</th>
                                    <th onClick={() => handleSortChange('amount', sortTransactionAttribute, setSortTransactionAttribute, sortTransactionAsc, setSortTransactionAsc)}
                                        style={{ borderBottom: '1px solid black', padding: '10px', width: '20%' }}>Amount {getArrow('amount', sortTransactionAttribute, sortTransactionAsc)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedTransactions.map((transaction, index) => {
                                    const hoverColor = '#87D3D7';//'#87D3D7';
                                    const selectedColor = '#31bac1';
                                    const defaultColor = index % 2 === 0 ? '#ebf4f5' : '#ffffff';
                                    let color = index % 2 === 0 ? '#ebf4f5' : '#ffffff';
                                    if (selectedTransactions.includes(transaction.transaction_id)) color = selectedColor;
                                    else if (hoveredTransaction === transaction.transaction_id) color = hoverColor;
                                    const style = { padding: '10px', cursor: 'pointer' };
                                    return (
                                        <tr key={transaction.transaction_id}
                                            onClick={() => handleRowClick(transaction.transaction_id, 'transaction')}
                                            onMouseEnter={() => setHoveredTransaction(transaction.transaction_id)}
                                            onMouseLeave={() => setHoveredTransaction(null)}
                                            style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: color }}>
                                            <td style={style}>{transaction.transaction_id} {transactionType === 'Matched' && transaction?.coa_id === -1 ? <FontAwesomeIcon icon={faTrash} /> : ''}</td>
                                            <td style={style}>{formatDateText(transaction.transaction_date)}</td>
                                            <td style={style}>{transaction.transaction_description}</td>
                                            <td style={style}>{transaction.category}</td>
                                            <td style={style}>${transaction.amount}</td>
                                        </tr>);
                                })}
                            </tbody>
                        </table>
                    </div>

                </div>
                <div style={{
                    position: 'relative',
                    textAlign: 'center',
                    alignContent: 'center',
                    padding: '8px',
                    width: '7vw'
                    // height: '100vh'        // Full viewport height
                }}>
                    <button
                        disabled={((selectedTransactions.length === 0) || (selectedBundles.length === 0 && transactionSum + bundleSum !== 0))}
                        className='support-button'
                        style={{ fontSize: 'medium', width: '100%', cursor: isLoadingSubmit ? 'progress' : 'pointer' }}
                        onClick={async () => {
                            if (transactionType === 'Matched') {
                                setIsLoadingSubmit(true);
                                await handleUnmatchClick();
                                setIsLoadingSubmit(false);
                            }
                            else {
                                setIsLoadingSubmit(true);
                                handleMatchClick();
                                setIsLoadingSubmit(false);
                            }
                        }}

                    >
                        {matchPressed ? 'Submit' : transactionType === 'Matched' ? 'Unmatch' : 'Match'}
                        <br /> <FontAwesomeIcon icon={faArrowsAltH} />
                    </button>


                    <div className='select-box'
                        style={{ cursor: 'text', marginTop: '10px', borderColor: warning ? 'orange' : '' }}
                    >
                        ${Math.round((transactionSum + bundleSum) * 100) / 100}
                    </div>


                    <br />
                </div>
                <div style={{
                    border: '2px solid #87D3D7',
                    display: 'flex',
                    borderRadius: '8px',
                    justifyContent: 'left',
                    flexDirection: 'column',
                    width: '60%',
                    marginBottom: '10px',
                    maxHeight: '85vh', // Set a fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', }}>
                        <input
                            type="text"
                            className='select-box'
                            placeholder="Search"
                            value={bundleSearch}
                            onChange={e => {
                                setBundleSearch(e.target.value);
                            }}
                            style={{ marginLeft: '10px', marginTop: '10px', width: '30%' }}
                        />
                        <button className="support-button"
                            style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px', fontSize: 'small', width: '50px' }}
                            onClick={() => {
                                resetBundleFilters();
                            }}
                        >Clear</button>

                        <button className="support-button"
                            disabled={selectedBundles.length === 0}
                            style={{ marginLeft: 'auto', display: transactionType === 'Matched' ? 'none' : '', marginRight: '10px', marginTop: '10px', fontSize: 'small', cursor: isLoadingSubmit ? 'progress' : 'pointer' }}
                            onClick={isLoadingSubmit ? () => { } : async () => {
                                const knownBundleTransaction = -1;
                                const trashCoa = -1;
                                const result = await submitMapping([knownBundleTransaction], selectedBundles, trashCoa);
                                if (!result) {
                                    toast.error('Failed to mark as trash.');
                                    return;
                                }
                                toast.success('Successfully marked as trash.');
                                setSelectedBundles([])
                            }}
                        ><FontAwesomeIcon icon={faTrash} /></button>
                        <button className="support-button"
                            disabled={selectedBundles.length === 0}
                            style={{ marginLeft: transactionType === 'Matched' ? 'auto' : '', marginRight: '10px', marginTop: '10px', fontSize: 'small' }}
                            onClick={() => {
                                setSelectedBundles([]);
                                if (transactionType === 'Matched') {
                                    setSelectedTransactions([]);
                                }
                            }}

                        >Deselect All</button>
                    </div>
                    <div>
                        <select
                            id="location"
                            className='select-box'
                            value={selectedLocation}
                            onChange={(e) => { setSelectedLocation(e.target.value); setExpandedBundles([]); }}
                            style={{ width: '15%', marginTop: '10px', marginLeft: '10px' }}
                        >
                            <option value="">All Locations</option>
                            {locations.sort().map((item, index) => (
                                <option key={index} value={item.location_name}>{item.location_name}</option>
                            ))}
                        </select>
                        <select
                            id="manufacturer"
                            className='select-box'
                            value={selectedManufacturer}
                            onChange={(e) => { setSelectedManufacturer(e.target.value); setExpandedBundles([]); }}
                            style={{ width: '17%', marginTop: '10px', marginLeft: '10px' }}
                        >
                            <option value="">All Manufacturers</option>
                            {manufacturerOptions.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                        <input
                            className='select-box'
                            type="number"
                            id="minBundlePrice"
                            step='100'
                            style={{ marginLeft: '10px', marginTop: '10px', width: '10%' }}
                            placeholder='Min Price'
                            value={minBundlePrice}
                            max={maxBundlePrice}
                            min="0"
                            onChange={(e) => setMinBundlePrice(e.target.value)}
                        />

                        <input
                            className="select-box"
                            type="number"
                            step="100"
                            id="maxBundlePrice"
                            style={{ width: "10%", marginLeft: '10px' }}
                            placeholder='Max Price'
                            min={minBundlePrice ? minBundlePrice : 0}
                            value={maxBundlePrice}
                            onChange={(e) => setMaxBundlePrice(e.target.value)}
                        />
                        <span>
                            <input
                                className='select-box'
                                type="date"
                                id="date"
                                style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', width: '12%' }}
                                value={minBundleDate}
                                max={maxBundleDate}
                                onChange={(e) => setMinBundleDate(e.target.value)}
                            />
                            <span style={{ marginTop: '10px', }}>to</span>
                            <input
                                className='select-box'
                                type="date"
                                id="date"
                                style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', width: '12%' }}
                                value={maxBundleDate}
                                min={minBundleDate}
                                onChange={(e) => setMaxBundleDate(e.target.value)}
                            />

                        </span>



                    </div>                    
                    <div style={{ marginTop: '10px' }}>

                    </div>
                    <div style={{ overflowY: 'auto' }} onScroll={handleBundleScroll}>
                        <table style={{
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            position: 'relative' // Ensure positioning context
                        }}>
                            <thead style={{
                                position: 'sticky', // Stick the header
                                top: 0, // Stick to the top of the table
                                zIndex: 1 // Ensure it stays above the tbody rows
                            }}>
                                <tr style={{ fontSize: '14px', backgroundColor: 'white', width: '5%' }}>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px', width: '5%' }}></th>
                                    <th onClick={() => handleSortChange('order_code', sortBundleAttribute, setSortBundleAttribute, sortBundleAsc, setSortBundleAsc)}
                                        style={{ borderBottom: '2px solid black', padding: '10px', width: '5%' }}>Code {getArrow('order_code', sortBundleAttribute, sortBundleAsc)}</th>
                                    <th onClick={() => handleSortChange('due_date', sortBundleAttribute, setSortBundleAttribute, sortBundleAsc, setSortBundleAsc)}
                                        style={{ borderBottom: '2px solid black', padding: '10px', width: '20%' }}>Due Date {getArrow('due_date', sortBundleAttribute, sortBundleAsc)}</th>
                                    <th onClick={() => handleSortChange('bundle_date', sortBundleAttribute, setSortBundleAttribute, sortBundleAsc, setSortBundleAsc)}
                                        style={{ borderBottom: '2px solid black', padding: '10px', width: '15%' }}>Order Date {getArrow('bundle_date', sortBundleAttribute, sortBundleAsc)}</th>
                                    <th onClick={() => handleSortChange('location_name', sortBundleAttribute, setSortBundleAttribute, sortBundleAsc, setSortBundleAsc)}
                                        style={{ borderBottom: '2px solid black', padding: '10px', width: '5%' }}>Location {getArrow('location_name', sortBundleAttribute, sortBundleAsc)}</th>
                                    <th onClick={() => handleSortChange('manufacturer_name', sortBundleAttribute, setSortBundleAttribute, sortBundleAsc, setSortBundleAsc)}
                                        style={{ borderBottom: '2px solid black', padding: '10px', width: '5%' }}>Manufacturer {getArrow('manufacturer_name', sortBundleAttribute, sortBundleAsc)}</th>
                                    <th onClick={() => handleSortChange('total_price', sortBundleAttribute, setSortBundleAttribute, sortBundleAsc, setSortBundleAsc)}
                                        style={{ borderBottom: '2px solid black', padding: '10px', width: '5%' }}>Total Product {getArrow('total_price', sortBundleAttribute, sortBundleAsc)}</th>
                                    <th onClick={() => handleSortChange('shipping', sortBundleAttribute, setSortBundleAttribute, sortBundleAsc, setSortBundleAsc)}
                                        style={{ borderBottom: '2px solid black', padding: '10px', width: '2%' }}>Shipping {getArrow('shipping', sortBundleAttribute, sortBundleAsc)}</th>
                                    <th onClick={() => handleSortChange('taxes', sortBundleAttribute, setSortBundleAttribute, sortBundleAsc, setSortBundleAsc)}
                                        style={{ borderBottom: '2px solid black', padding: '10px', width: '5%' }}>Taxes {getArrow('taxes', sortBundleAttribute, sortBundleAsc)}</th>
                                    <th onClick={() => handleSortChange('discount', sortBundleAttribute, setSortBundleAttribute, sortBundleAsc, setSortBundleAsc)}
                                        style={{ borderBottom: '2px solid black', padding: '10px', width: '5%' }}>Discount {getArrow('discount', sortBundleAttribute, sortBundleAsc)}</th>
                                    <th onClick={() => handleSortChange('total_with_taxes_shipping', sortBundleAttribute, setSortBundleAttribute, sortBundleAsc, setSortBundleAsc)}
                                        style={{ borderBottom: '2px solid black', padding: '10px', width: '5%' }}>Total {getArrow('total_with_taxes_shipping', sortBundleAttribute, sortBundleAsc)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedBundles.map((bundle, index) => {
                                    const hoverColor = '#87D3D7';//'#87D3D7';
                                    const selectedColor = '#31bac1';
                                    let color = index % 2 === 0 ? '#ebf4f5' : '#ffffff';
                                    if (selectedBundles.includes(bundle.order_code)) color = selectedColor;
                                    else if (hoveredBundle === bundle.order_code) color = hoverColor;
                                    return (
                                        <>
                                            <tr key={bundle.order_code}
                                                onClick={() => handleRowClick(bundle.order_code, 'order')}
                                                onMouseEnter={() => {
                                                    setHoveredBundle(bundle.order_code);
                                                }}
                                                onMouseLeave={() => setHoveredBundle(null)}
                                                style={{ fontSize: '13px', backgroundColor: color, cursor: 'pointer' }} >
                                                <td
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBundleClick(bundle.order_code);
                                                    }}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <span>
                                                        {expandedBundles.includes(bundle.order_code) ?
                                                            <FontAwesomeIcon icon={faAngleUp} /> :
                                                            <FontAwesomeIcon icon={faAngleDown} />
                                                        }
                                                    </span>
                                                </td>
                                                <td onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleBundleClick(bundle.order_code);
                                                }} style={{ padding: '10px', }}>{bundle.order_code}</td>
                                                <td style={{ padding: '10px', width: '5%' }}>{formatDateText(bundle.due_date ? bundle.due_date : '')}</td>

                                                <td style={{ padding: '10px', width: '5%' }}>{formatDateText(bundle.bundle_date ? bundle.bundle_date : '')}</td>
                                                <td style={{ padding: '10px', width: '5%' }}>{bundle.location_name}</td>
                                                <td style={{ padding: '10px', width: '5%' }}>{bundle.manufacturer_name}</td>
                                                <td style={{ padding: '10px', width: '5%' }}>${bundle.total_price?.toFixed(2)}</td>
                                                <td style={{ padding: '10px', width: '5%' }}>${bundle.shipping}</td>
                                                <td style={{ padding: '10px', width: '5%' }}>${bundle.taxes}</td>
                                                <td style={{ padding: '10px', width: '5%' }}>${bundle.discount}</td>
                                                <td style={{ padding: '10px', width: '5%' }}>${bundle.total_with_taxes_shipping?.toFixed(2)}</td>
                                            </tr>
                                            {expandedBundles.includes(bundle.order_code) &&
                                                <tr>
                                                    {/* Render order details */}
                                                    <td colSpan="1"></td>
                                                    <td colSpan="11">
                                                        <div style={{ border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden' }}>
                                                            <table style={{
                                                                width: '100%',
                                                                borderCollapse: 'separate',
                                                                borderSpacing: 0,
                                                                position: 'relative' // Ensure positioning context
                                                            }}>
                                                                <thead style={{
                                                                    position: 'sticky', // Stick the header
                                                                    top: 0, // Stick to the top of the table
                                                                }}>
                                                                    <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Order Date</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Product Name</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Product Description</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Manufacturer Name</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Unit Price</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Order Quantity</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Order Price</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Internal Description</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Client Comment</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Order Comment</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Order Status</th>
                                                                        <th style={{ borderBottom: '1px solid black', padding: '10px' }}>Order Urgency</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {ordersPerBundle[bundle.order_code] && ordersPerBundle[bundle.order_code].map((order, orderIndex) => {
                                                                        // Conditional style for hovered row

                                                                        return (
                                                                            <tr style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: orderIndex % 2 === 0 ? '#ebf4f5' : '#ffffff' }} key={order.order_id}
                                                                            >
                                                                                <td style={{ padding: '10px' }}>{formatDateText(order.order_date ? order.order_date : '')}</td>
                                                                                <td style={{ padding: '10px' }}>{order.product_name}</td>
                                                                                <td style={{ padding: '10px' }}>{order.product_description}</td>
                                                                                <td style={{ padding: '10px' }}>{order.manufacturer_name}</td>
                                                                                <td style={{ padding: '10px' }}>${order.unit_price}</td>
                                                                                <td style={{ padding: '10px' }}>{order.order_quantity}</td>
                                                                                <td style={{ padding: '10px' }}>${parseFloat(order.order_quantity * order.unit_price).toFixed(2)}</td>
                                                                                <td style={{ padding: '10px' }}>{order.internal_description}</td>
                                                                                <td style={{ padding: '10px' }}>{order.client_comment}</td>
                                                                                <td style={{ padding: '10px' }}>{order.order_comment}</td>
                                                                                <td style={{ padding: '10px' }}>{order.status_cat}</td>
                                                                                <td style={{ padding: '10px' }}>{order.order_urgency}</td>
                                                                            </tr>);
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default TransactionMatching;