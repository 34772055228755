import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as Data from '../utils/mapping';
import OrderReview from '../features/mapping/OrderReview';
import ServiceSelection from '../features/mapping/ServiceSelection';
import ProductSelection from '../features/mapping/ProductSelection';
import EditWebProduct from '../features/mapping/EditWebProduct';
import MappingReview from '../features/mapping/MappingReview';

const Mapping = ({ }) => {
    const contentRef = useRef();
    const [serviceOptions, setServiceOptions] = useState([])
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('VIO Annapolis');
    const [selectedTab, setSelectedTab] = useState('ServiceMapping');
    const [serviceMappingOrderStatus, setServiceMappingOrderStatus] = useState('');
    const [inventoryCurrentData, setCurrentInventoryData] = useState([]);
    const [webProductsData, setWebProductsData] = useState([]);
    const [orderSpotcheck, setOrderSpotcheck] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        Data.fetchLocations(setLocations);
        Data.fetchInventory(selectedLocation, setCurrentInventoryData);
        Data.fetchWebProducts(setWebProductsData);
        Data.fetchOrderSpotcheck(selectedLocation, setOrderSpotcheck);
        Data.fetchServicesData({ selectedLocation, onlyNew: false, callback: setServiceOptions });
        resetAll()
        contentRef.current.style.marginLeft = '250px';
        contentRef.current.style.marginRight = '50px';
    }, [selectedLocation]);


    useEffect(() => {
        if (selectedTab === 'Web') {
            Data.fetchWebProducts(setWebProductsData);
        }
        if (selectedTab === 'ServiceMapping') {
            Data.fetchServicesData({ selectedLocation, onlyNew: false, callback: setServiceOptions });
            Data.fetchInventory(selectedLocation, setCurrentInventoryData);
        }
    }, [selectedTab])

    /**********************/
    /* HANDLING FUNCTIONS */
    /**********************/

    
    const handleTabClick = (tab) => {
        setRefreshKey(prevKey => prevKey + 1);
        if (tab === 'ServiceMapping') {
            Data.fetchInventory(selectedLocation, setCurrentInventoryData);
            Data.fetchWebProducts(setWebProductsData);
            Data.fetchOrderSpotcheck(selectedLocation, setOrderSpotcheck);
            Data.fetchServicesData({ selectedLocation, onlyNew: false, callback: setServiceOptions });
        }
        setSelectedTab(tab);
        resetAll();
    };

    const resetAll = () => {
        setServiceMappingOrderStatus('');

    };

    return (
        <div ref={contentRef} style={{ textAlign: 'left' }}>
            <ToastContainer />
            <h1 style={{ marginTop: '27px',  fontSize: '40px', fontWeight: 'bold', color: '#87D3D7'}}>Mapping</h1>

            <select
                id="location"
                value={selectedLocation}
                onChange={(e) => {resetAll(); setSelectedLocation(e.target.value);}}
                style={{ padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
                {locations.sort().map((item, index) => (
                    <option key={index} value={item.location_name}>{item.location_name}</option>
                ))}
            </select>
            
            <br/>
            <br/>
            <br/>

            <div>   
                <span 
                    onClick={() => handleTabClick('ServiceMapping')}
                    style={{
                        marginLeft: '0px',
                    color: '#000000',
                    borderBottom: selectedTab === 'ServiceMapping' ? ' 2px solid black' : ' 2px solid transparent',
                    fontSize: '25px', // Updated font size
                    fontWeight: 'bold', // Added font weight
                    cursor: 'pointer' // Set cursor to pointer
                    }}
                >
                    Edit Service Mapping
                </span>

                <span 
                    onClick={() => handleTabClick('Web')}
                    style={{
                    marginLeft: '50px',
                    color: '#000000',
                    borderBottom: selectedTab === 'Web' ? ' 2px solid black' : ' 2px solid transparent',
                    fontSize: '25px', // Updated font size
                    fontWeight: 'bold', // Added font weight
                    cursor: 'pointer' // Set cursor to pointer
                    }}
                >
                    Edit Web Product
                </span>

                <span 
                    onClick={() => handleTabClick('Mapping Review')}
                    style={{
                    marginLeft: '50px',
                    color: '#000000',
                        borderBottom: selectedTab === 'Mapping Review' ? ' 2px solid black' : ' 2px solid transparent',
                    fontSize: '25px', // Updated font size
                    fontWeight: 'bold', // Added font weight
                    cursor: 'pointer' // Set cursor to pointer
                    }}
                >
                    Mapping Review
                </span>

                <span
                    onClick={() => handleTabClick('Order Review')}
                    style={{
                        marginLeft: '50px',
                        color: '#000000',
                        borderBottom: selectedTab === 'Order Review' ? ' 2px solid black' : ' 2px solid transparent',
                        fontSize: '25px', // Updated font size
                        fontWeight: 'bold', // Added font weight
                        cursor: 'pointer' // Set cursor to pointer
                    }}
                >
                    Order Review
                </span>
            </div>

            <br/>
            <br/>
           
            {selectedTab === 'ServiceMapping' && (
                <>
                    { true && (
                        <>
                            {serviceMappingOrderStatus == '' &&

                                <div style={{marginLeft: '0px'}}>
                                    <button onClick={() => setServiceMappingOrderStatus('New Product')}
                                        className='support-button'
                                        style={{fontSize: 'medium', marginRight: '20px', width: '20vw'}} 
                                    >
                                        Map a new product to service(s)
                                    </button>
                                    <br/>
                                    <br/>
                                    <button onClick={() => setServiceMappingOrderStatus('New Service')}
                                        className='support-button'
                                        style={{fontSize: 'medium', marginRight: '20px', width: '20vw'}} 
                                    >
                                        Map a new service to product(s)
                                    </button>
                                    <br/>
                                    <br/>
                                    <button onClick={() => setServiceMappingOrderStatus('Product')}
                                        className='support-button'
                                        style={{fontSize: 'medium', marginRight: '20px', width: '20vw'}} 
                                    >
                                        Map an existing product to service(s)
                                    </button>
                                    <br/>
                                    <br/>
                                    <button onClick={() => setServiceMappingOrderStatus('Service')}
                                        className='support-button'
                                        style={{fontSize: 'medium', marginRight: '20px', width: '20vw'}} 
                                    >
                                        Map an existing service to product(s)
                                    </button>
                                </div>
                            }
                            {serviceMappingOrderStatus == 'New Service' &&
                                <ServiceSelection newService={true} selectedLocation={selectedLocation} serviceOptions={serviceOptions} backPressed={() => setServiceMappingOrderStatus('')} />
                            }
                            {serviceMappingOrderStatus == 'Service' &&
                                <ServiceSelection selectedLocation={selectedLocation} serviceOptions={serviceOptions} backPressed={() => setServiceMappingOrderStatus('')} />
                            }
                            {serviceMappingOrderStatus == 'New Product' &&
                                <ProductSelection newProduct={true} selectedLocation={selectedLocation} productOptions={inventoryCurrentData} backPressed={() => setServiceMappingOrderStatus('')} />
                            }
                            {serviceMappingOrderStatus == 'Product' &&
                                <ProductSelection selectedLocation={selectedLocation} productOptions={inventoryCurrentData} backPressed={() => setServiceMappingOrderStatus('')} />
                            }   
                        </>
                    )} 
                </>
            )}

            { selectedTab === 'Web' && ( 
                <>
                    <EditWebProduct selectedLocation={selectedLocation} webProductsData={webProductsData} currentInventory={inventoryCurrentData} refreshKey={refreshKey} />
                </>
            )}

            {selectedTab === 'Mapping Review' && (
                <>
                    <MappingReview selectedLocation={selectedLocation} serviceOptions={serviceOptions} refreshKey={refreshKey} />
                </>
            )}

            {selectedTab === 'Order Review' && (
                <>
                    <OrderReview orderSpotcheck={orderSpotcheck} setOrderSpotcheck={setOrderSpotcheck} location={selectedLocation} />
                </>
            )}
            <br/>
            <br/>
        </div>
    );
}

export default Mapping;
