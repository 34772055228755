// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import {getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA9yi4RfpuciJGbOZsMcRniwAxXNqkzbcU",
  authDomain: "medvelle-internal.firebaseapp.com",
  projectId: "medvelle-internal",
  storageBucket: "medvelle-internal.appspot.com",
  messagingSenderId: "678366794441",
  appId: "1:678366794441:web:6783ee6690a800becbb318",
  measurementId: "G-1XGJXKSKSZ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export {auth};
