import { formatDateToUTCString } from '../time_zones/TimeZones';
import { fetchInventory, fetchTicketInfo, updateBundle, updateTrackingNumber, updateBundlePaidStatus } from '../locations/locationsUtils';
import { sendOrderEmail } from '../notifications/notificationsUtils';

import { toast } from 'react-toastify';

/**
 * Handles the submission of an edited order by making the necessary API requests and updating the ticket information.
 *
 * @param {Object} editedOrder - The order object that has been edited and needs to be updated.
 * @param {Array} orders - The list of all orders that are part of the bundle.
 * @param {string} bundle_id - The ID of the bundle to which the orders belong.
 * @param {string} location_name - The name of the location where the bundle is being processed.
 * @param {Function} setTicketInfo - A function to update the ticket information in the state.
 * @param {string} username - The username of the person making the edits.
 */
export const handleEditSubmit = async (editedOrder, orders, bundle_id, location_name, setTicketInfo, username) => {

  try {
    if (editedOrder) {
      const { order_id, order_code, product_id, unit_price, order_quantity, internal_description, status_cat, supplier_id, order_urgency, client_comment } = editedOrder;

      // Fetch the old order data using the /orders API
      const oldOrderData = orders.find(order => order.order_id === order_id);

      if (status_cat === 'Remove') {
        await fetch(process.env.REACT_APP_BACKEND_URL + '/update-order-code', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            order_id: order_id,
            order_code: 0,
            order_code_old: order_code,
            location_name: location_name
          }),
        });

      } else if (status_cat === 'Recommend') {
        await fetch(process.env.REACT_APP_BACKEND_URL + '/update-order-code', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            order_id: order_id,
            order_code: -1,
            order_code_old: order_code,
            location_name: location_name
          }),
        });

      } else if (status_cat === 'Declined') {
        await fetch(process.env.REACT_APP_BACKEND_URL + '/update-order-code', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            order_id: order_id,
            order_code: -3,
            order_code_old: order_code,
            location_name: location_name
          }),
        });

      } else if (status_cat === 'Delivered') {
        const result = await fetchInventory(location_name);

        // If the update was successful, fetch the current inventory quantity
        const productId = orders.find(order => order.order_id === order_id).product_id;
        let currentInventoryData = result.find(item => item.product_id === productId);

        if (currentInventoryData) {
          // Update spotcheck to check order
          const requestBody = {
            productId: productId,
            locationName: location_name,
            orderId: order_id
          };

          // Make a PUT request to change the spotcheck status
          await fetch(process.env.REACT_APP_BACKEND_URL + '/change-spotcheck-status', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
          });

          // Edit quantity
          let currentQuantity = currentInventoryData.inventory_quantity;
          const newQuantity = currentQuantity + order_quantity * currentInventoryData.quantity_conversion;

          // Send a PUT request to update the inventory quantity by product_id
          const inventoryResponse = await fetch(process.env.REACT_APP_BACKEND_URL + `/edit-quantity/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newQuantity, locationName: location_name }),
          });

          const inventoryResult = await inventoryResponse.json();
          if (inventoryResult.success) {
            console.log('Inventory quantity updated successfully');

            // Call the /insert-tracking-data API to insert tracking data
            const date_time = formatDateToUTCString(new Date());
            const old_inventory_quantity = currentQuantity;
            const new_inventory_quantity = newQuantity;
            const origin = 'Order (+)';

            await fetch(process.env.REACT_APP_BACKEND_URL + `/insert-tracking-data/${product_id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ location_name, username, product_id, old_inventory_quantity, new_inventory_quantity, date_time, origin }),
            });

            console.log('Tracking data inserted successfully');

            if (unit_price !== 0) {
              // Call the API to update the product price and insert a price tracking record
              await fetch(process.env.REACT_APP_BACKEND_URL + '/update-product-price', {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  price: unit_price,
                  product_id: product_id,
                  location_name: location_name,
                  username: username,
                  origin: 'Automation-Page',
                }),
              });

              console.log('Product price updated successfully');
            }
          } else {
            console.error('Failed to update inventory quantity');
          }
        }
      }

      // Make a PUT request to update the order details with new data
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/update-order/${order_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          unit_price,
          order_quantity,
          internal_description,
          status_cat,
          supplier_id
        }),
      });

      if (!response.ok) {
        throw new Error('Error updating order details');
      }

      // Handle success response (you can show a success message if needed)
      console.log('Order details updated successfully');

      // Prepare data for order tracking
      const date_time = formatDateToUTCString(new Date());

      // Send a POST request to store order tracking data with the required fields
      await fetch(process.env.REACT_APP_BACKEND_URL + '/store-orders-tracking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          order_id,
          location_name,
          product_id,
          order_quantity_before: oldOrderData.order_quantity,
          order_quantity_after: order_quantity,
          unit_price_before: oldOrderData.unit_price,
          unit_price_after: unit_price,
          order_comment_before: oldOrderData.order_comment,
          order_comment_after: username,
          status_cat_before: oldOrderData.status_cat,
          status_cat_after: status_cat,
          change_datetime: date_time,
          order_urgency_before: oldOrderData.order_urgency,
          order_urgency_after: order_urgency,
          client_comment_before: oldOrderData.client_comment,
          client_comment_after: client_comment
        }),
      });

      console.log('Order tracking data inserted successfully');
      await fetchTicketInfo(setTicketInfo, bundle_id);
    }
  } catch (error) {
    // Handle errors, you can show an error message if needed
    console.error('Error updating order details:', error);
    toast.error('Failed to update order details');
  }
};

/**
 * Updates the order code for a list of selected orders by making a PUT request to the /update-order-code API.
 *
 * @param {string} orderCodeToSend - The new order code to be set.
 * @param {Array} selectedOrders - The list of selected orders to be updated.
 * @returns {Promise} - A promise that resolves when all orders are updated successfully.
 */
export const updateOrdersCode = (orderCodeToSend, selectedOrders) => {
  return new Promise((resolve, reject) => {
      const promises = selectedOrders.map(async (order) => {
          try {
              const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/update-order-code', {
                  method: 'PUT',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                      order_id: order.order_id,
                      order_code: orderCodeToSend,
                      order_code_old: order.order_code,
                      location_name: order.location_name,
                  }),
              });

              if (response.status === 200) {
                  console.log(`Order ${order.order_id} updated successfully with order code ${orderCodeToSend}`);
              } else {
                  console.error(`Failed to update order ${order.order_id}`);
              }
              return Promise.resolve(); // Resolve the individual promise
          } catch (error) {
              console.error('Error updating order:', error);
              return Promise.reject(error); // Reject the individual promise
          }
      });

      Promise.all(promises)
          .then(() => {
              resolve(); // Resolve the outer promise once all inner promises are resolved
          })
          .catch((error) => {
              reject(error); // Reject the outer promise if any of the inner promises are rejected
          });
  });
};

/**
 * Function to fetch recent bundle updates.
 * 
 * @param {function} setRecentUpdates - The function to set the state with the fetched data.
 */
export const fetchRecentBundleUpdates = async (setRecentBundleUpdates, bundleID) => {
  try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/recent-bundle-updates/${bundleID}`);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRecentBundleUpdates(data);
  } catch (error) {
      console.error('Error fetching recent updates:', error);
  }
};

/**
 * Fetches order data from the server and sets it in the state.
 * Filters the orders based on the specified location name.
 *
 * @param {string} locationName - The name of the location to filter the orders by.
 * @param {function} setOrderData - The state setter function for the filtered order data.
 */
export const fetchOrderData = async (locationName, setOrdersData) => {
  fetch(process.env.REACT_APP_BACKEND_URL + '/orders')
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {

      let locationOrderData = data;

      if (locationName !== 'All Locations'){
        locationOrderData = data.filter(item => item.location_name === locationName);
      }

      setOrdersData(locationOrderData);
    })
    .catch(error => console.error('Error fetching order data:', error));
};

/**
 * Handles the submission of bundle updates.
 *
 * @param {Object} editedBundle - The bundle being edited, including bundle date, order code, bundle status, etc.
 * @param {String} editedBundleStatus - The edited status of the bundle.
 * @param {String} dueDateOption - The selected due date option.
 * @param {Function} fetchTicketInfo - Function to fetch the updated ticket information.
 * @param {Function} setTicketInfo - Function to update the state of ticket information.
 * @param {Function} onClose - Function to close the modal or form after submission.
 * @param {Boolean} setIsLoading - Function to set the loading state.
 * @param {Number} selectedSupplierId - The ID of the selected supplier.
 * @param {String} optionOrder - The selected order option.
 * @param {Object} emailData - Data for the order email.
 * @param {String} trackingNumber - The tracking number for the shipment.
 * @param {Boolean} invoiceExist - Indicates if the invoice exists.
 * @param {String} editedShippingPrice - The edited shipping price.
 * @param {String} editedTaxesPrice - The edited taxes price.
 * @param {String} editedDiscount - The edited discount.
 * @param {String} bundleComment - Additional comments for the bundle.
 * @param {String} dueDate - The due date for the bundle.
 */

export const handleBundleSubmit = async (
  editedBundle, editedBundleStatus, dueDateOption, setTicketInfo, onClose, 
  setIsLoading, selectedSupplierId, optionOrder, emailData, trackingNumber, invoiceExist,
  editedShippingPrice, editedTaxesPrice, editedDiscount, bundleComment, dueDate, username, eta=''
) => {

  setIsLoading(true);

  if (dueDateOption === '' && editedBundle.bundle_status !== 'Shipped' && ['Shipped', 'Delivered'].includes(editedBundleStatus)) {
      alert('Please select a due date option.');
      setIsLoading(false);
      return;
  }

  if ((editedBundleStatus === 'Placed' && editedBundle.bundle_status !== 'Placed') || editedBundle.bundle_status === 'Open') {
      if (selectedSupplierId === 0 || !selectedSupplierId || !Number.isInteger(parseInt(selectedSupplierId))) {
          alert('Please select a supplier.');
          setIsLoading(false);
          return;
      }

      if (optionOrder === 'email') {
        try {
            const sentSuccessfully = await sendOrderEmail(emailData);
            if (sentSuccessfully){
                toast.success('Email sent successfully');
            } else{
                toast.error('Failed to send email');
                return;
            }
            
        } catch (error) {
            console.error('Failed to send email:', error);
            toast.error('Failed to send email');
            return;
        }
      }

      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/update-order-option', {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              order_code: editedBundle.order_code,
              order_option: optionOrder
          }),
      });

      if (response.status === 200) {
          console.log("Order option updated successfully");
      } else {
          console.log("Error updating order option");
      }
  }

  if (dueDateOption === 'immediate') {
      dueDate = editedBundle.bundle_date;
  }

  if (editedBundleStatus === 'Shipped' || editedBundleStatus === 'Delivered' || editedBundle.bundle_status === 'Shipped') {
      if (!trackingNumber && (!eta ||  eta === '')) {
          alert('Enter a tracking number or an ETA.');
          setIsLoading(false);
          return;
      }
      updateTrackingNumber(editedBundle.order_code, trackingNumber);
  }

  if ((editedBundleStatus === 'Shipped' || editedBundleStatus === 'Delivered') && editedBundle.bundle_status !== 'Shipped') {
      if (dueDateOption === 'immediate') {
          await updateBundlePaidStatus(1, editedBundle.order_code);
      }
      if (!invoiceExist) {
          alert('Please upload the invoice.');
          setIsLoading(false);
          return;
      }
  }

  const response = await updateBundle(editedBundle.date, editedBundle.order_code, editedShippingPrice, editedTaxesPrice, editedDiscount, bundleComment, dueDate, dueDateOption, eta);

  if (response) {
    await processBundle(editedBundle, editedBundleStatus, selectedSupplierId, setTicketInfo, username);
  } else {
    setIsLoading(false);
    return;
  }

  // Close the modal
  await fetchTicketInfo(setTicketInfo, editedBundle.bundle_id);

  onClose();

  setIsLoading(false);
};

/**
* Processes each order in the bundle and updates their status if necessary.
*
* @param {Object} ticketInfo - Information about the ticket, including orders, bundle ID, location name, etc.
* @param {String} editedBundleStatus - The edited status of the bundle.
* @param {Object} selectedSupplier - The selected supplier with supplier_id.
* @param {Function} setTicketInfo - Function to update the state of ticket information.
* @param {String} username - The username of the person submitting the changes.
*/
export const processBundle = async (
  ticketInfo, editedBundleStatus, selectedSupplierId, setTicketInfo, username
) => {
  let editedOrder;

  for (const order of ticketInfo.orders) {
      if (order.status_cat !== 'Delivered' && editedBundleStatus !== 'Pending') {
          editedOrder = {
              ...order,
              status_cat: editedBundleStatus,
              supplier_id: selectedSupplierId
          };
          await handleEditSubmit(
              editedOrder,
              ticketInfo.orders,
              ticketInfo.bundle_id,
              ticketInfo.location_name,
              setTicketInfo,
              username
          );
      }
  }
};

/**
 * Handles the submission of the assignment form for a bundle.
 * 
 * @param {string} bundleID - The ID of the bundle being assigned.
 * @param {string} assignedTo - The name or ID of the person/entity to whom the bundle is assigned.
 * @param {string} assignmentComments - Comments or notes related to the assignment.
 * @param {function} setIsManageModalOpen - A function to toggle the state of the manage modal (open/closed).
 * @param {function} setAssignedTo - A function to update the 'assignedTo' state after submission.
 * @param {function} setAssignmentComments - A function to update the 'assignmentComments' state after submission.
 */
export const handleManageSubmit = async (bundleID, assignedTo, assignmentComments) => {
  try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/assign-bundle/${bundleID}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              assigned_to: assignedTo,
              assignment_comments: assignmentComments,
          }),
      });

      if (!response.ok) {
          throw new Error('Error assigning order');
      }

      console.log('Bundle assigned successfully');
  } catch (error) {
      console.error('Error assigning order:', error);
  }

  toast.success('Changes saved!');
};
