import React, { useEffect, useState, useRef } from 'react';
import { fetchOrderData, fetchInvoiceStatuses } from '../utils/locations/locationsUtils';
import PieChart from '../components/PieChart';
import TopBar from '../components/TopBar';
import OrdersPerLocation from '../features/dashboard/OrdersPerLocation';
import AvgOrderValuePerLocation from '../features/dashboard/AvgOrderValuePerLocation';
import TopProductsBySales from '../features/dashboard/TopProductsBySales';

const Home = () => {
  const [ordersData, setOrdersData] = useState([]);
  const [invoicesStatuses, setInvoicesStatuses] = useState([]);
  const contentRef = useRef();
  const orderStatuses = ['Placed', 'Open', 'Shipped', 'Confirmed', 'Backorder', 'In Review'];

  useEffect(() => {
    fetchOrderData((data) => {
      // Filter the orders to include only specific status categories
      const filteredData = data.filter(order => 
        orderStatuses.includes(order.status_cat) && !order.location_name.includes('Medvelle')
      );
      setOrdersData(filteredData);
    }, 'All Locations');

    fetchInvoiceStatuses(setInvoicesStatuses);

    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '49px';
  }, []);

  return (
    <div ref={contentRef}>
      <TopBar title={'Dashboard'} />

      <div className='pie-charts-container'>
        <div className='pie-chart-box'>
          <h2 style={{textAlign: 'center', marginBottom: '10px'}}>Current Orders</h2>
          <PieChart data={ordersData} labelKey="status_cat" title="Orders Status Breakdown" />
        </div>
        <div className='pie-chart-box'>
          <h2 style={{textAlign: 'center', marginBottom: '10px'}}>Current Invoices</h2>
          <PieChart data={invoicesStatuses} labelKey="invoice_status" title="Invoices Status Breakdown" />        
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{ width: '70%'}}>
          <OrdersPerLocation />
          <AvgOrderValuePerLocation />
          <TopProductsBySales />
        </div>

      </div>
    </div>
  );
};

export default Home;
