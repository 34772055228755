import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Link, Font } from '@react-pdf/renderer';
import logo from '../../assets/images/medvelle_blue.png';


// Register Lato fonts
Font.register({
  family: 'Lato',
  fonts: [
    { src: require('../../assets/fonts/Lato/Lato-Regular.ttf') }, // Regular
    { src: require('../../assets/fonts/Lato/Lato-Bold.ttf'), fontWeight: 'bold' }, // Bold
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: '10px',
    paddingTop: '30px',
    paddingRight: '10px',
    paddingBottom: '30px',
    backgroundColor: 'white',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 11,
    marginTop: '20px',
    marginLeft: '25px',
    lineHeight: 1.2, 
    fontWeight: 600,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 11,
    lineHeight: '1.2px',
    color: 'black',
    marginTop: '20px',
    marginLeft: '25px',
  },
  logo: {
    width: 180,
    height: 30,
    marginLeft: '31px',
  },
  table: {
    display: 'table',
    borderCollapse: 'separate',
    borderSpacing: 0,
    marginRight: '25px',
    marginLeft: '25px',
    marginTop: '40px', // This is 2X
  },
  tableRow: {
    flexDirection: 'row',
    textAlign: 'left',
  },
  tableCol: {
    width: '15%',
    borderBottom: '2px solid black',
    textAlign: 'left',
  },
  tableCol1: {
    width: '15%',
    textAlign: 'left',
  },
  tableCol2: {
    width: '30%',
    borderBottom: '2px solid black',
    textAlign: 'left',
  },
  tableCol3: {
    width: '30%',
    textAlign: 'left',
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    textAlign: 'left',
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '30px',
    marginLeft: '330px',
    textAlign: 'right',
    marginTop: '20px',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 12,
    marginBottom: '5px',
  },
  totalBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#f0f0f0',
    width: '225px',
    height: '35px',
    alignItems: 'center',
    marginLeft: '-5px',
    padding: 2,
    borderRadius: 0,
    marginTop: 4,
    fontSize: 12,
  },
  payButton: {
    marginTop: 20,
    padding: 8,
    width: '250px',
    backgroundColor: '#87D3D7',
    color: '#fff',
    textAlign: 'center',
    borderRadius: 20,
    marginLeft: '300px',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
  boldText: {
    fontWeight: 'bold',
    fontWeight: 600,
    textAlign: 'left',
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    fontSize: 11,
    marginLeft: '40px', // Aligned with '#'
    marginBottom: '30px',
    color: '#585858', // Darker grey color
    textAlign: 'left',
  },
});


// Create Document Component
const InvoicePDF = ({ invoice, bundles, ordersPerBundle, invoiceData, billingAddress, pricingStyle }) => {

  let totalAmount = 0;
  let subTotal = 0;
  let totalShipping = 0;
  let totalTaxes = 0;
  let medvelleNetworkDiscount = 0;
  let totalDiscounts = 0;
  let groupedBundles;



  const groupByProductCategoryFullPricing = (bundles) => {
    const groupedData = {};

    bundles.forEach(bundle => {
      const orders = ordersPerBundle[bundle.order_code] || [];
      orders.forEach(order => {
        if (!groupedData[order.product_cat]) {
          groupedData[order.product_cat] = {
            total_price: 0,
            orders: [],
          };
        }
        groupedData[order.product_cat].total_price += order.order_quantity * order.unit_price;
        groupedData[order.product_cat].orders.push(order);
        subTotal += order.order_quantity * order.unit_price;
      });

      totalShipping += bundle.shipping;
      totalTaxes += bundle.taxes;

    });

      // Filter out categories with total_price 0
      const filteredData = Object.keys(groupedData).reduce((acc, key) => {
        if (groupedData[key].total_price > 0) {
          acc[key] = groupedData[key];
        }
        return acc;
      }, {});

      return filteredData;

  };

  const groupByProductCategory = (invoiceData) => {
    const groupedData = {};

    invoiceData.forEach((item) => {
      const { 'Product Category': category, 'Order Quantity': quantity, 'Total Price': totalPrice } = item;

      if (!groupedData[category]) {
        groupedData[category] = {
          total_quantity: 0,
          total_price: 0,
          products: [],
        };
      }

      groupedData[category].total_quantity += quantity;
      groupedData[category].total_price += totalPrice;
      subTotal += totalPrice;
      groupedData[category].products.push(item);
    });

    // Filter out categories with total_price 0
    const filteredGroupedData = Object.keys(groupedData)
      .filter((category) => groupedData[category].total_price > 0)
      .reduce((acc, category) => {
        acc[category] = groupedData[category];
        return acc;
      }, {});

    return filteredGroupedData;
  };

  if(pricingStyle === 'average_pricing'){

    groupedBundles = groupByProductCategory(invoiceData);  

  } else{

    groupedBundles = groupByProductCategoryFullPricing(bundles);  
    
  }

  if (invoice.medvelle_network_discounts > 0) {
    medvelleNetworkDiscount = invoice.medvelle_network_discounts;
  }

  if (invoice.total_discount > 0) {
    totalDiscounts = Math.abs(invoice.total_discount);
  }

  totalAmount = subTotal + totalShipping + totalTaxes - totalDiscounts - medvelleNetworkDiscount;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '20px' }}>
          <Image style={styles.logo} src={logo} />
          <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: '30px', marginTop: '11px' }}>
            <Text style={{ fontSize: '20px', fontFamily: 'Lato', fontWeight: 'bold' }}>Invoice #: {(invoice.invoice_id)}</Text>
          </View>
        </View>
        <View style={styles.header}>
          <View style={{ textAlign: 'left', marginLeft: '5px' }}>
            <Text>Sent By:</Text>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>Medvelle LLC</Text>
            <Text>611 S DuPont Highway Suite 102</Text>
            <Text>Dover DE 19901</Text>
            <Text>accounting@medvelle.com</Text>
          </View>
          <View style={{ textAlign: 'right', marginRight: '30px', width: '218px' }}>
            <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <Text>Invoice date: </Text>
              <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>
                {new Date(invoice.invoice_date).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                  timeZone: 'UTC'
                })}
              </Text>
            </View>
            <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <Text>Due date: </Text>
              <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>
                {new Date(invoice.payment_date).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                  timeZone: 'UTC'
                })}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <View style={{ marginLeft: '5px' }}>
            <Text>Sent To:</Text>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>{billingAddress.group_name}</Text>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>{invoice.recipient}</Text>
            <Text>{billingAddress.billing_street}</Text>
            <Text>{billingAddress.billing_secondary}</Text>
            <Text>{billingAddress.billing_country} {billingAddress.billing_zip}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={[styles.tableRow, { backgroundColor: '#d3d3d3', fontFamily: 'Lato', fontWeight: 'bold' }]}>
            <View style={[styles.tableCol, { width: '5%' }]}><Text style={styles.tableCell}>#</Text></View>
            <View style={[styles.tableCol2, { width: '50%' }]}><Text style={styles.tableCell}>Product or Service</Text></View>
            <View style={styles.tableCol2}><Text style={styles.tableCell}>Description</Text></View>
            <View style={styles.tableCol}><Text style={[styles.tableCell, { textAlign: 'center' }]}>Qty</Text></View>
            <View style={styles.tableCol}><Text style={[styles.tableCell, { textAlign: 'right' }]}>Rate ($)</Text></View>
            <View style={styles.tableCol}><Text style={[styles.tableCell, { textAlign: 'right' }]}>Amount ($)</Text></View>
          </View>
          {Object.keys(groupedBundles).map((productCat, index) => (
            <View style={styles.tableRow} key={`${index}`}>
              <View style={[styles.tableCol1, { width: '5%' }]}><Text style={styles.tableCell}>{index + 1}</Text></View>
              <View style={[styles.tableCol3, { width: '50%' }]}><Text style={styles.tableCell}>{productCat} Orders</Text></View>
              <View style={styles.tableCol3}><Text style={styles.tableCell}>Product Reimbursement</Text></View>
              <View style={styles.tableCol1}><Text style={[styles.tableCell, { textAlign: 'center' }]}>1</Text></View>
              <View style={styles.tableCol1}>
                <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                {parseFloat(groupedBundles[productCat].total_price.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </Text>
              </View>
              <View style={styles.tableCol1}>
                <Text style={[styles.tableCell, { textAlign: 'right' }]}>
                {parseFloat(groupedBundles[productCat].total_price.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </Text>
              </View>
            </View>
            
          ))}
        </View>
        {pricingStyle === 'average_pricing' ?
        <View style={styles.totalContainer}>
          <View style={styles.totalRow}>
            <Text>Subtotal:</Text>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>$ {parseFloat((subTotal).toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
          </View>

          {medvelleNetworkDiscount > 0 && (
            <View style={styles.totalRow}>
              <Text>Credits:</Text>
              <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>-$ {parseFloat(medvelleNetworkDiscount.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>
          )}
          <View style={styles.totalBox}>
            <Text style={{ marginLeft: '3px' }}>Total:</Text>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'bold', marginRight: '2.5px' }}>$ {parseFloat((subTotal-medvelleNetworkDiscount).toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
          </View>
          {invoice.ach_link && (
            <View>
              <Text style={{ fontSize: '10', textAlign: 'right', marginTop: '10px', marginRight: '-2.5px', marginLeft: '-13px' }}>Please use this <Link src={invoice.ach_link}>LINK</Link> to securely process your payment. Thank you!
              </Text>
            </View>
          )}
        </View>
        : 
        <View style={styles.totalContainer}>
          <View style={styles.totalRow}>
            <Text>Subtotal:</Text>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>$ {parseFloat((subTotal).toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
          </View>
          {totalTaxes > 0 && (
            <View style={styles.totalRow}>
              <Text>Taxes:</Text>
              <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>$ {parseFloat(totalTaxes.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>
          )}
          {totalShipping > 0 && (
            <View style={styles.totalRow}>
              <Text>Shipping:</Text>
              <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>$ {parseFloat(totalShipping.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>
          )}
          {totalDiscounts !== 0 && (
            <View style={styles.totalRow}>
              <Text>Discounts:</Text>
              <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>-$ {parseFloat(totalDiscounts.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>
          )}
          {medvelleNetworkDiscount > 0 && (
            <View style={styles.totalRow}>
              <Text>Credits:</Text>
              <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>-$ {parseFloat(medvelleNetworkDiscount.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>
          )}
          <View style={styles.totalBox}>
            <Text style={{marginLeft: '3px'}}>Total:</Text>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'bold', marginRight: '2.5px' }}>$ {parseFloat((totalAmount).toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
          </View>
          {invoice.ach_link && (
            <View>
              <Text style={{ fontSize: '10', textAlign: 'right', marginTop: '10px', marginRight: '-2.5px', marginLeft: '-13px' }}>Please use this <Link src={invoice.ach_link}>LINK</Link> to securely process your payment. Thank you!
              </Text>
            </View>
          )}
        </View>
        }
        <View style={styles.footer}>
          <Text>medvelle.com | accounting@medvelle.com</Text>
        </View>
      </Page>
    </Document>
  );

  }

export default InvoicePDF;