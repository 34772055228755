import React, {useEffect, useState} from 'react';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {formatDateTimeText} from '../../utils/time_zones/TimeZones';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTableList} from '@fortawesome/free-solid-svg-icons';
import {fetchRecentSupportUpdates} from '../../utils/support/supportUtils';

/**
 * `SupportTrackingPreview` is a  component that displays a preview of support tracking details by ticket, 
 * user, and location. It includes functionality to manage the display of a tracking modal for additional details/
 *s
 * @param {Object} props - The component props.
 * @param {Object} props.ticket - The support ticket object containing details about the ticket.
 * @param {Function} props.setSelectedTicket - A function to set the currently selected ticket for tracking modal.
 * @param {string} props.selectedTab - The currently selected tab, which determines what content to display in the preview.
 *                                      Options include Ticket History, User History, and Location History.
 * @param {Function} props.setIsTrackingModalOpen - A function to control the visibility of the tracking modal.
 * 
 * @returns {JSX.Element} The rendered component displaying ticket details, support updates, and interactive controls.
 */
const SupportTrackingPreview = ({ticket, setSelectedTicket, selectedTab, setIsTrackingModalOpen}) => {
    const [recentSupportUpdates, setRecentSupportUpdates] = useState([]);   

    useEffect(() => {
        fetchRecentSupportUpdates(setRecentSupportUpdates, ticket, selectedTab);
    }, [selectedTab]);

    return (
        <div style={{
            height: '80%', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px', backgroundColor: '#f9f9f9'
        }}>
            {recentSupportUpdates && recentSupportUpdates.length > 0 ? (
                <div style={{
                    textAlign: 'right'  // Aligns text and inline-block elements to the right
                }}>
                    <button
                        className="support-button"
                        onClick={() => {
                            if (selectedTab === 'User History') {
                                setSelectedTicket({username: ticket.username});
                            }
                            else if (selectedTab === 'Location History') {
                                setSelectedTicket({location_name: ticket.location_name});
                            }
                            setIsTrackingModalOpen(true);
                        }}
                        style={{
                            position: 'sticky', // Distance from the right edge
                            top: '0px',
                            zIndex: 1000,
                            left: '0px',
                            borderRadius: '50%', 
                        }}
                    >
                        <FontAwesomeIcon icon={faTableList} />
                    </button>
                    <VerticalTimeline>
                        {recentSupportUpdates.map((item, index) => {
                            const paragraphs = item.action.split('\n');
                            return (
                                <VerticalTimelineElement
                                    iconOnClick={() => {
                                        if (selectedTab !== 'Ticket History') {
                                            setSelectedTicket({ticket_id: item.ticket_id});
                                            setIsTrackingModalOpen(true); 
                                        }
                                    }}
                                    key={index}
                                    contentStyle={{background: '#f0f0f0', color: '#000', fontSize: '10px'}}
                                    contentArrowStyle={{borderRight: '7px solid  #f0f0f0'}}
                                    date={<div style={{fontSize: '10px', textAlign: (index % 2 === 0 ? 'right' : 'left')}}>{formatDateTimeText(item.time)}</div>}
                                    iconStyle={{background: item.status_cat === 'Resolved' ? '#87D3D7' : '#F49C7D', color: '#000', cursor: selectedTab !== 'Ticket History' ? 'pointer' : ''}}
                                >
                                    {paragraphs.map((paragraph, index) => (
                                        <p style={{textAlign: 'left', marginTop: '5px', fontSize: '12px'}} key={index}>{paragraph}</p>
                                    ))}
                                    <p style={{textAlign: 'left', marginTop: '5px', fontSize: '12px'}}>
                                        User: {item.username}
                                    </p>
                                </VerticalTimelineElement>
                            );
                        })}
                    </VerticalTimeline>
                </div>

            ) : (
                <div style={{textAlign: 'center', fontStyle: 'italic', padding: '50px', height: '160px'}}>
                    No tracking data.
                </div>
            )}
        </div>
    );
};

export default SupportTrackingPreview;
