import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ErrorTable } from './ErrorTable';
import * as Data from '../../utils/mapping';
import { formatErrorDescription, getPercentageChange } from '../../utils/mapping/Format';
import ProductMapping from './ProductMapping';
import ServiceMapping from './ServiceMapping';
import BackArrow from '../../components/BackArrow';
import ServiceForm from './ServiceForm';

const MappingReview = ({ selectedLocation, serviceOptions, refreshKey }) => {
    const [errorData, setErrorData] = useState([]);
    const [unmappedProducts, setUnmappedProducts] = useState([]);
    const [unmappedServices, setUnmappedServices] = useState([]);
    const [mappingOutliers, setMappingOutliers] = useState([]);
    const [highlightedRows, setHighlightedRows] = useState([]);
    const [unknownServices, setUnknownServices] = useState([]);
    const [discontinuedProducts, setDiscontinuedProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState('main');
    const [selectedTimeframe, setSelectedTimeframe] = useState('');
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedService, setSelectedService] = useState({});
    const [newService, setNewService] = useState({});
    const [isAdding, setIsAdding] = useState(false);
    const [newServiceOptions, setNewServiceOptions] = useState([]);

    useEffect(() => {
        onBack();
        Data.fetchUnmappedProducts(selectedLocation, setUnmappedProducts);
        Data.fetchUnmappedServices(selectedLocation, setUnmappedServices);
        Data.fetchUnknownServices(selectedLocation, selectedTimeframe, setUnknownServices);
        Data.fetchDiscontinuedProducts(selectedLocation, setDiscontinuedProducts);
        Data.fetchMappingOutliers(selectedLocation, setMappingOutliers);
        Data.fetchServicesData({ selectedLocation, onlyNew: true, callback: setNewServiceOptions });
    }, [selectedLocation]);

    useEffect(() => {
        onBack();
    }, [refreshKey])

    useEffect(() => {
        Data.fetchUnknownServices(selectedLocation, selectedTimeframe, setUnknownServices);
    }, [selectedTimeframe]);

    useEffect(() => {
        const products = unmappedProducts.map(product => ({
            ...product,
            category: 'Error',
            sub_category: 'Unmapped Product',
            description: formatErrorDescription(product, 'Unmapped Product'),
            callBack: handleUnmappedProduct
        }));

        const services = unmappedServices.map(service => ({
            ...service,
            category: 'Error',
            sub_category: 'Unmapped Service',
            description: formatErrorDescription(service, 'Unmapped Service'),
            callBack: handleUnmappedService

        }));

        const services_unknown = unknownServices.map(service => ({
            ...service,
            category: 'Error',
            sub_category: 'Unknown Service',
            description: formatErrorDescription(service, 'Unknown Service'),
            callBack: handleUnknownService
        }));

        const discontinued_products = discontinuedProducts.map(product => ({
            ...product,
            category: 'Alert',
            sub_category: 'Discontinued Product',
            description: formatErrorDescription(product, 'Discontinued Product'),
            callBack: handleDiscontinuedProduct
        }));

        const outliers = mappingOutliers.filter(item => (item.origin.toLowerCase().includes('anomaly'))).map(service => ({
            ...service,
            category: 'Anomaly',
            sub_category: 'Outlier',
            description: formatErrorDescription(service, 'Outlier'),
            callBack: handleOutlier,
            confirm: async () => {
                const success = await Data.confirmMappingSpotcheck(service.product_id, selectedLocation, service.service_id);
                if (success) {
                    onSubmit();
                }
            }
        }));

        const manualGrouped = Object.values(mappingOutliers.filter(item => (item.origin.toLowerCase().includes('alert'))).reduce((acc, mapping) => {
            const product_id = mapping.product_id;

            // If the department doesn't exist in the accumulator, create an object for it
            if (!acc[product_id]) {
                acc[product_id] = {
                    product_id,
                    product_name: mapping.product_name,
                    product_description: mapping.product_description,
                    product_cat: mapping.product_cat,
                    product_unit: mapping.product_unit,
                    origin: mapping.origin ? mapping.origin : '',
                    spotcheck_status: mapping.spotcheck_status,
                    usage_quantity: mapping.usage_quantity,
                    service_ids: []
                };
            }


            // Push the current employee object with selected fields to the appropriate department group
            acc[product_id].service_ids.push(mapping.service_id);

            return acc;
        }, {}));

        const manual_mapping = manualGrouped.map(service => ({
            ...service,
            category: 'Alert',
            sub_category: 'Manual Order',
            description: formatErrorDescription(service, 'Manual Order'),
            callBack: handleManualOrder,
            confirm: async () => {
                const success = await Data.confirmMappingSpotcheck(service.product_id, selectedLocation);
                if (success) {
                    onSubmit()
                }
            }

        }));

        setErrorData(products.concat(services).concat(services_unknown).concat(discontinued_products).concat(manual_mapping).concat(outliers));
    }, [mappingOutliers, discontinuedProducts, unknownServices, unmappedServices, unmappedProducts]);



    const handleUnmappedProduct = (product) => {
        setCurrentPage('unmapped product');
        setSelectedProduct(product);
    };

    const handleUnmappedService = (service) => {
        setCurrentPage('unmapped service');
        setSelectedService(service);
    };
    const handleUnknownService = async (service) => {
        if (service.service_exists) {
            await Data.fetchServiceInfo(service.service_name, service.price, setNewService);
        }
        else {
            setNewService({ service_name: service.service_name });
        }
        setIsAdding(true);
        setCurrentPage('unknown service');
    };

    const handleManualOrder = (product) => {
        setSelectedProduct(product);
        setHighlightedRows(product.service_ids);
        setCurrentPage('manual order');
    };

    const handleDiscontinuedProduct = (product) => {
        setSelectedProduct(product);
        setCurrentPage('discontinued product');
    };

    const handleOutlier = (product) => {
        setSelectedProduct(product);
        setHighlightedRows([product.service_id]);
        setCurrentPage('outlier');
    }

    const onBack = () => {
        setCurrentPage('main');
        setSelectedProduct({});
        setSelectedService({});
        setNewService({});
        setIsAdding(false)
    };

    const refresh = async () => {
        if (currentPage === 'unmapped product') await Data.fetchUnmappedProducts(selectedLocation, setUnmappedProducts);
        else if (currentPage === 'unmapped service') await Data.fetchUnmappedServices(selectedLocation, setUnmappedServices);
        else if (currentPage === 'unknown service') await Data.fetchUnknownServices(selectedLocation, selectedTimeframe, setUnknownServices);
        else if (currentPage === 'outlier' || currentPage === 'manual order') Data.fetchMappingOutliers(selectedLocation, setMappingOutliers);
        else if (currentPage === 'discontinued product') Data.fetchDiscontinuedProducts(selectedLocation, setDiscontinuedProducts);
        else {
            Data.fetchMappingOutliers(selectedLocation, setMappingOutliers);
        };
    };

    const addService = async () => {
        const serviceId = await Data.addNewService(selectedService);
        if (serviceId) return serviceId;
        return false;
    };


    const onSubmit = async () => {
        if (currentPage === 'outlier' || currentPage === 'manual order') {
            selectedProduct.confirm();
        }
        await refresh();
        onBack();
    };

    return (
        <>
            <div style={{ display: currentPage != 'main' ? 'none' : '' }}>
                <ErrorTable errors={errorData} timeframeCallback={setSelectedTimeframe} />
            </div>

            {currentPage === 'unmapped product' && (
                <>
                    <ProductMapping selectedProduct={selectedProduct} selectedLocation={selectedLocation} onBack={onBack} onSubmit={onSubmit} />
                </>
            )}

            {currentPage === 'unmapped service' && (
                <>
                    <ServiceMapping selectedService={selectedService} selectedLocation={selectedLocation} onBack={onBack} onSubmit={onSubmit} />
                </>
            )}

            {currentPage === 'unknown service' && (
                <>
                    <span style={{ display: isAdding ? '' : 'none' }}>
                        <BackArrow onClick={onBack} />
                        <br />
                        <br />
                        <ServiceForm initialService={newService} setSelectedService={setSelectedService} setIsAdding={setIsAdding} serviceOptions={newServiceOptions} selectedLocation={selectedLocation} />
                    </span>

                    {/* A service has been selected for mapping. */}
                    {!isAdding && (
                        <ServiceMapping selectedService={selectedService} selectedLocation={selectedLocation} onBack={() => setIsAdding(true)} onSubmit={onSubmit} beforeOnSubmit={addService} />
                    )}
                </>
            )}

            {currentPage === 'discontinued product' && (
                <>
                    Remove from inventory?
                    <br />
                    <br />
                    <button
                        className='support-button'
                        style={{ marginRight: '20px' }}
                        onClick={async () => {
                            const result = await Data.handleDeleteProductInventory(selectedProduct.product_id, selectedLocation);
                            if (!result.ok) {
                                toast.error('Did not remove product successfully.');
                            }
                            onSubmit();
                        }}
                    >
                        Yes
                    </button>

                    <button
                        className='action-button-decline'
                        onClick={() => {
                            onBack();
                        }}
                    >
                        No
                    </button>
                </>
            )}

            {(currentPage === 'outlier' || currentPage === 'manual order') && (
                <>
                    <ProductMapping selectedProduct={selectedProduct} selectedLocation={selectedLocation} onBack={onBack} onSubmit={onSubmit} highlightedRows={highlightedRows} />
                </>
            )}

        </>
    );
};

export default MappingReview;