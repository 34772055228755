export const fetchUnmappedProducts = async (selectedLocation, callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-unmapped-products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location_name: selectedLocation })
        });

        if (response.ok) {
            const data = await response.json();
            callback(data);
            return data
        } else {
            console.error('Failed to fetch unmapped products:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

// Retrieves services without a product mapping.
export const fetchUnmappedServices = async (selectedLocation, callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-unmapped-services`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location_name: selectedLocation })
        });

        if (response.ok) {
            const data = await response.json();
            callback(data);
            return data
        } else {
            console.error('Failed to fetch unmapped services:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

// Retrieves unknown services from tracking error appointments table.
export const fetchUnknownServices = async (selectedLocation, timeFrame, callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-unknown-services`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location_name: selectedLocation, time_frame: timeFrame})
        });

        if (response.ok) {
            const data = await response.json();
            callback(data);
            return data
        } else {
            console.error('Failed to fetch unknown services:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const fetchDiscontinuedProducts = async (selectedLocation, callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-discontinued-products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location_name: selectedLocation })
        });

        if (response.ok) {
            const data = await response.json();
            callback(data);
            return data
        } else {
            console.error('Failed to fetch discontinued products:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

// Retrieves mapping outliers outliers.
export const fetchMappingOutliers = async (selectedLocation, callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-mapping-spotcheck`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ location_name: selectedLocation})
        });

        if (response.ok) {
            const data = await response.json();
            callback(data);
            return data
        } else {
            console.error('Failed to fetch unknown services:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

// Fetch suppliers data
export const fetchSuppliersData = async (callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-all-suppliers`);
        if (!response.ok) {
            throw new Error('Failed to fetch suppliers data');
        }
        const data = await response.json();
        callback(data);
        return data
    } catch (error) {
        console.error('Error fetching suppliers data:', error);
    }
};

// Function to fetch service mapping info from API
export const fetchServiceMappingInfo = async (selectedLocation, serviceId, callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-service-mapping-info`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ serviceId, locationName: selectedLocation })
        });
        const data = await response.json();
        callback(data);
        return data
    } catch (error) {
        console.error('Error fetching service mapping info:', error);
    }
};

// Function to fetch service mapping info from API
export const fetchServiceInfo = async (service_name, price, callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/services`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ service_name })
        });
        const data = await response.json();
        if (data.length > 0){
            const aggregated_data = data.reduce((result, obj) => {
                for (const [key, value] of Object.entries(obj)) {
                // Check if result[key] is empty or undefined before assigning the new value
                if ((result[key] === undefined || result[key] === '') && value !== '') {
                    result[key] = value;
                }
                }
                return result;
            }, {});
            if (price !== 0){
                aggregated_data.service_price = price
            }
            callback(aggregated_data);
        }
        else {
            callback({service_name: service_name, price:price});
        }
        return data;
    } catch (error) {
        console.error('Error fetching service info:', error);
    }
};

// Function to fetch service mapping info from API
export const fetchServiceMappingProductInfo = async (selectedLocation, productId, callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-product-mapping-info`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                productId: productId,
                locationName: selectedLocation
            })
        });
        const data = await response.json();
        callback(data) 
        return data
        
    } catch (error) {
        console.error('Error fetching service mapping info:', error);
    }
};

// Gets all locations
export const fetchLocations = (callback) => {
    fetch(`${ process.env.REACT_APP_BACKEND_URL }/locations`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            callback(data);
        })
        .catch(error => console.error('Error fetching locations data:', error));
};

export const fetchLastProductId = async (callback) => {
    try {

        // Make the PUT request to the server
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/last-product-id`);

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch last product ID');
        }

        // Parse the JSON response
        const data = await response.json();

        callback(data["lastProductId"]);

        // Return the services data
        return data["lastProductId"];
    } catch (error) {
        console.error('Error fetching services data:', error);
        return null; // Return null in case of error
    }
};

export const fetchLastServiceId = async (callback) => {
    try {

        // Make the PUT request to the server
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/last-service-id`);

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch last service ID');
        }

        // Parse the JSON response
        const data = await response.json();

        callback(data["lastServiceId"]);

        // Return the services data
        return data["lastServiceId"];
    } catch (error) {
        console.error('Error fetching last service ID:', error);
        return null; // Return null in case of error
    }
};

// Distinct = true then only fetch services not at current location.
export const fetchServicesData = async ({ selectedLocation, serviceName, onlyNew, callback }) => {
    try {
        // Define the data to be sent in the request body
        const requestData = {
            location_name: selectedLocation,
            service_name: serviceName,
            only_new: onlyNew
        };

        // Make the PUT request to the server
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/services`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch services data');
        }

        // Parse the JSON response
        const data = await response.json();
        callback(data);

        // Return the services data
        return data;
    } catch (error) {
        console.error('Error fetching services data:', error);
        return null; // Return null in case of error
    }
};

// Gets web product info.
export const fetchWebProducts = async (callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-web-products`); // Assuming your backend server is running on the same host
        if (!response.ok) {
            throw new Error('Failed to fetch web products');
        }
        const data = await response.json();

        callback(data);

        return data;
    } catch (error) {
        console.error('Error fetching web products:', error);
        return [];
    }
};

// Gets all products
export const fetchProducts = async (callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-products`); // Assuming your backend server is running on the same host
        if (!response.ok) {
            throw new Error('Failed to fetch products');
        }
        const data = await response.json();

        callback(data);

        return data;
    } catch (error) {
        console.error('Error fetching web products:', error);
        return [];
    }
};

// Define a function to fetch inventory data
export const fetchInventory = async (selectedLocation, callback) => {
    try {
        // Fetch data from the server
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-inventory`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ locationName: selectedLocation })
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch inventory data');
        }

        // Parse the JSON response
        const data = await response.json();
        callback(data);

        // Return the inventory data
        return data;
    } catch (error) {
        console.error('Error fetching inventory data:', error);
        return null; // Return null in case of error
    }
};

// /**
//  * Retrieves products and mappings for the selected service at all locations.
//  * @returns {Object[]} - The products and mappings for the selected service across all locations.
//  */
export const fetchCloneProductsData = async (serviceName, locationName, callback) => {
    try {
        // Define the data to be sent in the request body
        const requestData = {
            service_name: serviceName,
            location_name: locationName
        }

        // Make the PUT request to the server
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-service-products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });
        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch services data');
        }

        // Parse the JSON response
        const data = await response.json();
        callback(data)

        return data;
    } catch (error) {
        console.error('Error fetching services data:', error);
        
        return null; // Return null in case of error
    }
};

export const fetchCloneOptions = async ({ serviceName, productId, locationName, compareLocation, callback, showAll }) => {
    try {
        // Define the data to be sent in the request body
        if (serviceName) {
            const requestData = {
                service_name: serviceName,
                location_name: locationName,
                compare_location: compareLocation,
                show_all: showAll
            };

            // Make the PUT request to the server
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-service-products`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });
            // Check if the response is successful
            if (!response.ok) {
                throw new Error('Failed to fetch services data');
            }

            // Parse the JSON response
            const data = await response.json();
            callback(data);

            return data;
        }
        else if (productId) {
            const requestData = {
                product_id: productId,
                location_name: locationName,
                compare_location: compareLocation,
                show_all: showAll
            };

            // Make the PUT request to the server
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-product-services`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            // Check if the response is successful
            if (!response.ok) {
                throw new Error('Failed to fetch services data');
            }

            // Parse the JSON response
            const data = await response.json();
            callback(data);
            return data;
        }
    } catch (error) {
        console.error('Error fetching clone options', error);

        return null; // Return null in case of error
    }
};

/**
 * Retrieves services and mappings for the selected product at all locations.
 * @returns {Object[]} - The services and mappings for the selected product across all locations.
 */
export const fetchCloneServicesData = async (product_id, location_name, callback) => {
    try {
        // Define the data to be sent in the request body
        const requestData = {
            product_id: product_id,
            location_name: location_name
        };

        // Make the PUT request to the server
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-product-services`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch services data');
        }

        // Parse the JSON response
        const data = await response.json();
        callback(data)
        return data;
    } catch (error) {
        console.error('Error fetching services data:', error);
        return null; // Return null in case of error
    }
};

export const getPrice = async (product_id) => {
    try {
        const requestBody = JSON.stringify({ product_id });
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-price-product`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to update services mapping');
        }

        const data = await response.json();

        return data['priceInfo'];
    } catch (error) {
        console.error('Error updating services mapping:', error);
        return null;
    }
};

export const getInventoryWeb = async (product_id, location_name) => {
    try {
        const requestBody = JSON.stringify({ product_id, location_name });
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-inventory-product`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to get inventory product');
        }

        const data = await response.json();

        return data['inventoryInfo'];
    } catch (error) {
        console.error('Error getting inventory product:', error);
        return null;
    }
};

export const getCommonProducts = async (setCommonProducts, locationName) => {
    try {
        const requestBody = JSON.stringify({location_name: locationName});
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-common-products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to get common product');
        }

        const data = await response.json();
        setCommonProducts(data);
        return data;
    } catch (error) {
        console.error('Error getting common product:', error);
        return null;
    }
};

export const replaceProductOrders = async (original_product_id, replacement_product_id) => {
    try {
        const requestBody = JSON.stringify({original_product_id, replacement_product_id});
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/replace-product-orders`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to replace product.');
        }

        const data = await response.json();
        return true;
    } catch (error) {
        console.error('Error replacing product', error);
        return false;
    }
};

export const deleteProduct = async (product_id) => {
    try {
        const requestBody = JSON.stringify({product_id});
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/remove-product`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to delete product.');
        }

        const data = await response.json();
        return true;
    } catch (error) {
        console.error('Error deleting product', error);
        return false;
    }
};

// Function to fetch order spotcheck data
export const fetchOrderSpotcheck = async (selectedLocation, callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/spotcheck-order`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({location_name: selectedLocation})
        });
        const data = await response.json();
        callback(data);
        return data;
    } catch (error) {
        console.error('Error fetching order spotcheck:', error);
    }
};

// Function to fetch recent prices
export const fetchRecentPrices = async (selectedLocation, productId, callback) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/recent-order-prices`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({location_name: selectedLocation, product_id: productId})
        });
        const data = await response.json();
        callback(data);
        return data;
    } catch (error) {
        console.error('Error fetching order spotcheck:', error);
    }
};

export const updateOrders = async (orderId, updateValues) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/update-order/${ orderId }`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updateValues)
        });
        const data = await response.json();

        if (response.ok) {
            return true;
        } else {
            console.error('Error updating order:', data.error);
            // Handle specific error based on server response
            return false;
        }
    } catch (error) {
        console.error('Error updating orders', error);
        return false;
    }
};

export const updatePrice = async (priceData) => {
    try {
            // Call the API to update the product price and insert a price tracking record
        const updatePriceResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-product-price`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(priceData),
        });
        if (updatePriceResponse.ok) {
            return true;
        }
        else {
            return false;
        }

    }
    catch (error) {
        console.error('Error updating product price:', error);
        return false;
    }
};

// resolves mapping spotcheck for given productId and location name. ServiceId is optional for targetting specific mapping
export const confirmMappingSpotcheck = async (productId, locationName, serviceId) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/resolve-mapping-spotcheck`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({product_id: productId, location_name: locationName, service_id: serviceId})
        });
        const data = await response.json();

        if (response.ok) {
            return true;
        } else {
            console.error('Error updating mappings:', data.error);
            // Handle specific error based on server response
            return false;
        }
    } catch (error) {
        console.error('Error updating mappings', error);
        return false;
    }
};

export const submitMappingData = async ({ serviceMappings, locationName, username }) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/add-mapping-new`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ service_mappings: serviceMappings, location_name: locationName, username: username })
        });

        if (response.ok) {
            return true;
        } else {
            console.error('Error updating mappings:', data.error);
            // Handle specific error based on server response
            return false;
        }
    } catch (error) {
        console.error('Error updating mappings', error);
        return false;
    }

};


/**
 * @function fetchLastInternalServiceId
 * @description Fetch the last internal service ID from the server.
 * 
 * 
 * @returns {Number|null} Returns the last internal service ID if successful, or null in case of an error.
 * 
 * @throws {Error} Throws an error if the fetch request fails.
 */
export const fetchLastInternalServiceId = async () => {
    try {
        // Make the GET request to the server
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/last-internal-service-id`);

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch last service ID');
        }

        // Parse the JSON response
        const data = await response.json();

        // Return the last internal service ID
        return data["lastInternalServiceId"];
    } catch (error) {
        console.error('Error fetching last service ID:', error);
        return null; // Return null in case of error
    }
};


/**
 * @function fetchInternalServiceId
 * @description Fetch the internal service ID for a given service name from the server.
 * 
 * @param {String} serviceName The name of the service to fetch the internal service ID for.
 * 
 * @returns {Number|null} Returns the internal service ID if successful, or null in case of an error.
 * 
 * @throws {Error} Throws an error if the fetch request fails.
 */
export const fetchInternalServiceId = async (serviceName) => {
    try {
        // Fetch data from the server
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-internal-service-id`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ serviceName: serviceName })
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to fetch internal service id');
        }

        // Parse the JSON response
        const data = await response.json();

        // Return the internal service ID
        if (data.length > 0) {
            if (data[0].internal_service_id) {
                return data[0].internal_service_id;
            }
        }

        const responseLastId = await fetch(`${process.env.REACT_APP_BACKEND_URL}/last-internal-service-id`);

        // Check if the response is successful
        if (!responseLastId.ok) {
            throw new Error('Failed to fetch last service ID');
        }

        // Parse the JSON response
        const lastIdData = await responseLastId.json();

        // Return the last internal service ID
        return lastIdData["lastInternalServiceId"] + 1;

    } catch (error) {
        console.error('Error fetching internal service id data:', error);
        return null; // Return null in case of error
    }
};

export const deleteServiceMapping = async (serviceId, productId, username, locationName) => {
    try {
        // Fetch data from the server
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete-service-mapping`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ service_id: serviceId, product_id: productId, location_name: locationName, username })
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Failed to delete mapping');
        }
        // Return the last internal service ID
        return true;

    } catch (error) {
        console.error('Error deleting mapping:', error);
        return false; // Return null in case of error
    }
};

export const addNewService = async (service) => {
    try {
        // Fetch data from the server
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/add-new-service`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(service)
        });
        const serviceData = await response.json();
        // Parse the JSON response
        if (response.ok) {
            return serviceData.service_id;
        }
        else {
            throw new Error('Failed to add service');
        }

    } catch (error) {
        console.error('Error:', error);
        return false; // Return null in case of error
    }
};

export const addNewProduct = async (product, price, inventory) => {
    try {
        // Fetch data from the server
        const productResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/add-new-product`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(product)
        });
        const productData = await productResponse.json();

        // Parse the JSON response
        if (!productResponse.ok || !productData.product_id) {
            throw new Error('Failed to add product');
        }
        price.product_id = productData.product_id;
        inventory.product_id = productData.product_id

        const priceResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/add-new-price`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(price)
        });

        // Parse the JSON response
        if (!priceResponse.ok) {
            throw new Error('Failed to add price');
        }

        const inventoryResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/add-new-inventory`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inventory)
        });

        // Parse the JSON response
        if (!inventoryResponse.ok) {
            throw new Error('Failed to add inventory');
        }

        return productData.product_id;

    } catch (error) {
        console.error('Error:', error);
        return false; // Return null in case of error
    }
};

export const addNewInventory = async (inventory) => {
    try {
        const inventoryResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/add-new-inventory`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inventory)
        });

        // Parse the JSON response
        if (!inventoryResponse.ok) {
            throw new Error('Failed to add inventory');
        }
        return true;


    } catch (error) {
        console.error('Error:', error);
        return false; // Return null in case of error
    }
};

export const handleDeleteProductInventory = (productId, selectedLocation) => {
    return new Promise(async (resolve, reject) => {
        // Make PUT request to delete-service-mapping API
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete-inventory-product`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                product_id: productId,
                location_name: selectedLocation
            })
        })
            .then(response => {
                // Check if response is ok (status between 200 and 299)
                if (response.ok) {
                    return { 'response': response.json(), ok: response.ok }; // Parse response JSON
                } else {
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
            })
            .then(data => {
                resolve(data); // Resolve with response data

                // const updatedMappings = selectedServiceMapping[0].filter(mapping => mapping.product_id !== productId || mapping.service_id !== serviceId);
                // setSelectedServiceMapping([updatedMappings]);
            })
            .catch(error => {
                reject(error.message); // Reject with error message
            });
    });
};