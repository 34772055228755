import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { replyToEmail, fetchEmailConversation } from '../../utils/notifications/notificationsUtils';
import loadingSpinner from '../../assets/images/loading.gif';
import EmailTrackingModal from './EmailTrackingModal';
import ContentEditable from 'react-contenteditable';
import { toast } from 'react-toastify';
import { fetchTicketsInfo } from '../../utils/locations/locationsUtils';

const EmailPreview = ({ emailConversation, setEmailConversation, emailID, bundleID, setTickets, locationNameSelected }) => {
    const { body, from, sent_date, subject, message_id, to_recipients, cc_recipients } = emailConversation.lastMessage ?? {};
    const [replyMessage, setReplyMessage] = useState('');
    const [senderEmail, setSenderEmail] = useState('order@medvelle.com');
    const [recipientEmails, setRecipients] = useState(from === senderEmail ? to_recipients :[from]);
    const [ccEmails, setCCEmails] = useState(cc_recipients);
    const [isSendReplyLoading, setIsSendReplyLoading] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

    const handleReply = async () => {
        try {
            setIsSendReplyLoading(true);
            await replyToEmail(senderEmail, message_id, replyMessage, recipientEmails, ccEmails, bundleID);
            setIsSendReplyLoading(false);
            setReplyMessage('');
            await fetchEmailConversation(emailID, 'order@medvelle.com', setEmailConversation);
            await fetchTicketsInfo(setTickets, locationNameSelected);
            toast.success('Reply sent successfully!');

        } catch (error) {
            console.error('Failed to send reply:', error);
            toast.error('Failed to send reply.');
            setIsSendReplyLoading(false);
        }
    };

    const parseEmails = (input) => {
        // Split the string by commas or comma followed by spaces
        const emailArray = input.split(/,\s*/);
        // Trim whitespace from each email
        return emailArray.map(email => email.trim());
    };

    return (
        <div>
            <text><strong>Subject:</strong> {subject}</text>
            <br/>
            <text><strong>From:</strong> {from}</text>
            <br/>
            <strong>To:</strong> {to_recipients?.join(', ')}
            <br />
            <strong>CC:</strong> {cc_recipients?.join(', ')}
            <br />
            <text><strong>Last Message Date:</strong> {new Date(sent_date).toLocaleString()}</text>
            <div
                style={{
                    border: '2px solid #87D3D7',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    maxHeight: '80px',
                    overflowY: 'auto',
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                    backgroundColor: 'white',
                    margin: '0 auto'
                }}
            >
                <div
                    className="email-body"
                    dangerouslySetInnerHTML={{ __html: body }}
                    style={{
                        marginTop: '20px',
                        maxHeight: '100%'
                    }}
                />
            </div>
            <div>
                <div
                    style={{
                        marginTop: '10px',
                    }}
                >
                    <label htmlFor="senderEmail">Sender Email:</label>
                    <input
                        type="email"
                        id="senderEmail"
                        value={senderEmail}
                        onChange={(e) => setSenderEmail(parseEmails(e.target.value))}
                        style={{ width: '100%' }}
                    />
                    <label htmlFor="recipientEmails">Recipient Email(s):</label>
                    <input
                        type="email"
                        id="recipientEmails"
                        value={recipientEmails}
                        onChange={(e) => setRecipients(parseEmails(e.target.value))}
                        style={{ width: '100%' }}
                    />
                    <label htmlFor="ccEmails">CC Email(s):</label>
                    <input
                        type="email"
                        id="ccEmails"
                        value={ccEmails}
                        onChange={(e) => setCCEmails(parseEmails(e.target.value))}
                        style={{ width: '100%' }}
                    />

                    <label htmlFor="reply" style={{ marginRight: '10px' }}>Reply:</label>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <ContentEditable
                            html={replyMessage}
                            onChange={(e) => setReplyMessage(e.target.value)}
                            style={{
                                width: '100%',
                                maxHeight: '120px',
                                padding: '5px',
                                overflowY: 'auto',
                                backgroundColor: '#fff',
                                marginRight: '10px',
                                marginBottom: '10px'
                            }}
                        />
                        {isSendReplyLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                <img
                                    src={loadingSpinner}
                                    alt="loading-spinner"
                                    style={{
                                        height: '20px',
                                        marginRight: '10px',
                                        padding: '10px'
                                    }}
                                />
                            </div>
                        ) : (
                            <button
                                className="button-style"
                                onClick={handleReply}
                                style={{marginRight: '10px'}}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                        )}
                        <button 
                            className="button-style"
                            onClick={async() => {
                                setIsEmailModalOpen(true);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <FontAwesomeIcon icon={faAngleDoubleDown} />
                        </button>
                    </div>
                </div>
            </div>
            
            <EmailTrackingModal 
                isOpen={isEmailModalOpen} 
                onClose={() => setIsEmailModalOpen(false)} 
                emailConversation={emailConversation} 
                setEmailConversation={setEmailConversation} 
                emailID={emailID} 
                bundleID={bundleID}
                setTickets={setTickets}
            />
            
        </div>
    );
};

export default EmailPreview;
