import React, { useState } from 'react';
import OperationsModal from './OperationsModal';
import { fetchTicketInfo, fetchTicketsInfo, GetStatusColor } from '../../utils/locations/locationsUtils';
import { formatDateToUTCString, formatDateTimeText, formatToUTC } from '../../utils/time_zones/TimeZones';
import { updateEmailReadStatus } from '../../utils/notifications/notificationsUtils';
import { updateOrdersCode } from '../../utils/procurement/procurementUtils';
import loadingSpinner from '../../assets/images/loading.gif';
import ManageModal from './ManageModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faTrash } from '@fortawesome/free-solid-svg-icons';

const TicketTable = ({ tickets, userList, setTickets, locationName, suppliers, selectedOrders, setSelectedOrders }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ticketInfo, setTicketInfo] = useState(null);
    const [isBundleLoading, setIsBundleLoading] = useState(null);
    const [isMergeLoading, setIsMergeLoading] = useState(false);
    const [isManageModalOpen, setIsManageModalOpen] = useState(false);
    const [bundleID, setBundleID] = useState(null);
    const [expandedBundles, setExpandedBundles] = useState([]);

    const handleStartClick = async (ticket) => {
        setIsBundleLoading(ticket.bundle_id);
        
        await fetchTicketInfo(setTicketInfo, ticket.bundle_id);
        
        setIsBundleLoading(null);
        setIsModalOpen(true);
    };

    const handleBundleClick = (bundle) => {
        if (expandedBundles.includes(bundle.bundle_id)) {
            // Deselect orders in the bundle based on order_id
            const deselectedOrders = selectedOrders.filter(
                (order) => !bundle.orders.some(bundleOrder => bundleOrder.order_id === order.order_id)
            );
    
            setSelectedOrders(deselectedOrders);
    
            // Remove the bundle from the list of expanded bundles
            setExpandedBundles(expandedBundles.filter(id => id !== bundle.bundle_id));
        } else {
            // If the bundle is not expanded, add it to the list of expanded bundles
            setExpandedBundles([...expandedBundles, bundle.bundle_id]);
        }
    };

    // Function to handle order checkbox change
    const handleOrderCheckboxChange = (object, option) => {
        if (option === 'bundle') {
            const orders = object;

            // Check if all orders in the bundle are already selected based on order_id
            const allOrdersSelected = orders.every(order =>
                selectedOrders.some(selectedOrder => selectedOrder.order_id === order.order_id)
            );

            if (allOrdersSelected) {
                // If all orders are selected, remove them from selectedOrders based on order_id
                setSelectedOrders(
                    selectedOrders.filter(
                        selectedOrder => !orders.some(order => order.order_id === selectedOrder.order_id)
                    )
                );
            } else {
                // If not all orders are selected, add missing orders to selectedOrders based on order_id
                const updatedOrders = [...selectedOrders];
                orders.forEach(order => {
                    if (!updatedOrders.some(selectedOrder => selectedOrder.order_id === order.order_id)) {
                        updatedOrders.push(order);
                    }
                });
                setSelectedOrders(updatedOrders);
            }
        } else {
            const order = object;

            // Toggle individual order selection based on order_id
            if (selectedOrders.some(selectedOrder => selectedOrder.order_id === order.order_id)) {
                setSelectedOrders(
                    selectedOrders.filter(selectedOrder => selectedOrder.order_id !== order.order_id)
                );
            } else {
                setSelectedOrders([...selectedOrders, order]);
            }
        }
    };

    const getNewBundleDate = (orders) => {
        // Extract order_date values from all objects in the bundle
        const allOrderDates = orders.map(order => order.order_date);
        
        // Find the maximum date from the array of order dates
        const maxDate = new Date(Math.max(...allOrderDates.map(date => new Date(date))));

        return maxDate;
    };

    const checkOneLocation = (orders) => {
    
        const firstLocationName = orders[0].location_name;
    
        return orders.every(order => order.location_name === firstLocationName);
    };

    // Function to handle Apply button click
    const mergeOrders = async () => {

        setIsMergeLoading(true);

        let orderCodeToSend;

        if (selectedOrders.length === 0){
            alert("Select the orders you want to merge.");
            setIsMergeLoading(false);
            return;
        }

        if (!checkOneLocation(selectedOrders)){
            alert("You can only merge orders from the same location.");
            setIsMergeLoading(false);
            return;
        }

        try {
            // Fetch the next order code from the API
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get_max_order_code');
            
            if (!response.ok) {
                setIsMergeLoading(false);
                throw new Error('Network response was not ok');
            }
        
            const data = await response.json();
            
            // Use the returned next order code
            orderCodeToSend = data.next_order_code;
    
        } catch (error) {
            console.error('Error fetching max order code:', error);
        
            // If there's an error, fallback to order code 1
            orderCodeToSend = 1;
        }

        await fetch(process.env.REACT_APP_BACKEND_URL + '/create-bundle', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                orderCode: orderCodeToSend, // Example order code
                shipping: 0, // Example shipping
                taxes: 0, // Example taxes
                discount: 0,
                bundleDate: formatDateToUTCString(getNewBundleDate(selectedOrders)).split('T')[0].split(' ')[0],
                dueDate: formatDateToUTCString(getNewBundleDate(selectedOrders)).split('T')[0].split(' ')[0]
            })
            })
            .then(response => {
                if (response.ok) {
                    console.log('Bundle updated successfully');
                } else {
                    console.error('Failed to update bundle');
                }
            })
            .catch(error => {
                console.error('Error updating bundle:', error);
                setIsMergeLoading(false);
            });

        await updateOrdersCode(orderCodeToSend, selectedOrders);

        await fetchTicketsInfo(setTickets, locationName);
    
        // Clear selected orders and order code
        setSelectedOrders([]);

        setIsMergeLoading(false);
    
    };

     // Function to get the appropriate content based on the order code
    function renderBundleID(codeID) {
        switch (codeID) {
        case 0:
            return <FontAwesomeIcon icon={faTrash} />;
        case -1:
            return 'Recommended';
        case -2:
            return 'Free Products';
        case -3:
            return 'Declined';
        case -4:
            return 'Web';
        case -5:
            return 'Rewards';
        case -999:
            return 'Errors';
        default:
            return null; // Or some default content if necessary
        }
    }

    function sortTicketsByBundleId(tickets) {
        return tickets.sort((a, b) => {
          if (a.bundle_id > 0 && b.bundle_id <= 0) {
            return -1; // a comes before b
          }
          if (a.bundle_id <= 0 && b.bundle_id > 0) {
            return 1; // b comes before a
          }
          if (a.bundle_id <= 0 && b.bundle_id <= 0) {
            return a.bundle_id - b.bundle_id; // Sort in increasing order for bundle_id <= 0
          }
          return 0; // Preserve original order for other elements
        });
    } 

    return (

        <div>
            {isMergeLoading ? (
                    <img
                        src={loadingSpinner}
                        alt="loading-spinner"
                        style={{
                        height: '20px',
                        marginBottom: '20px',
                    }} />
                ) : (
                    <button 
                        className="button-style" 
                        style={{cursor: 'pointer', marginBottom: '20px'}} 
                        onClick={mergeOrders}
                    >
                        Merge
                    </button>
            )}
            
            <div style={{ border: '2px solid #87D3D7', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%', height: '610px', overflow: 'auto' }}>
                <table style={{  
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0, 
                    position: 'relative'
                }}>
                    <thead style={{
                        position: 'sticky', // Stick the header
                        top: 0, // Stick to the top of the table
                    }}>
                        <tr style={{ fontSize: '12px', backgroundColor: 'white'  }}>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}></th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Select</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Bundle ID</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Date</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Importance</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Location Name</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Status</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Last Update</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Status Update</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Bundle Comment</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Supplier</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Total Price</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>ETA</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Tracking Number</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Assigned To</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Assignement Comment</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Action</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tickets && sortTicketsByBundleId(tickets).map((ticket, index) => (
                            <>
                                {ticket.bundle_id > 0 ? (
                                    <tr key={index} style={{ fontSize: '13px', backgroundColor: ticket.flag ? '#e9453b' : (index % 2 === 0 ? '#ebf4f5' : '#ffffff') }} >
                                        <td 
                                            onClick={() => handleBundleClick(ticket)} 
                                            style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center', cursor: 'pointer'  }}
                                        >
                                            {expandedBundles.includes(ticket.bundle_id) ? 
                                                <FontAwesomeIcon icon={faAngleUp} /> :
                                                <FontAwesomeIcon icon={faAngleDown} />
                                            }
                                        </td>
                                        <td 
                                            style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center' }}
                                        >
                                            {(ticket.bundle_status !== 'Delivered' && ticket.bundle_status !== 'Shipped' && ticket.bundle_status !== 'Moved') ? (
                                                <input
                                                    type="checkbox"
                                                    checked={ticket.orders.every(order => selectedOrders.some(selectedOrder => selectedOrder.order_id === order.order_id))}
                                                    onChange={() => handleOrderCheckboxChange(ticket.orders, 'bundle')}
                                                    style={{ width: '20px', height: '20px' }}
                                                />
                                            )
                                                :
                                                (
                                                    <span></span>
                                                )
                                            }
                                        </td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.bundle_id}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.date && formatDateTimeText(ticket.date)}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center'}}>{ticket.minImportanceScore}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.location_name}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>
                                            <div style={{ textAlign: 'center', backgroundColor: GetStatusColor(ticket.bundle_status), borderRadius: '10px', padding: '5px', color: 'white' }}>
                                                {ticket.bundle_status}
                                            </div>
                                        </td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.last_update_time  && `${formatDateTimeText(formatToUTC(ticket.last_update_time))} by ${ticket?.last_update_user} from ${ticket?.status_cat_before} to ${ticket?.status_cat_after}`}</td> 
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.first_status_update_time  && `In ${ticket?.status_cat_after} since ${formatDateTimeText(formatToUTC(ticket.first_status_update_time))}`}</td> 
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.bundle_comment}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{suppliers.find(item => item.supplier_id === ticket.bundle_supplier_id)?.supplier_name || 'Unknown Supplier'}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center'}}>${(ticket?.total_price + ticket?.shipping + ticket?.taxes - ticket?.discount).toLocaleString()}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.eta && formatDateTimeText(ticket.eta)}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.tracking_number}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.assigned_to}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>{ticket.assignment_comment}</td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center'}}>
                                            {isBundleLoading === ticket.bundle_id ? (
                                                <img
                                                    src={loadingSpinner}
                                                    alt="loading-spinner"
                                                    style={{
                                                    height: '20px',
                                                    marginRight: '10px',
                                                }} />
                                                ) : (
                                                <button 
                                                    style={{ padding: '5px 10px', backgroundColor: '#87D3D7', border: 'none', borderRadius: '4px', color: 'white', cursor: 'pointer' }} 
                                                    onClick={async () => {
                                                        handleStartClick(ticket);
                                                        await updateEmailReadStatus(ticket.bundle_id);
                                                        await fetchTicketsInfo(setTickets, locationName);
                                                    }}  
                                                >
                                                    Start
                                                </button>
                                            )}
                                    
                                        </td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center'}}>
                                            <button 
                                                style={{ padding: '5px 10px', backgroundColor: '#87D3D7', border: 'none', borderRadius: '4px', color: 'white', cursor: 'pointer' }} 
                                                onClick={() => { setBundleID(ticket.bundle_id); setIsManageModalOpen(true); }}
                                            >
                                                Assign
                                            </button>
                                        </td>
                                    </tr>
                                    ) : (
                                    <tr key={index} style={{ fontSize: '13px', backgroundColor: ticket.flag ? '#e9453b' : (index % 2 === 0 ? '#ebf4f5' : '#ffffff') }} >
                                        <td 
                                            onClick={() => handleBundleClick(ticket)} 
                                            style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center', cursor: 'pointer'  }}
                                        >
                                            {expandedBundles.includes(ticket.bundle_id) ? 
                                                <FontAwesomeIcon icon={faAngleUp} /> :
                                                <FontAwesomeIcon icon={faAngleDown} />
                                            }
                                        </td>  
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}></td>                                      
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}>
                                            {renderBundleID(ticket.bundle_id)}
                                        </td>
                                        <td colSpan="13" style={{ borderBottom:'1px solid #ddd', padding: '10px'}}></td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px', textAlign: 'center'}}>
                                            {isBundleLoading === ticket.bundle_id ? (
                                                <img
                                                    src={loadingSpinner}
                                                    alt="loading-spinner"
                                                    style={{
                                                    height: '20px',
                                                    marginRight: '10px',
                                                }} />
                                                ) : (
                                                <button 
                                                    style={{ padding: '5px 10px', backgroundColor: '#87D3D7', border: 'none', borderRadius: '4px', color: 'white', cursor: 'pointer' }} 
                                                    onClick={() => handleStartClick(ticket)}
                                                >
                                                    Start
                                                </button>
                                            )}
                                    
                                        </td>
                                        <td style={{ borderBottom:'1px solid #ddd', padding: '10px'}}></td>
                                    </tr>
                                    )
                                }
                                {expandedBundles.includes(ticket.bundle_id) &&  
                                    <tr>
                                        {/* Render order details */}
                                        <td colSpan="1"></td>
                                        <td colSpan="17">
                                            <div style={{ border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden' }}>
                                            <table style={{  
                                                width: '100%', 
                                                borderCollapse: 'separate', 
                                                borderSpacing: 0
                                            }}>
                                                <thead style={{
                                                    top: 0
                                                }}>
                                                <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Select</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Date</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Location Name</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Name</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Description</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Manufacturer</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px', textAlign: 'center' }}>Unit Price</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px', textAlign: 'center' }}>Order Quantity</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px', textAlign: 'center' }}>Order Price</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px', textAlign: 'center' }}>Inventory Quantity</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px', textAlign: 'center' }}>Weekly Usage</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Internal Description</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Client Comment</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Comment</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Status</th>
                                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Urgency</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {ticket.orders.map((order, orderIndex) => (
                                                        <tr key={orderIndex} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: orderIndex % 2 === 0 ? '#ebf4f5' : '#ffffff'  }}>
                                                            <td style={{ padding: '10px' }}>
                                                            {(ticket.status_cat != 'Delivered' && ticket.status_cat != 'Shipped') ? (
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedOrders.some(selectedOrder => selectedOrder.order_id === order.order_id)}
                                                                    onChange={() => handleOrderCheckboxChange(order, 'order')}
                                                                    style={{ width: '20px', height: '20px', alignItems: 'center' }}
                                                                />
                                                                )
                                                                :
                                                                (
                                                                <span></span>
                                                                )
                                                            }
                                                            </td>
                                                            <td style={{ padding: '10px' }}>{order.order_date && formatDateTimeText(order.order_date)}</td>
                                                            <td style={{ padding: '10px' }}>{order.location_name}</td>
                                                            <td style={{ padding: '10px' }}>{order.product_name}</td>
                                                            <td style={{ padding: '10px' }}>{order.product_description}</td>
                                                            <td style={{ padding: '10px' }}>{order.manufacturer_name}</td>
                                                            <td style={{ padding: '10px', textAlign: 'center' }}>${order.unit_price}</td>
                                                            <td style={{ padding: '10px', textAlign: 'center' }}>{order.order_quantity}</td>
                                                            <td style={{ padding: '10px', textAlign: 'center' }}>${parseFloat(order.order_quantity * order.unit_price).toFixed(2)}</td>
                                                            <td style={{ padding: '10px', textAlign: 'center' }}>{(order.inventory_quantity ?? 0).toFixed(2)}</td>
                                                            <td style={{ padding: '10px', textAlign: 'center' }}>{(order.usage_forecast ?? 0).toFixed(2)}</td>
                                                            <td style={{ padding: '10px' }}>{order.internal_description}</td>
                                                            <td style={{ padding: '10px' }}>{order.client_comment}</td>
                                                            <td style={{ padding: '10px' }}>{order.order_comment}</td>
                                                            <td style={{ padding: '10px' }}>{order.status_cat}</td>
                                                            <td style={{ padding: '10px' }}>{order.order_urgency}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </>
                        ))}
                    </tbody>
                </table>
                {ticketInfo && <OperationsModal isOpen={isModalOpen} onClose={() => {setIsModalOpen(false); setTicketInfo(null);}} ticketInfo={ticketInfo} setTicketInfo={setTicketInfo} setTickets={setTickets} locationName={locationName} />}
                {bundleID && <ManageModal bundleID={bundleID} isOpen={isManageModalOpen} onClose={() => { setIsManageModalOpen(false); setBundleID(null); }} userList={userList} setTickets={setTickets} locationName={locationName} />}
            </div>
        </div>
    );
};

export default TicketTable;
