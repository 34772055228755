import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faDownload} from '@fortawesome/free-solid-svg-icons';
import {downloadBlob} from '../../utils/support/supportUtils';
import loadingSpinner from '../../assets/images/loading.gif';
import Modal from 'react-modal';

/**
 * AttachmentModal component is responsible for displaying a modal with an attachment preview 
 * (either an image or a PDF) and provides download functionality. 
 * It also handles the blob creation and cleanup.
 * 
 * @param {Blob} blob - The blob data for the attachment (image or PDF).
 * @param {string} name - The name of the attachment.
 * @param {string} type - The MIME type of the attachment (e.g., 'image/jpeg', 'application/pdf').
 * @param {boolean} isOpen - Controls whether the modal is open or closed.
 * @param {Function} setIsOpen - Function to toggle the modal open/close state.
 */
const AttachmentModal = ({blob, name, type, isOpen, setIsOpen}) => {
// State to hold the URL for the blob that can be used as the source in the modal.
    const [attachmentSrc, setAttachmentSrc] = useState(null);

    // Effect to clean up the blob URL when the component unmounts or when attachmentSrc changes.
    useEffect(() => {
        return () => {
            if (attachmentSrc) {
                // Revoke the object URL to release memory.
                URL.revokeObjectURL(attachmentSrc);
            }
        };
    }, [attachmentSrc]);

    // Effect to create a URL from the blob when the component receives a new blob.
    useEffect(() => {
        if (blob) {
            // Create a URL for the blob and set it as the source.
            const url = window.URL.createObjectURL(blob);
            setAttachmentSrc(url);
        }
    }, [blob]);

    // Handles closing the modal.
    const handleClose = () => {
        setIsOpen(!isOpen);
    };

    // Handles downloading the attachment using the downloadBlob utility function.
    const handleDownload = () => {
        downloadBlob(blob, name);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            className="modal-content"
            overlayClassName="modal-overlay"
            backgroundColor='white'
            contentLabel="Support Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2,
                },
                content: {
                    backgroundColor: 'white',
                    width: '70vw',  // Adjusts width based on content
                    height: '90%',
                    padding: '10px',
                    overflow: 'auto',
                }
            }}
        >
            {/* Modal header with download and close buttons */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                overflow: 'auto',
                zIndex: 2,
                position: 'sticky',
                top: '0px',
                right: '0px'
            }}>
                {/* Download button */}
                <button className="support-button" style={{marginLeft: '10px'}} onClick={handleDownload}>
                    <FontAwesomeIcon icon={faDownload} />
                </button>

                {/* Attachment name */}
                <h2>{name}</h2>

                {/* Close button */}
                <button className="support-button" style={{
                    marginRight: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 'medium'
                }} onClick={handleClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>

            {/* Render the attachment based on its type */}
            {attachmentSrc ? (
                <div style={{
                    width: '90%',
                    height: '90%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 'auto',
                }}>
                    {/* If the attachment is an image */}
                    {type === 'image/jpeg' && (
                        <img
                            src={attachmentSrc}
                            alt="Fetched Blob"
                            style={{
                                objectFit: 'cover',
                                transition: 'transform 0.3s ease-in-out',
                                position: 'relative',
                                margin: 'auto',
                                height: '100%',
                                overflow: 'auto',
                                transformOrigin: 'bottom left',
                                zIndex: 0,
                            }}
                            className={`image-button__img image`}
                        />
                    )}

                    {/* If the attachment is a PDF */}
                    {type === 'application/pdf' && (
                        <iframe
                            src={attachmentSrc}
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                transition: 'transform 0.3s ease-in-out',
                                cursor: 'pointer',
                            }}
                            title="Clickable iframe"
                        />
                    )}
                </div>
            ) : (
                // Loading spinner when the attachment is being fetched
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '20px'
                }}>
                    <img
                        src={loadingSpinner}
                        alt="loading-spinner"
                        style={{
                            height: '20px',
                            marginRight: '10px',
                            padding: '10px'
                        }}
                    />
                </div>
            )}
        </Modal>
    );
};

export default AttachmentModal;
