import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

// Register necessary Chart.js components
Chart.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data, labelKey }) => {
  // Function to generate dynamic colors
  const generateColor = (index, totalCategories) => {
    const baseColor = { r: 113, g: 177, b: 217 }; // Starting color (e.g., #71B1D9)
    const colorStep = 360 / totalCategories; // Adjust the color based on the total number of categories
    
    // Calculate the hue shift
    const hueShift = index * colorStep;

    // Convert base RGB to HSL
    let { h, s, l } = rgbToHsl(baseColor.r, baseColor.g, baseColor.b);
    
    // Apply hue shift
    h = (h + hueShift) % 360;

    // Convert back to RGB
    const { r, g, b } = hslToRgb(h, s, l);

    return `rgb(${r}, ${g}, ${b})`;
  };

  // RGB to HSL conversion
  const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // Achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        default: break;
      }

      h /= 6;
    }

    return { h: h * 360, s: s, l: l };
  };

  // HSL to RGB conversion
  const hslToRgb = (h, s, l) => {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // Achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h / 360 + 1 / 3);
      g = hue2rgb(p, q, h / 360);
      b = hue2rgb(p, q, h / 360 - 1 / 3);
    }

    return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
  };

  // Get distinct labels based on the provided key
  const distinctLabels = [...new Set(data.map(item => item[labelKey]))];

  // Prepare the pie chart data
  const pieChartData = {
    labels: distinctLabels,
    datasets: [
      {
        label: '# of Entries',
        data: distinctLabels.map(label =>
          data.filter(item => item[labelKey] === label).length
        ),
        backgroundColor: distinctLabels.map((label, index) =>
          generateColor(index, distinctLabels.length)
        ),
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <Doughnut data={pieChartData} />
    </div>
  );
};

export default PieChart;
