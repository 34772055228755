import React, { useState, useEffect } from 'react';
import { fetchSupplierPerManufacturer, fetchSuppliers, fetchSuppliersData, fetchTicketsInfo } from '../../utils/locations/locationsUtils';
import { emailTemplates, tableOrders } from '../../utils/notifications/emailTemplates';
import { handleBundleSubmit } from '../../utils/procurement/procurementUtils';
import { formatDateToUTCString, formatDateToLocalString } from '../../utils/time_zones/TimeZones';

import ContentEditable from 'react-contenteditable';

import loadingSpinner from '../../assets/images/loading.gif';

const OpenFlow = ({locationName, ticketInfo, setTicketInfo, username, setTickets, onClose, locationNameSelected}) => {
    const [suppliers, setSuppliers] = useState([]);
    const [suppliersManufacturer, setSuppliersManufacturer] = useState([]);
    const channels = ['Platform', 'Phone', 'Email'];
    const [selectedSupplier, setSelectedSupplier] = useState({'supplier_id': 0});
    const [selectedChannel, setSelectedChannel] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('Standard Order Version 1');
    const [emailData, setEmailData] = useState({ sender_email: 'order@medvelle.com', recipient_emails: [''], cc_emails: [''], subject: '', message: '', bundle_id: '' });
    const [eta, setEta] = useState('');
    const [comment, setComment] = useState('');
    const [subtotal, setSubtotal] = useState(0);
    const [taxes, setTaxes] = useState('');
    const [shipping, setShipping] = useState(ticketInfo.shipping);
    const [discount, setDiscount] = useState(ticketInfo.discount);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showAllSuppliers, setShowAllSuppliers] = useState(false); // Toggle state

    // const loadSuppliers = async () => {
    //     const supplier_data = await fetchSuppliers(ticketInfo.location_name, 'All');
    //     setSuppliers(supplier_data);
    // };

    useEffect(() => {

        fetchSupplierPerManufacturer(ticketInfo.location_name, ticketInfo.bundle_manufacturer_name, setSuppliersManufacturer);
        fetchSuppliersData(setSuppliers);

    }, [locationName]);

    useEffect(() => {

        updateBundleInfo();
    
    }, [ticketInfo, suppliers]);


    const calculateTotal = () => {
        const parseOrZero = (value) => {
            const parsed = parseFloat(value);
            return isNaN(parsed) ? 0 : parsed;
        };

        const subtotalValue = parseOrZero(subtotal);
        const taxesValue = parseOrZero(taxes);
        const shippingValue = parseOrZero(shipping);
        const discountValue = parseOrZero(discount);

        setTotal(subtotalValue + taxesValue + shippingValue - discountValue);
    };
    
    useEffect(() => {
        calculateTotal();
    }, [subtotal, taxes, shipping, discount]);

    const handleSupplierChange = (e) => {
        const selectedSupplierName = e.target.value;
        const supplierFound = suppliers.find(supplier => supplier.supplier_name === selectedSupplierName);
        setSelectedSupplier(supplierFound);
    };

    const updateBundleInfo = async () => {
        setSubtotal(ticketInfo.total_price);
        setShipping(ticketInfo.shipping);
        setTaxes(ticketInfo.taxes);
        setDiscount(ticketInfo.discount);
        setComment(ticketInfo.bundle_comment);
        setEta(ticketInfo.eta);
        setSelectedSupplier(suppliers?.find(supplier => supplier.supplier_id === ticketInfo.bundle_supplier_id) || {'supplier_id': 0});
    };

    const formatPhoneNumber = (phoneNumber) => {
        try{

            if (phoneNumber === '' || !phoneNumber){
                return '';
            }

            const phoneString = phoneNumber.toString();
          
            const areaCode = phoneString.slice(0, 3);
            const centralOfficeCode = phoneString.slice(3, 6);
            const lineNumber = phoneString.slice(6, 10);
          
            return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
        } catch (error){
            return phoneNumber;
        }
        
    };

    const conditions = {
        status: 'open',
        repContacted: false,
        termsKnown: false,
        shippingTime: 7,
        terms: selectedSupplier?.terms || '',
        cardUpdated: 1,
        automaticEmails: selectedSupplier?.automatic_emails_updates,
        firstTimeEmailOrder: false,
        rep: selectedSupplier?.rep_first_name + ' ' + selectedSupplier?.rep_last_name,
        location: locationName,
        owner: 'Owner X',
        orderTable: tableOrders(ticketInfo.orders),
        card: 'xxxx',
        user: '',
        orderItem: 'Item X',
        platformUser: 'Platform User',
        supplier: selectedSupplier?.supplier_name
    };

    useEffect(() => {

        setEmailData({ ...emailData, recipient_emails: [selectedSupplier?.rep_email], subject:`${locationName} <> ${selectedSupplier?.supplier_name} Order`,  message: emailTemplates[selectedTemplate](conditions), bundle_id: ticketInfo.bundle_id });
    
    }, [selectedSupplier]);

    const parseEmails = (input) => {
        // Split the string by commas or comma followed by spaces
        const emailArray = input.split(/,\s*/);
        // Trim whitespace from each email
        return emailArray.map(email => email.trim());
    };

    const handleFormSubmit = async (bundleStatus) => {

        await handleBundleSubmit(
            ticketInfo, bundleStatus, '', setTicketInfo, onClose, 
            setIsLoading, selectedSupplier?.supplier_id, selectedChannel.toLocaleLowerCase(),
            emailData, ticketInfo.tracking_number, false,
            shipping, taxes, discount, comment, ticketInfo.due_date, username, eta
        );

        await fetchTicketsInfo(setTickets, locationNameSelected);
    };

    return (
        <div>

            <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '10px' }}>All Suppliers</span>
                <label className="switch">
                    <input type="checkbox" checked={!showAllSuppliers} onChange={() => {setSelectedSupplier({'supplier_id': 0}); setShowAllSuppliers(!showAllSuppliers);}} />
                    <span className="slider round"></span>
                </label>
                <span style={{ marginLeft: '10px' }}>Manufacturer Suppliers</span>
            </div>
            
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <select
                    value={selectedSupplier && selectedSupplier?.supplier_name}
                    onChange={handleSupplierChange}
                    style={{
                        marginBottom: '20px', 
                        padding: '5px', 
                        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', 
                        border: '1px solid #87D3D7', 
                        fontStyle: 'italic', 
                        width: '45%' 
                    }}
                >
                    <option value={{'supplier_id': 0}}>Select Supplier</option>
                    {(showAllSuppliers ? suppliers : suppliersManufacturer).map((supplier) => (
                        <option key={supplier.id} value={supplier.supplier_name}>
                            {supplier.supplier_name}
                        </option>
                    ))}
                </select>
                
                <select
                    value={selectedChannel}
                    onChange={(e) => setSelectedChannel(e.target.value)}
                    style={{
                        marginBottom: '20px', 
                        padding: '5px', 
                        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', 
                        border: '1px solid #87D3D7', 
                        fontStyle: 'italic', 
                        width: '45%' 
                    }}
                >
                    <option value="" disabled>Select Channel</option>
                    {channels.map((channel) => (
                        <option key={channel} value={channel}>
                            {channel}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                { selectedSupplier && <p><strong>Customer Service Phone Number:</strong> {formatPhoneNumber(selectedSupplier?.cust_service_phone)}  | <strong>Customer Service Email:</strong> {selectedSupplier?.cust_service_email}</p>}
            </div>
            
            {selectedChannel === 'Platform' && (
                <div>
                    <p><strong>Platform website:</strong> {selectedSupplier?.website}</p>
                    <p><strong>Username:</strong> {selectedSupplier?.username}</p>
                    <p><strong>Password:</strong> {selectedSupplier?.password}</p>
                </div>
            )}

            {(selectedChannel === 'Phone' ||  selectedChannel === 'Email') && (
                <div>
                    <p><strong>Rep Name:</strong> {selectedSupplier?.rep_first_name + ' ' + selectedSupplier?.rep_last_name} | <strong>Rep Email:</strong> {selectedSupplier?.rep_email} | <strong>Rep Phone Number:</strong> {formatPhoneNumber(selectedSupplier?.rep_phone)}</p>
                </div>
            )}

            {(selectedChannel === 'Platform' || selectedChannel === 'Phone') && (
                <>
                    <p><strong>Subtotal:</strong> ${ticketInfo.total_price.toFixed(2)}</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <div>
                            <label>Taxes:</label>
                            <input type="number" value={taxes} onChange={(e) => setTaxes(e.target.value)} style={{padding: '5px', marginBottom: '5px'}} />
                        </div>
                        <div>
                            <label>Shipping:</label>
                            <input type="number" value={shipping} onChange={(e) => setShipping(e.target.value)} style={{padding: '5px', marginBottom: '5px'}} />
                        </div>
                        <div>
                            <label>Discount:</label>
                            <input type="number" value={discount} onChange={(e) => setDiscount(e.target.value)} style={{padding: '5px', marginBottom: '5px'}} />
                        </div>
                    </div>
                    <p><strong>Total:</strong> ${total.toFixed(2)}</p>
                    <div>
                        <label>ETA:</label>
                        <input 
                            type="datetime-local" 
                            value={eta && formatDateToLocalString(eta)} 
                            onChange={(event) => {
                                setEta(formatDateToUTCString(event.target.value));
                            }}
                            placeholder="YYYY-MM-DDTHH:MM"
                            style={{ padding: '10px', marginBottom: '10px' }} 
                        />
                    </div>
                    <div>
                        <label>Comment:</label>
                        <textarea value={comment} onChange={(e) => setComment(e.target.value)} style={{padding: '10px', marginBottom: '10px', width: '100%', boxSizing: 'border-box'}} />
                    </div>
                </>    
            )}

            {selectedChannel === 'Platform' && (
                <>
                    {isLoading ? (
                        <img
                            src={loadingSpinner}
                            alt="loading-spinner"
                            style={{
                                height: '20px',
                                marginBottom: '10px'  // Space below the spinner if needed
                            }}
                        />
                    ) : (
                        <button 
                            className="button-style" 
                            style={{cursor: 'pointer'}} 
                            onClick={() => { handleFormSubmit('Confirmed');}}
                        >
                            Move To Confirmed
                        </button>
                    )}
                </>
            )}

            {selectedChannel === 'Phone' && (
                <>
                    {isLoading ? (
                        <img
                            src={loadingSpinner}
                            alt="loading-spinner"
                            style={{
                                height: '20px',
                                marginBottom: '10px'  // Space below the spinner if needed
                            }}
                        />
                    ) : (
                        <button 
                            className="button-style" 
                            style={{cursor: 'pointer'}} 
                            onClick={() => {handleFormSubmit('Placed');}}
                        >
                            Move to Placed
                        </button>
                    )}
                </>
            )}

            {selectedChannel === 'Email' && (
                <div>
                    <div>
                        <label>Template:</label>
                        <select 
                            value={selectedTemplate} 
                            onChange={(e) => {
                                const selected = e.target.value;
                                setSelectedTemplate(selected);
                                setEmailData({ ...emailData, message: emailTemplates[selected](conditions) });
                            }} 
                            style={{padding: '10px', marginBottom: '10px', width: '100%', boxSizing: 'border-box'}}
                        >
                            <option value="" disabled>Select Template</option>
                            {Object.keys(emailTemplates).map((template) => (
                                <option key={template} value={template}>
                                    {template}
                                </option>
                            ))}
                        </select>
                    </div>
                    <label htmlFor="senderEmail">Sender Email:</label>
                    <input
                        type="email"
                        id="senderEmail"
                        value={emailData && emailData.sender_email}
                        onChange={(e) => setEmailData({ ...emailData, sender_email: e.target.value })}
                        style={{ width: '100%' }}
                    />
                    <label htmlFor="recipientEmails">Recipient Email(s):</label>
                    <input
                        type="email"
                        id="recipientEmails"
                        value={emailData && emailData.recipient_emails}
                        onChange={(e) => setEmailData({ ...emailData, recipient_emails: parseEmails(e.target.value) })}
                        style={{ width: '100%' }}
                    />
                    <label htmlFor="ccEmails">CC Email(s):</label>
                    <input
                        type="email"
                        id="ccEmails"
                        value={emailData && emailData.cc_emails}
                        onChange={(e) => setEmailData({ ...emailData, cc_emails: parseEmails(e.target.value) })}
                        style={{ width: '100%' }}
                    />
                    <label htmlFor="emailSubject">Subject Email:</label>
                    <input
                        type="text"
                        id="emailSubject"
                        value={emailData && emailData.subject}
                        onChange={(e) => setEmailData({ ...emailData, subject: e.target.value })}
                        style={{ width: '100%' }}
                    />
                    <label htmlFor="emailMessage">Edit Email:</label>
                    <ContentEditable
                        html={emailData ? emailData.message : ''}
                        onChange={(e) => { setEmailData({ ...emailData, message: e.target.value }); }}
                        style={{
                            width: '100%',
                            maxHeight: '120px',
                            padding: '5px',
                            overflowY: 'auto',
                            backgroundColor: '#fff',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}
                    />
                    <div>
                        <label>Comment:</label>
                        <textarea value={comment} onChange={(e) => setComment(e.target.value)} style={{padding: '10px', marginBottom: '10px', width: '100%', boxSizing: 'border-box'}} />
                    </div>

                    {isLoading ? (
                        <img
                            src={loadingSpinner}
                            alt="loading-spinner"
                            style={{
                                height: '20px',
                                marginBottom: '10px'  // Space below the spinner if needed
                            }}
                        />
                    ) : (
                        <button 
                            className="button-style" 
                            style={{cursor:'pointer'}} 
                            onClick={async () => {    
                                setIsLoading(true);
                                handleFormSubmit('Placed');
                                setIsLoading(false);
                            }}
                        >
                            Send Email & Move to Placed
                        </button>
                    )}

                </div>
            )}
        </div>
    );
};

export default OpenFlow;
