import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { fetchAvgOrderValuePerLocation } from '../../utils/locations/locationsUtils';

const AvgOrderValuePerLocation = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchAvgOrderValuePerLocation(setData);
  }, []);

  const chartData = {
    labels: data.map(entry => entry.location_name),
    datasets: [{
      label: 'Average Order Value',
      data: data.map(entry => entry.avg_order_value),
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }]
  };

  return (
    <div>
      <h2>Average Order Value Per Location</h2>
      <Bar data={chartData} options={{ responsive: true }} />
    </div>
  );
};

export default AvgOrderValuePerLocation;
